import React, {useState, useEffect} from 'react';
import RoundRobinStandings from "../CompetitionSystems/RoundRobinStandings/RoundRobinStandings";
import {
    fetchMatches,
    fetchRoundRobinStandings,
    generateFinalMatches,
    getUserData, returnToPhaseGroup,
    saveMatchResult
} from "../../api/api";
import MatchesTable from "../CompetitionSystems/MatchesTable/MatchesTable";
import MatchResultModal from "../CompetitionSystems/MatchResultModal/MatchResultModal";
import CustomModal from "../../UI/Modal/CustomModal";
import styles from './TourCurForm.module.css';
import SingleEliminationBracket from "../CompetitionSystems/SingleEliminationBracket/SingleEliminationBracket";
import BrazilianBracket from "../CompetitionSystems/BrazilianBracket/BrazilianBracket";
import Spinner from "../Spinner/Spinner";
import Podium from "../Podium/Podium";
import { IoArrowUndoOutline } from "react-icons/io5";

const TourCurForm = ({tournament, isArchived, onReturn}) => {
    const [standings, setStandings] = useState([]);
    const [matches, setMatches] = useState([]);
    const [editingMatchId, setEditingMatchId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState({});
    const [showConfirmButton, setShowConfirmButton] = useState(false);
    const [confirmLabel, setConfirmLabel] = useState("");
    const [nameFilter, setNameFilter] = useState('');
    const [currentAction, setCurrentAction] = useState(null);

    const competitionSystemMapping = {
        ROUND_ROBIN: 'Każdy z każdym',
        KNOCKOUT: 'Pucharowy',
        BRAZILIAN: 'Brazylijski do dwóch przegranych',
        COMBINED: 'Faza grupowa + faza finałowa'
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await getUserData();
            setUserData(data);
        };
        fetchData();
    }, []);

    const userRoles = new Set(userData.authorities || []);
    const isUserSuperVisor = userRoles.has("ROLE_SUPERVISOR");

    const fetchMatchData = async () => {
        const matchesResult = await fetchMatches(tournament.id);
        if (matchesResult.success) {
            setMatches(matchesResult.data);
        } else {
            console.error('Could not fetch matches:', matchesResult.data);
        }
    };

    const fetchStandingsData = async () => {
        const result = await fetchRoundRobinStandings(tournament.id);
        if (result.success) {
            setStandings(result.data);
        } else {
            console.error('Could not fetch standings');
        }
    };

    useEffect(() => {
        loadData();

        const intervalId = setInterval(() => {
            if (!isArchived && !tournament.isLeague) {
                loadData();
            }
        }, 60000);

        return () => clearInterval(intervalId);
    }, [tournament, isArchived]);

    const loadData = async () => {
        setIsLoading(true);
        if (tournament) {
            if (tournament.competitionSystem === 'ROUND_ROBIN' || tournament.competitionSystem === 'COMBINED') {
                await fetchMatchData();
                await fetchStandingsData();
            } else if (tournament.competitionSystem === 'KNOCKOUT' || tournament.competitionSystem === 'BRAZILIAN') {
                await fetchMatchData();
            }
        }
        if (!isArchived && !tournament.isLeague) {
            await new Promise(resolve => setTimeout(resolve, 2000));
        }
        setIsLoading(false);
    };

    useEffect(() => {
        loadData();
    }, [tournament]);

    const handleEditMatch = (matchId) => {
        setEditingMatchId(matchId);
        const matchToEdit = matches.find(match => match.id === matchId);
        if (matchToEdit) {
            setIsModalOpen(true);
        } else {
            console.error('Match not found!');
        }
    };

    const onSaveResult = async (matchId, scores, walkover, walkoverWinnerId, injuryResignation, injuryResignationParticipantId, clearMatchData) => {
        const matchResult = {
            matchId,
            scores,
            walkover,
            walkoverWinnerId,
            injuryResignation,
            injuryResignationParticipantId,
            clearMatchData
        };

        // await new Promise(resolve => setTimeout(resolve, 3000));

        const response = await saveMatchResult(matchResult);
        if (response.success) {
            setModalMessage(response.data);
            setShowConfirmButton(false);
            setIsModalOpen(false);
            setEditingMatchId(null);
            await fetchMatchData();
            await fetchStandingsData();
            setIsCustomModalOpen(true);
        } else {
            console.error('Failed to enter result of the match:', response.data);
            setModalMessage(response.data || 'error');
            setIsCustomModalOpen(true);
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setEditingMatchId(null);
        setShowConfirmButton(false);
        setConfirmLabel("");
    };

    const currentMatch = matches.find(match => match.id === editingMatchId);

    const handleCloseCustomModal = () => {
        setIsCustomModalOpen(false);
    };

    const filterFinalPhaseMatches = (matches) => {
        return matches.filter(match => match.matchNumber !== null);
    };

    const renderCombinedSystem = () => {
        const groupedStandings = standings
            .filter(standing => standing.group) // Filter out standings without a group
            .reduce((acc, standing) => {
                const group = standing.group;
                if (!acc[group]) acc[group] = [];
                acc[group].push(standing);
                return acc;
            }, {});

        const groupedMatches = matches
            .filter(match => match.group) // Filter out matches without a group
            .reduce((acc, match) => {
                const group = match.group;
                if (!acc[group]) acc[group] = [];
                acc[group].push(match);
                return acc;
            }, {});

        return (
            <>
                <h4 className={styles.groupPhaseTitle}>Faza Grupowa</h4>
                <div className={styles.groupsContainer}>
                    {Object.keys(groupedMatches).map(group => (
                        <div key={group} className={styles.group}>
                            <h4>Grupa {group}</h4>
                            {groupedStandings[group] && groupedStandings[group].length > 0 && (
                                <RoundRobinStandings standings={groupedStandings[group]} matches={groupedMatches[group]}
                                                     isArchived={isArchived}/>
                            )}
                            <MatchesTable matches={groupedMatches[group]} onEditMatch={handleEditMatch}
                                          isArchived={isArchived}
                                          isFinal={filterFinalPhaseMatches(matches).length > 0}/>
                        </div>
                    ))}
                </div>
                {!isArchived && isUserSuperVisor && filterFinalPhaseMatches(matches).length === 0 ? (
                    <button onClick={handleGenerateFinalMatches} className={styles.generateButton}>
                        Generuj mecze finałowe
                    </button>
                ) : (
                    !isArchived && isUserSuperVisor && (
                        <button onClick={handleReturnToFaseGroup} className={styles.returnToPhaseGroupButton}>
                            Powrót do fazy grupowej
                        </button>
                    )
                )}
                {filterFinalPhaseMatches(matches).length > 0 && (
                    <div>
                        <h4 className={styles.finalPhaseTitle}>Faza Finałowa</h4>
                        <SingleEliminationBracket matches={filterFinalPhaseMatches(matches)}
                                                  onEditMatch={handleEditMatch}
                                                  isArchived={isArchived}/>
                    </div>
                )}
                {currentMatch && (
                    <MatchResultModal
                        isOpen={isModalOpen}
                        onRequestClose={handleModalClose}
                        onSaveResult={onSaveResult}
                        editingMatch={currentMatch}
                        matchId={editingMatchId}
                        participant1Id={currentMatch.participant1Id}
                        participant2Id={currentMatch.participant2Id}
                        participant1FirstName={currentMatch.participant1FirstName}
                        participant1LastName={currentMatch.participant1LastName}
                        participant2FirstName={currentMatch.participant2FirstName}
                        participant2LastName={currentMatch.participant2LastName}
                        participant1PartnerFirstName={currentMatch.participant1PartnerFirstName}
                        participant1PartnerLastName={currentMatch.participant1PartnerLastName}
                        participant2PartnerFirstName={currentMatch.participant2PartnerFirstName}
                        participant2PartnerLastName={currentMatch.participant2PartnerLastName}
                        isThirdSetTiebreak={tournament.isThirdSetTiebreak}
                        isUserSupervision={isUserSuperVisor}
                        showConfirmButton={showConfirmButton}
                    />
                )}
            </>
        );
    };

    const handleConfirm = async () => {
        if (currentAction) {
            await currentAction();
            // setIsCustomModalOpen(false);
        }
    };

    const handleGenerateFinalMatches = async () => {
        setModalMessage("Czy napewno chcesz wygenerować mecze finałowe ? ");
        setConfirmLabel("Tak, generuje");
        setShowConfirmButton(true);
        setIsCustomModalOpen(true);
        setCurrentAction(() => generateFinalMatchesConfirmAction);
    };

    const handleReturnToFaseGroup = () => {
        setModalMessage("Czy napewno chcesz powrócić do fazy grupowej?");
        setConfirmLabel("Tak, wróć");
        setShowConfirmButton(true);
        setIsCustomModalOpen(true);
        setCurrentAction(() => returnToPhaseGroupConfirmation);
    };

    const generateFinalMatchesConfirmAction = async () => {
        const response = await generateFinalMatches(tournament.id);
        if (response.success) {
            setModalMessage(response.data);
            setConfirmLabel("");
            setShowConfirmButton(false);
            setIsCustomModalOpen(true);
            await fetchMatchData();
            await fetchStandingsData();
        } else {
            console.error('Failed to generate final matches:', response.data);
            setModalMessage(response.data || 'error');
            setIsCustomModalOpen(true);
        }
    };

    const returnToPhaseGroupConfirmation = async () => {
        const response = await returnToPhaseGroup(tournament.id);
        if (response.success) {
            setModalMessage(response.data);
            setConfirmLabel("");
            setShowConfirmButton(false);
            setIsCustomModalOpen(true);
            await fetchMatchData();
            await fetchStandingsData();
        } else {
            console.error('Failed to delete final matches:', response.data);
            setModalMessage(response.data || 'error');
            setIsCustomModalOpen(true);
        }
    };

    const getPodiumMatches = () => {
        if (tournament.competitionSystem === 'COMBINED') {
            return filterFinalPhaseMatches(matches);
        }
        return matches;
    };

    const filteredMatches = matches
        .filter(match => {
            return `${match.participant1LastName} ${match.participant1PartnerLastName} ${match.participant2LastName} ${match.participant2PartnerLastName}`.toLowerCase().includes(nameFilter.toLowerCase());
        });

    const renderCompetitionSystem = () => {
        switch (tournament.competitionSystem) {
            case 'ROUND_ROBIN':
                return (
                    <>
                        {standings.length > 0 &&
                            <RoundRobinStandings standings={standings} matches={matches} isArchived={isArchived}/>
                        }

                        <input
                            className={styles.lookingInput}
                            type="text"
                            placeholder="nazwisko gracza"
                            value={nameFilter}
                            onChange={(e) => setNameFilter(e.target.value)}
                        />

                        <MatchesTable matches={filteredMatches} onEditMatch={handleEditMatch} isArchived={isArchived}/>
                        {currentMatch && (
                            <MatchResultModal
                                isOpen={isModalOpen}
                                onRequestClose={handleModalClose}
                                onSaveResult={onSaveResult}
                                editingMatch={currentMatch}
                                matchId={editingMatchId}
                                participant1Id={currentMatch.participant1Id}
                                participant2Id={currentMatch.participant2Id}
                                participant1FirstName={currentMatch.participant1FirstName}
                                participant1LastName={currentMatch.participant1LastName}
                                participant2FirstName={currentMatch.participant2FirstName}
                                participant2LastName={currentMatch.participant2LastName}
                                participant1PartnerFirstName={currentMatch.participant1PartnerFirstName}
                                participant1PartnerLastName={currentMatch.participant1PartnerLastName}
                                participant2PartnerFirstName={currentMatch.participant2PartnerFirstName}
                                participant2PartnerLastName={currentMatch.participant2PartnerLastName}
                                isThirdSetTiebreak={tournament.isThirdSetTiebreak}
                                isUserSupervision={isUserSuperVisor}
                                showConfirmButton={showConfirmButton}
                            />
                        )}
                    </>
                );
            case 'KNOCKOUT':
                return (
                    <>
                        <SingleEliminationBracket matches={matches} onEditMatch={handleEditMatch}
                                                  isArchived={isArchived}/>
                        {currentMatch && (
                            <MatchResultModal
                                isOpen={isModalOpen}
                                onRequestClose={handleModalClose}
                                onSaveResult={onSaveResult}
                                editingMatch={currentMatch}
                                matchId={editingMatchId}
                                participant1Id={currentMatch.participant1Id}
                                participant2Id={currentMatch.participant2Id}
                                participant1FirstName={currentMatch.participant1FirstName}
                                participant1LastName={currentMatch.participant1LastName}
                                participant2FirstName={currentMatch.participant2FirstName}
                                participant2LastName={currentMatch.participant2LastName}
                                participant1PartnerFirstName={currentMatch.participant1PartnerFirstName}
                                participant1PartnerLastName={currentMatch.participant1PartnerLastName}
                                participant2PartnerFirstName={currentMatch.participant2PartnerFirstName}
                                participant2PartnerLastName={currentMatch.participant2PartnerLastName}
                                isThirdSetTiebreak={tournament.isThirdSetTiebreak}
                                isUserSupervision={isUserSuperVisor}
                                showConfirmButton={showConfirmButton}
                            />
                        )}
                    </>
                );
            case 'BRAZILIAN':
                return (
                    <>
                        <BrazilianBracket matches={matches} onEditMatch={handleEditMatch} isArchived={isArchived}/>
                        {currentMatch && (
                            <MatchResultModal
                                isOpen={isModalOpen}
                                onRequestClose={handleModalClose}
                                onSaveResult={onSaveResult}
                                editingMatch={currentMatch}
                                matchId={editingMatchId}
                                participant1Id={currentMatch.participant1Id}
                                participant2Id={currentMatch.participant2Id}
                                participant1FirstName={currentMatch.participant1FirstName}
                                participant1LastName={currentMatch.participant1LastName}
                                participant2FirstName={currentMatch.participant2FirstName}
                                participant2LastName={currentMatch.participant2LastName}
                                participant1PartnerFirstName={currentMatch.participant1PartnerFirstName}
                                participant1PartnerLastName={currentMatch.participant1PartnerLastName}
                                participant2PartnerFirstName={currentMatch.participant2PartnerFirstName}
                                participant2PartnerLastName={currentMatch.participant2PartnerLastName}
                                isThirdSetTiebreak={tournament.isThirdSetTiebreak}
                                isUserSupervision={isUserSuperVisor}
                                showConfirmButton={showConfirmButton}
                            />
                        )}
                    </>
                );
            case 'COMBINED':
                return (
                    <>
                        {renderCombinedSystem()}
                    </>
                );
            default:
                return <p>Unsupported competition system</p>;
        }
    };

    if ((isArchived || tournament.isLeague) && isLoading) return <Spinner size={"large"}/>;

    return (
        <>
            <CustomModal
                isOpen={isCustomModalOpen}
                onRequestClose={handleCloseCustomModal}
                message={modalMessage}
                confirmLabel={showConfirmButton ? confirmLabel : undefined}
                onConfirm={handleConfirm}
                isPositiveMessage={modalMessage.includes("zrobione") || modalMessage.includes("wygenerowane") || modalMessage.includes("skasowane")}
                cancelLabel="Zamknij"
                confirmButtonColor="red"
                cancelButtonColor="green"
                showConfirmButton={showConfirmButton}
            />
            <div className={styles.buttonContainer}>
                <button className={styles.returnCurButton} onClick={onReturn}><IoArrowUndoOutline/></button>
            </div>
            <div className={styles.container}>
                <div className={styles.titleContainer}>
                    <div className={styles.title}>{tournament.title}</div>
                    <div>
                        {!isArchived && !tournament.isLeague && isLoading &&
                            <div className={styles.spinner}></div>
                        }
                    </div>
                </div>
                <div className={styles.competitionSystemDisplay}>
                    System: <a href="/competition-descriptions" target="_blank"
                               className={styles.linkSystems}>{competitionSystemMapping[tournament.competitionSystem]} </a>
                </div>
                {!isArchived &&
                    <div className={styles.scoreRules}>
                        <a href="/score-rules" target="_blank">Zasady gry</a>
                    </div>
                }
                {isArchived && <Podium standings={standings} matches={getPodiumMatches()}
                                       competitionSystem={tournament.competitionSystem}/>}
                {renderCompetitionSystem()}
            </div>
        </>
    );
};

export default TourCurForm;
