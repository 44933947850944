import React from "react";
import styles from "./AdminReservationCell.module.css";
import PropTypes from "prop-types";

const AdminReservationCell = ({ cell }) => {


        let statusClass;
        switch (cell.status) {
            case 'MEMBER':
                statusClass = styles.member;
                break;
            case 'PERIODIC':
                statusClass = styles.periodic;
                break;
            case 'BLOCKED':
                statusClass = styles.blocked;
                break;
            case 'REGISTERED':
                statusClass = styles.registered;
                break;
            case 'PAYED':
                statusClass = styles.payed;
                break;
            case 'LESSONS':
                statusClass = styles.lessons;
                break;
            case 'NMEMBER':
                statusClass = styles.nmember;
                break;
            default:
                statusClass = '';
        }


    return (
        <div className={`${styles.cell} ${styles[cell.color]} ${statusClass}`}>
            <div className={styles.textContainer}>
                <div>{cell.firstName}</div>
                <div>{cell.lastName}</div>
            </div>
        </div>
    );

};

AdminReservationCell.propTypes = {
    cell: PropTypes.shape({
        court: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        time: PropTypes.instanceOf(Date).isRequired,
        user: PropTypes.shape({
            id: PropTypes.number.isRequired,
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
        }),
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    member: PropTypes.bool,
};


export default AdminReservationCell;
