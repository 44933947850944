import React from 'react';
import { Tabs, Tab, Box } from '@material-ui/core';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';

import AdminTournaments from "../AdminTournaments/AdminTournaments";
import SuperAdmin from "../SuperAdmin/SuperAdmin";
import ConfigurationTable from "../ConfigurationTable/ConfigurationTable";
import style from './SuperConsole.module.css';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

export default function SuperConsole() {
    const [value, setValue] = React.useState(0);


    const navigate = useNavigate();

    const isAuthenticated = () => {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        // Sprawdzanie ważności tokenu
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const decodedToken = JSON.parse(window.atob(base64));
        const expirationDate = new Date(decodedToken.exp * 1000);

        return expirationDate > new Date();
    };
    useEffect(() => {
        if (!isAuthenticated()) {
            navigate('/login');
        }
    }, [navigate]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={style.container}>
            <Tabs value={value} onChange={handleChange} centered>
                <Tab label="Users" {...a11yProps(0)} />
                <Tab label="Tournaments" {...a11yProps(1)} />
                <Tab label="Config" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <SuperAdmin/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AdminTournaments />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ConfigurationTable/>
            </TabPanel>
        </div>
    );
}
