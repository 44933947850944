import React, { useState, useEffect } from 'react';
import {
    fetchAllPaymentsCount,
    loadAllPaymentsByAdmin,
} from '../../api/api';
import styles from './PaymentOnLine.module.css';
import {getStatus} from "../../utils";
import Spinner from "../Spinner/Spinner";

const PaymentOnline = () => {
    const [payments, setPayments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const fetchAllPayments = async () => {
        try {
            const fetchedPayments = await loadAllPaymentsByAdmin(currentPage - 1);
            setPayments(fetchedPayments);
        } catch (error) {
            console.error('Error while fetching payments:', error);
        }finally {
            setIsLoading(false);
        }
    };

    const fetchPageCount = async () => {
        try {
            const count = await fetchAllPaymentsCount();
            const countAsNumber = Number(count);
            setPageCount(Math.ceil(countAsNumber / 10));
            setHasNextPage(countAsNumber > currentPage * 10);
        } catch (error) {
            console.error('Error while fetching page count:', error);
        }
    };

    useEffect(() => {
        fetchAllPayments();
    }, [currentPage]);

    useEffect(() => {
        fetchPageCount();
    }, [currentPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Miesiące są indeksowane od 0
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}-${month} ${hours}:${minutes}`;
    }

    if (isLoading) return <Spinner size={"large"}/>;

    return (
        <div className={styles.PaymentOnline}>
            <div className={styles.tableContainer}>
                <table className={styles.paymentsTable}>
                    <thead>
                    <tr>
                        <th>Id</th>
                        <th>Imię i Nazwisko</th>
                        <th>Tytuł</th>
                        <th>Kwota</th>
                        <th>Status</th>
                        <th>Data</th>
                    </tr>
                    </thead>
                    <tbody>
                    {payments.map(({id, firstName, lastName, title, amount, status, localUpdatedAt}) => (
                        <tr key={id}>
                            <td>{id}</td>
                            <td>{`${firstName} ${lastName}`}</td>
                            <td>{title}</td>
                            <td>{amount}</td>
                            <td>{getStatus(status)}</td>
                            <td>{formatDate(localUpdatedAt)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className={styles.pagination}>
                {pageCount > 1 && (
                    <>
                        <button
                            className={styles.prevNextButton}
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            Poprzednia
                        </button>
                        <span className={styles.pageNumber}>{currentPage}</span>
                        <button
                            className={styles.prevNextButton}
                            disabled={!hasNextPage}
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            Następna
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default PaymentOnline;
