import {BrowserRouter, Routes, Route} from "react-router-dom";
import News from "./pages/News/News";
import Reservations from "./pages/Reservations/Reservations";
import Info from "./pages/Info/Info";
import Error from "./pages/Error";
import Navbar from "./Navbar";
import LoginForm from "./components/LoginForm/LoginForm";
import RegisterForm from "./components/RegisterForm/RegisterForm";
import Modal from "react-modal";
import TokenConfirmation from "./UI/Confirmation/TokenConfirmation";
import Camera from "./pages/Camera/Camera";
import ResetPasswordForm from "./components/ResetPasswordForm/ResetPasswordForm";
import AdminConsole from "./components/AdminConsole/AdminConsole";
import SuperConsole from "./components/SuperConsole/SuperConsole";
import ProfileConsole from "./pages/ProfileConsole/ProfileConsole";
import AdminReservations from "./components/AdminReservations/AdminReservations";
import Regulation from "./components/Regulation/Regulation";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Instructions from "./components/Instructions/Instructions";
import MemberFee from "./components/MemberFee/MemberFee";
import Statute from "./components/Statute/Statute";
import CoachesList from "./components/CoachesList/CoachesList";
import TournamentsConsole from "./pages/TournamentConsole/TournamentsConsole";
import AdminStartTournament from "./components/AdminStartTournament/AdminStartTournament";
import ScoreRules from "./components/ScoreRules/ScoreRules";
import AdminTournaments from "./components/AdminTournaments/AdminTournaments";
import LookingPartner from "./components/LookingPartner/LookingPartner";
import NtrpScale from "./components/NtrpScale/NtrpScale";
import PaymentHistory from "./components/PaymentHistory/PaymentHistory";
import SuperAdmin from "./components/SuperAdmin/SuperAdmin";
import EloDescription from "./components/EloDescripion/EloDescription";
import CompetitionSystemsDescription from "./components/CompetitionSystemsDescription/CompetitionSystemsDescription";




function App() {

    Modal.setAppElement("#root");


    return (
        <BrowserRouter>
            <Navbar/>
                <Routes>
                    <Route path="/" element={<News/>}/>
                    <Route path="/admin-console" element={<AdminConsole/>}/>
                    <Route path="/camera" element={<Camera/>}/>
                    <Route path="/coaches" element={<CoachesList/>}/>
                    <Route path="/competition-descriptions" element={<CompetitionSystemsDescription/>}/>
                    <Route path="/elo-description" element={<EloDescription/>}/>
                    <Route path="/info" element={<Info/>}/>
                    <Route path="/instructions" element={<Instructions/>}/>
                    <Route path="/login" element={<LoginForm/>}/>
                    <Route path="/member-fee" element={<MemberFee/>} />
                    <Route path="/my-profile" element={<ProfileConsole/>}/>
                    <Route path="/my-profile/payments" element={<ProfileConsole/>}/>
                    <Route path="/my-profile/profile" element={<ProfileConsole/>}/>
                    <Route path="/ntrp-scale" element={<NtrpScale/>}/>
                    <Route path="/my-profile/reservations" element={<ProfileConsole/>}/>
                    <Route path="/partner" element={<LookingPartner/>}/>
                    <Route path="/payment-history" element={<PaymentHistory/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="/regulation" element={<Regulation/>}/>
                    <Route path="/register" element={<RegisterForm/>}/>
                    <Route path="/register/confirm/:tokenType/:token" element={<TokenConfirmation />} />
                    <Route path="/reset-password/confirm/:tokenType/:token" element={<TokenConfirmation />} />
                    <Route path="/reset-password/reset-form/:token" element={<ResetPasswordForm />} />
                    <Route path="/reservations" element={<Reservations/>}/>
                    <Route path="/score-rules" element={<ScoreRules/>}/>
                    <Route path="/statute" element={<Statute/>}/>
                    <Route path="/start-tournament/:tournamentId" element={<AdminStartTournament />} />
                    <Route path="/super-console" element={<SuperConsole/>}/>
                    <Route path="/timetable" element={<AdminReservations/>}/>
                    <Route path="/tournaments" element={<TournamentsConsole/>}/>
                    <Route path="/supervisor/tournaments" element={<AdminTournaments/>}/>
                    <Route path="/tournaments/menage" element={<AdminTournaments/>}/>
                    <Route path="*" element={<Error/>}/>
                </Routes>
        </BrowserRouter>
    );
}

export default App;