import React from 'react';
import styles from './Regulation.module.css';

const Regulation = () => {
    return (
        <div className={styles.regulationContainer}>
            <h1>Regulamin Korzystania z Kortów</h1>
            <h4>Bocheńskiego Towarzystwa Tenisowego (BTT)</h4>

            <div className={styles.regulationText}>
                <h5>Dane Stowarzyszenia:</h5>
                <p>
                    Bocheńskie Towarzystwo Tenisowe <br/>
                    ul św. Marka, 32-700 Bochnia <br/>
                    REGON: 850338181 <br/>
                    NIP: 8681020455 <br/>
                    KRS: 0000035600 <br/>
                </p>
                <h5>1. Rezerwacje i płatności</h5>
                <p>Korzystanie z kortu tenisowego możliwe jest tylko po wcześniejszej rezerwacji na stronie btt.org.pl. Obowiązkiem osoby rezerwującej jest obecność na kortach w czasie trwania rezerwacji. Płatność za korzystanie z obiektu winna nastąpić w ciągu trzech dni od zakończenia gry. Nieuregulowanie opłaty może skutkować zawieszeniem rezerwacji.</p>

                <h5>2. Strój i obuwie</h5>
                <p>Wymagany jest odpowiedni strój do gry w tenisa oraz buty nie niszczące powierzchni kortu (z płaską podeszwą).</p>

                <h5>3. Użytkowanie kortu</h5>
                <p>Na kortach obowiązuje samoobsługa. Po grze należy uprzątnąć kort, w tym siatkować powierzchnię oraz zabrać ze sobą wszelkie śmieci. Obowiązuję bezwzględny nakaz polewania kortu wodą (za wyjątkiem sytuacji po wystąpieniu opadów deszczu).</p>

                <h5>4. Bezpieczeństwo i etyka</h5>
                <p>Obowiązuje zakaz przebywania na kortach osób nietrzeźwych, palenia papierosów i używania wulgarnych słów. Każde działania mogące stwarzać zagrożenie są zabronione.</p>

                <h5>5. Odpowiedzialność</h5>
                <p>Korzystanie z kortu odbywa się na własną odpowiedzialność. Osoby, które uszkodzą wyposażenie obiektu, pokrywają koszty naprawy.</p>

                <h5>6. Zakończenie gry</h5>
                <p>Gra powinna zostać zakończona 5 minut przed końcem rezerwacji, by przygotować kort dla kolejnych graczy.</p>

                <h5>7. Zastrzeżenia</h5>
                <p>BTT zastrzega sobie prawo do anulowania rezerwacji na potrzeby organizowanych turniejów lub prac konserwacyjnych obiektu.</p>

                <h5>8. Anulowanie rezerwacji</h5>
                <p>Anulowanie rezerwacji jest możliwe do momentu jej rozpoczęcia. Jeśli rezerwacja nie zostanie anulowana przed jej rozpoczęciem, powstaje obowiązek zapłaty za rezerwację, niezależnie od tego, czy została ona faktycznie wykorzystana czy nie.</p>

                <h5>9. Reklamacje</h5>
                <p>W przypadku wszelkich uwag lub reklamacji związanych z korzystaniem z usług BTT, Klient powinien zgłosić je pisemnie na adres e-mail: btt.wysylka.maili@gmail.com. Reklamacje będą rozpatrywane w terminie 14 dni od daty ich otrzymania.
                    O wyniku rozpatrzenia reklamacji Klient zostanie poinformowany drogą mailową.</p>

                <p><strong>Niedostosowanie się do regulaminu może skutkować zakazem korzystania z obiektu.</strong></p>
            </div>
        </div>
    );
};

export default Regulation;