import React, {useEffect, useState} from 'react';
import styles from './TourCur.module.css';
import TourCurForm from "../TourCurForm/TourCurForm";
import {fetchTournaments} from "../../api/api";
import Spinner from "../Spinner/Spinner";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const TourCur = () => {
    const [tournaments, setTournaments] = useState([]);
    const [selectedTournament, setSelectedTournament] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchActiveTournaments = async () => {
            try {
                // Fetch only the tournaments with 'PLANNED' status
                const result = await fetchTournaments('ACTIVE');
                if (result.success) {
                    const sortedTournaments = result.data.sort((a, b) => {
                        return new Date(b.tournamentDate) - new Date(a.tournamentDate);
                    });
                    setTournaments(sortedTournaments);
                    setIsLoading(false)
                }
            } catch (error) {
                console.error("Failed to fetch active tournaments", error);
                setError("Błąd pobrania danych");
                setIsLoading(false);
            }
        };

        fetchActiveTournaments();
    }, []);

    const handleReturnToList = () => {
        setSelectedTournament(null);
    };

    if (isLoading) return <Spinner size={"large"}/>;
    if (error) return <ErrorMessage error={error}/>;

    return (
        <div>
            {selectedTournament && <TourCurForm tournament={selectedTournament} isArchived={false} onReturn={handleReturnToList}/>}
            <div className={styles.tournaments}>
                {!selectedTournament && tournaments.length > 0 ? (
                    tournaments.map((tournament) => (
                        <button
                            key={tournament.id}
                            className={styles.tournamentButton}
                            onClick={() => setSelectedTournament(tournament)}
                        >
                            {`${tournament.title}`}
                        </button>
                    ))
                ) : (
                    !selectedTournament && <p className={styles.noTournamentsMessage}>Brak trwających turniejów.</p>
                )}
            </div>
        </div>
    );
};

export default TourCur;
