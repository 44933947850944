import {useState, useRef, useContext} from "react";
import {FaBars} from "react-icons/fa";
import {useNavigate, Link} from "react-router-dom";
import {links} from "./data";
import logo from './logo.svg';
import AuthContext from "./contexts/AuthContext";
import CustomModal from "./UI/Modal/CustomModal";


const Navbar = () => {
    const [showLinks, setShowLinks] = useState(false);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    const linksContainerRef = useRef(null);
    const linksRef = useRef(null);
    const {isLoggedIn, setIsLoggedIn, setUserId} = useContext(AuthContext);

    const navigate = useNavigate();
    const toggleLinks = () => {
        setShowLinks(!showLinks);
    };

    const linkStyles = {
        height: showLinks
            ? `${linksRef.current.getBoundingClientRect().height}px`
            : '0px',
    }

    function logout() {
        localStorage.removeItem("token");
        setIsLoggedIn(false);
        navigate("/");
        setShowLinks(false);
    }

    function handleLogoutButtonClick() {
        logout();
        setIsLogoutModalOpen(true);
        closeNavbar();
    }

    const closeNavbar = () => {
        setShowLinks(false);
    };


    return (
        <nav>
            <div className="nav-center">
                <div className="nav-header">
                    <Link to="/">
                        <img src={logo} className="logo" alt="logo"/>
                    </Link>
                    <button className="nav-toggle" onClick={toggleLinks}>
                        <FaBars/>
                    </button>
                </div>
                <div
                    className="links-container"
                    ref={linksContainerRef}
                    style={linkStyles}
                >
                    <ul className="links" ref={linksRef}>
                        {links
                            .filter(
                                (link) =>
                                    (!link.requiresAuth || isLoggedIn) &&
                                    (!link.requiresNotAuth || !isLoggedIn)
                            )
                            .map((link) => {
                                const {id, url, text} = link;
                                return (
                                    <li key={id}>
                                        {text !== "Wyloguj" ? (
                                            <Link
                                                to={url}
                                                onClick={closeNavbar}
                                            >
                                                {text}
                                            </Link>
                                        ) : (
                                            <Link
                                                to="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleLogoutButtonClick();
                                                }}
                                            >
                                                {text}
                                            </Link>
                                        )}
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </div>
            <CustomModal
                isOpen={isLogoutModalOpen}
                onRequestClose={() => {
                    setIsLogoutModalOpen(false);
                }}
                message="Wylogowano."
                isPositiveMessage={true}
                cancelLabel="OK"
            />
        </nav>
    );
};

export default Navbar;
