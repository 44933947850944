import React, {useState, useEffect} from "react";
import styles from './SuperAdmin.module.css';
import {
    fetchUsers,
    deleteUser,
    deleteExpiredTokens,
    updateUserBySuper, redirectToLoginIfInvalidToken
} from "../../api/api";
import CustomModal from "../../UI/Modal/CustomModal";
import {useLocation, useNavigate} from "react-router-dom";
import Spinner from "../Spinner/Spinner";


const SuperAdmin = () => {

    const [users, setUsers] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalIsPositive, setModalIsPositive] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);


    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const isValidToken = redirectToLoginIfInvalidToken(navigate, location.pathname);
        setIsAuthorized(isValidToken);
    }, [navigate, location.pathname]);


    useEffect(() => {
        if (isAuthorized) {
            getUsers();
        }
    }, [isAuthorized]);

    const getUsers = async () => {
        setIsLoading(true);
        try {
            const fetchedUsers = await fetchUsers();
            if (Array.isArray(fetchedUsers)) {
                setUsers(fetchedUsers);
            } else {
                console.error('Fetched data is not an array:', fetchedUsers);
                setUsers([]);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setIsLoading(false);
        }
    };


    const handleEnabledChange = async (user, isEnabled) => {
        try {
            await updateUserBySuper(user.id, {...user, enabled: isEnabled});
            getUsers();
            setUsers((prevUsers) => {
                return prevUsers.map((u) => {
                    if (u.id === user.id) {
                        return {...u, enabled: isEnabled};
                    }
                    return u;
                });
            });
        } catch (error) {
            console.error("Błąd aktualizacji statusu użytkownika:", error);
        }
    };

    const handleAdminRoleChange = async (user, isAdmin) => {
        try {
            const updates = {
                isAdmin: isAdmin
            };

            await updateUserBySuper(user.id, updates);
            setUsers((prevUsers) => {
                return prevUsers.map((u) => {
                    if (u.id === user.id) {
                        const updatedAuthorities = isAdmin
                            ? [...user.authorities, "ROLE_ADMIN"]
                            : user.authorities.filter((authority) => authority !== "ROLE_ADMIN");
                        return {...u, authorities: updatedAuthorities};
                    }
                    return u;
                });
            });
        } catch (error) {
            console.error("Błąd aktualizacji roli użytkownika:", error);
        }
    };

    const handleViewRoleChange = async (user, isView) => {
        try {
            const updates = {
                isView: isView
            };

            await updateUserBySuper(user.id, updates);
            setUsers((prevUsers) => {
                return prevUsers.map((u) => {
                    if (u.id === user.id) {
                        const updatedAuthorities = isView
                            ? [...user.authorities, "ROLE_VIEW"]
                            : user.authorities.filter((authority) => authority !== "ROLE_VIEW");
                        return {...u, authorities: updatedAuthorities};
                    }
                    return u;
                });
            });
        } catch (error) {
            console.error("Błąd aktualizacji roli użytkownika:", error);
        }
    };

    const handleSuperVisorRoleChange = async (user, isSuperVisor) => {
        try {
            const updates = {
                isSuperVisor: isSuperVisor
            };

            await updateUserBySuper(user.id, updates);
            setUsers((prevUsers) => {
                return prevUsers.map((u) => {
                    if (u.id === user.id) {
                        const updatedAuthorities = isSuperVisor
                            ? [...user.authorities, "ROLE_SUPERVISOR"]
                            : user.authorities.filter((authority) => authority !== "ROLE_SUPERVISOR");
                        return {...u, authorities: updatedAuthorities};
                    }
                    return u;
                });
            });
        } catch (error) {
            console.error("Błąd aktualizacji roli użytkownika:", error);
        }
    };


    const handleDeleteUser = async (user) => {
        try {
            await deleteUser(user.id);
            const updatedUsers = users.filter((u) => u.id !== user.id);
            setUsers(updatedUsers);
        } catch (error) {
            console.error(`Error deleting user with ID ${user.id}:`, error);
        }
    };

    const handleDeleteExpiredTokens = async () => {
        try {
            const response = await deleteExpiredTokens(); // Otrzymaj odpowiedź z serwera
            console.log(response);
            setModalMessage(response );
            setModalIsPositive(true);
            setModalIsOpen(true);
        } catch (error) {
            console.error('Error deleting expired tokens:', error);
            setModalMessage('Wystąpił błąd podczas usuwania nieważnych tokenów.');
            setModalIsPositive(false);
            setModalIsOpen(true);
        }
    };


    const handleModalClose = () => {
        setModalIsOpen(false);
    };

    if (isLoading) return <Spinner size={"large"}/>;
    if (!isAuthorized) return null;

    return (
        <div className={styles.superAdmin}>
            <button className={styles.button} onClick={handleDeleteExpiredTokens}>
                Usuń nieważne tokeny
            </button>
            <table className={styles.table}>
                <thead>
                <tr>
                    <th>Email</th>
                    <th>Imię</th>
                    <th>Nazwisko</th>
                    <th>ID</th>
                    <th>Enabled</th>
                    <th>Admin</th>
                    <th>View</th>
                    <th>Visor</th>
                </tr>
                </thead>
                <tbody>
                {users
                    .sort((a, b) => b.id - a.id)
                    .map((user) => (
                        <tr key={user.id}>
                            <td>{user.username}</td>
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td>{user.id}</td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={user.enabled}
                                    onChange={(e) => handleEnabledChange(user, e.target.checked)}
                                />
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={user.authorities.includes("ROLE_ADMIN")}
                                    onChange={(e) => handleAdminRoleChange(user, e.target.checked)}
                                />
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={user.authorities.includes("ROLE_VIEW")}
                                    onChange={(e) => handleViewRoleChange(user, e.target.checked)}
                                />
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={user.authorities.includes("ROLE_SUPERVISOR")}
                                    onChange={(e) => handleSuperVisorRoleChange(user, e.target.checked)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <CustomModal
                isOpen={modalIsOpen}
                onRequestClose={handleModalClose}
                message={modalMessage}
                isPositiveMessage={modalIsPositive}
                cancelLabel="OK"
            />
        </div>
    );
};
export default SuperAdmin;
