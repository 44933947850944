import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {
    redirectToLoginIfInvalidToken,
    fetchAllReservationCount,
    loadAllReservations,
    cancelAdminReservation
} from "../../api/api";
import styles from './../MyReservations/MyReservations.module.css';
import {getStatus, formatDate, formatTime, canAdminCancelReservation} from "../../utils";
import ReservationCancelModal from "../../UI/Modal/ReservationCancelModal/ReservationCancelModal";
import Spinner from "../Spinner/Spinner";


const AllUsersReservations = () => {
    const [reservations, setReservations] = useState([]);
    const [reservationType, setReservationType] = useState("current");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [cancelingReservationId, setCancelingReservationId] = useState(null);
    const [isReservationCancelModalOpen, setIsReservationCancelModalOpen] = useState(false);
    const [reservationCancelMessage, setReservationCancelMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        redirectToLoginIfInvalidToken(navigate);
    }, [navigate]);

    useEffect(() => {
        const checkToken = () => {
            redirectToLoginIfInvalidToken(navigate);
        };

        window.addEventListener("storage", checkToken);
        return () => window.removeEventListener("storage", checkToken);
    }, [navigate]);

    const fetchAllReservations = async () => {
        try {
            const fetchedAllReservations = await loadAllReservations(currentPage - 1, reservationType);

            const sortedReservations = fetchedAllReservations.sort((a, b) => {
                if (reservationType === "current") {
                    return new Date(a.startTime) - new Date(b.startTime);
                } else {
                    return new Date(b.startTime) - new Date(a.startTime);
                }
            });
            setReservations(sortedReservations);
        } catch (error) {
            if (error.message === "Invalid or missing token") {
                navigate("/login", {replace: true});
            } else {
                console.error("Error while fetching reservations:", error);
            }
        }finally {
            setIsLoading(false);
        }
    };

    const fetchPageCount = async () => {
        try {
            const count = await fetchAllReservationCount(reservationType);
            const countAsNumber = Number(count);
            setPageCount(Math.ceil(countAsNumber / 10));
            setHasNextPage(countAsNumber > currentPage * 10);
        } catch (error) {
            if (error.message === "Invalid or missing token") {
                navigate("/login", {replace: true});
            } else {
                console.error("Error while fetching page count:", error);
            }
        }
    };


    useEffect(() => {
        fetchAllReservations();
    }, [reservationType, currentPage]);


    useEffect(() => {
        fetchPageCount();
    }, [reservationType, currentPage]);


    useEffect(() => {
        setCurrentPage(1);
        setPageCount(0);
    }, [reservationType]);

    const handleButtonCancelClick = (reservationId) => {
        setCancelingReservationId(reservationId);
        setIsCancelModalOpen(true);
    };


    const handleConfirmCancel = async (sendEmail, emailMessage) => {
        if (cancelingReservationId) {
            try {
                await cancelAdminReservation(cancelingReservationId, sendEmail, emailMessage);
                fetchAllReservations();

                setReservationCancelMessage('Rezerwacja anulowana, e-mail wysłany.');
            } catch (error) {
                console.error('Error canceling reservation:', error);
                console.error('Error details:', error.response);

                if (error.message === "Invalid or missing token") {
                    navigate("/login", {replace: true});
                }

                setReservationCancelMessage('Wystąpił błąd podczas anulowania rezerwacji.');
                setIsReservationCancelModalOpen(true);
            } finally {
                setCancelingReservationId(null);
                setIsCancelModalOpen(false);
            }
        }
        setIsCancelModalOpen(false);
        setIsReservationCancelModalOpen(true);
    };


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    if (isLoading) return <Spinner size={"large"}/>;

    return (
        <div className={styles.MyReservations}>
            <div className={styles.radioButtons}>
                <label>
                    <input
                        type="radio"
                        value="current"
                        checked={reservationType === "current"}
                        onChange={(e) => setReservationType(e.target.value)}
                    />
                    Aktualne
                </label>
                <label>
                    <input
                        type="radio"
                        value="past"
                        checked={reservationType === "past"}
                        onChange={(e) => setReservationType(e.target.value)}
                    />
                    Przeszłe
                </label>
            </div>

            <ReservationCancelModal
                isOpen={isCancelModalOpen}
                onRequestClose={() => setIsCancelModalOpen(false)}
                message="Czy na pewno chcesz anulować tę rezerwację?"
                isPositiveMessage={false}
                confirmLabel="Anuluj rezerwację"
                onConfirm={handleConfirmCancel}
                confirmButtonColor="red"
                cancelLabel="Nie anuluj"
                onCancel={() => setIsCancelModalOpen(false)}
                cancelButtonColor="green"
            />
            <ReservationCancelModal
                isOpen={isReservationCancelModalOpen}
                onRequestClose={() => setIsReservationCancelModalOpen(false)}
                message={reservationCancelMessage}
                isPositiveMessage={true}
                confirmLabel="OK"
                onConfirm={() => setIsReservationCancelModalOpen(false)}
                showEmailForm={false} // Ukryj formularz wiadomości e-mail

            />

            <div className={styles.tableContainer}>

                {reservations.length === 0 ? (
                    <div className={styles.noReservations}>
                        Brak rezerwacji dla wybranego okresu
                    </div>
                ) : (
                    <table className={styles.reservationsTable}>
                        <thead>
                        <tr>
                            <th>Nr</th>
                            <th>Data</th>
                            <th>Nazwa</th>
                            <th>Kort</th>
                            <th>Czas</th>
                            <th>Stat</th>
                            <th></th>
                            <th>Kwota</th>
                        </tr>
                        </thead>
                        <tbody>
                        {reservations.map(({
                                               id,
                                               status,
                                               startTime,
                                               endTime,
                                               firstName,
                                               lastName,
                                               courtId,
                                               cost,
                                           }) => (

                            <tr key={id}
                                className={
                                    status === "CANCELED"
                                        ? styles.cancelledReservation
                                        : status === "PAYED"
                                            ? styles.payedReservation
                                            : status === "REGISTERED" && new Date(startTime) < new Date()
                                                ? styles.expiredReservation
                                                : ""
                                }
                            >

                                <td>{id}</td>
                                <td>{formatDate(startTime)}</td>
                                <td>{`${firstName} ${lastName}`}</td>
                                <td>{courtId}</td>
                                <td>{formatTime(startTime)} - {formatTime(endTime)}</td>
                                <td>{getStatus(status)}</td>
                                <td>
                                    {canAdminCancelReservation(status) && (
                                        <div className={styles.cancelButtonContainer}>
                                            <button className={styles.button}
                                                    onClick={() => handleButtonCancelClick(id)}>
                                                X
                                            </button>
                                        </div>
                                    )}
                                </td>
                                <td>
                                    {(status === "REGISTERED" || status === "PAYED" || status === "LESSONS" || status === "NMEMBER") ? cost.toFixed(2) : ""}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                )}
            </div>
            <div className={styles.pagination}>
                {pageCount > 1 && (
                    <>
                        <button
                            className={styles.prevNextButton}
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            Poprzednia
                        </button>
                        <span className={styles.pageNumber}>{currentPage}</span>
                        <button
                            className={styles.prevNextButton}
                            disabled={!hasNextPage}
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            Następna
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default AllUsersReservations;
