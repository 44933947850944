import React from 'react';
import styles from './Spinner.module.css';

const Spinner = ({ size = 'medium' }) => {
    const spinnerClass = `${styles.spinner} ${styles[size]}`;
    return (
        <div className={styles.spinnerContainer}>
            <div className={spinnerClass}></div>
        </div>
    );
};

export default Spinner;
