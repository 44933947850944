import React, {useEffect, useState} from 'react';
import {
    fetchAllUnpaidFees,
    fetchAllUnpaidReservations, markPaymentAsPaid,
    markReservationAsPaid,
    togglePayingInProgress,
    updateReservationByAdmin
} from "../../api/api";
import {formatDate, formatTime, getStatus} from "../../utils";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './AdminPayments.module.css';
import CustomModal from "../../UI/Modal/CustomModal";
import Spinner from "../Spinner/Spinner";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const AdminPayments = () => {

    const [unpaidReservations, setUnpaidReservations] = useState([]);
    const [unpaidFees, setUnpaidFees] = useState([]);
    const [showModal, setShowModal] = useState(false); // stan modala
    const [pendingReservationId, setPendingReservationId] = useState(null); // id rezerwacji oczekującej na potwierdzenie89
    const [selectedReservationId, setSelectedReservationId] = useState(null);
    const [selectedPaymentId, setSelectedPaymentId] = useState(null);
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [nameFilter, setNameFilter] = useState('');
    const [numberOfReservationFilter, setNumberOfReservationFilter] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editingReservation, setEditingReservation] = useState(null);
    const [statusFilter, setStatusFilter] = useState('');
    const [paymentReservationMark, setPaymentReservationMark] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const reservationsResponse = await fetchAllUnpaidReservations();
                const sortedReservations = reservationsResponse.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
                setUnpaidReservations(sortedReservations);
            } catch (error) {
                console.error("Failed to fetch reservations:", error);
                setError("Błąd pobrania unpaid reservations");
                setIsLoading(false);
                // Optionally set an error state here
            }

            try {
                const paymentsResponse = await fetchAllUnpaidFees();
                setUnpaidFees(paymentsResponse);
                setIsLoading(false);
            } catch (error) {
                console.error("Failed to fetch unpaid fees:", error);
                setError("Błąd pobrania unpaid fees");
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);


    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setEditingReservation(prevState => ({...prevState, [name]: value}));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const updateReservationDTO = {
            id: editingReservation.id,
            status: editingReservation.status,
            cost: parseFloat(editingReservation.cost)
        };

        try {
            const response = await updateReservationByAdmin(updateReservationDTO);
            if (response) {
                setIsEditing(false);
                const updatedReservations = await fetchAllUnpaidReservations();
                setUnpaidReservations(updatedReservations);
            } else {
                console.error("Update was not successful", response);
            }
        } catch (error) {
            console.error("Failed to update reservation:", error);
        }
    };


    const handlePaymentConfirmation = async () => {

        if (paymentReservationMark === "reservation") {
            const result = await markReservationAsPaid(selectedReservationId);
            if (result) {
                setUnpaidReservations(await fetchAllUnpaidReservations());
            } else {
                console.error("Error while confirming payment for reservation.");
            }
        } else if (paymentReservationMark === "payment") {
            const result = await markPaymentAsPaid(selectedPaymentId);
            if (result) {
                setUnpaidFees(await fetchAllUnpaidFees());
            } else {
                console.error("Error while confirming payment for reservation.");
            }
        }
        closeModal();
    };

    const openModal = (reservationId) => {
        setPendingReservationId(reservationId);
        setSelectedReservationId(reservationId);
        setPaymentReservationMark("reservation");
        setShowModal(true);
    };

    const openModalForPayment = (paymentId) => {
        setShowModal(true);
        setSelectedPaymentId(paymentId);
        setPaymentReservationMark("payment");
    };

    const closeModal = () => {
        setShowModal(false);
        setPendingReservationId(null);
        setSelectedReservationId(null);
        setSelectedPaymentId(null);
        setPaymentReservationMark('');
    };

    const handlePayingToggle = async (id) => {
        const result = await togglePayingInProgress(id);
        if (result) {
            setUnpaidReservations(
                unpaidReservations.map((res) =>
                    res.id === id ? {...res, payingInProgress: !res.payingInProgress} : res
                )
            );
        } else {
            console.error("Error while toggling payment in progress.");
        }
    };

    // Function to filter reservations based on the date range and name
    const filteredReservations = unpaidReservations
        .filter(reservation => {
            const startTime = new Date(reservation.startTime);
            const withinStartDate = startDateFilter ? startTime >= startDateFilter : true;
            const endDateAdjusted = endDateFilter ? new Date(endDateFilter.getTime() + (24 * 60 * 60 * 1000) - 1) : null;
            const withinEndDate = endDateAdjusted ? startTime <= endDateAdjusted : true;
            const nameMatch = `${reservation.firstName} ${reservation.lastName}`.toLowerCase().includes(nameFilter.toLowerCase());
            const numberMatch = numberOfReservationFilter ? reservation.id === parseInt(numberOfReservationFilter, 10) : true;
            const statusMatch = statusFilter ? reservation.status === statusFilter : true;
            return withinStartDate && withinEndDate && nameMatch && numberMatch && statusMatch;
        });

    const totalUnpaid = filteredReservations
        .reduce((sum, reservation) => {
            if (reservation.payingInProgress) {
                return sum;
            }
            return sum + reservation.cost;
        }, 0);

    const totalUnpaidFees = unpaidFees.reduce((sum, fee)=>{
        return sum + fee.amount;
    },0);


    const handleEditReservation = (reservationId) => {
        const reservationToEdit = unpaidReservations.find(reservatons => reservatons.id === reservationId);
        if (reservationToEdit) {
            setEditingReservation({...reservationToEdit});
            setIsEditing(true)
        }
    };

    if (isLoading) return <Spinner size={"large"}/>;
    if (error) return <ErrorMessage error={error}/>;


    return (
        <div className={styles.AdminPayments}>
            <CustomModal
                isOpen={showModal}
                onRequestClose={closeModal}
                message={paymentReservationMark === "reservation" ? `PAID dla rezerwacji nr ${selectedReservationId}?`: `PAID dla płatności nr ${selectedPaymentId}?`}
                confirmLabel="Tak"
                confirmButtonColor="green"
                onConfirm={paymentReservationMark === "reservation" ? () => handlePaymentConfirmation(pendingReservationId): () => handlePaymentConfirmation()}
                cancelLabel="Nie"
                cancelButtonColor="red"
                onCancel={closeModal}
            />
            {unpaidReservations.length === 0 && unpaidFees.length === 0 && (
                <p>Brak zaległych opłat.</p>
            )
            }
            <div className={styles.tableContainer}>
                {unpaidReservations.length > 0 && (
                    <>

                        <div className={styles.filterContainer}>
                            <div>
                                <input
                                    className={styles.datepicker}
                                    type="number"
                                    placeholder="Nr"
                                    value={numberOfReservationFilter || ''}
                                    onChange={(e) => setNumberOfReservationFilter(e.target.value ? parseInt(e.target.value, 10) : null)}

                                />
                                <DatePicker
                                    className={styles.datepicker}
                                    selected={startDateFilter}
                                    onChange={(date) => setStartDateFilter(date)}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="From date"
                                />
                                <DatePicker
                                    className={styles.datepicker}
                                    selected={endDateFilter}
                                    onChange={(date) => setEndDateFilter(date)}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="To date"
                                />
                                <input
                                    className={styles.datepicker} // Assuming you add styles for this
                                    type="text"
                                    placeholder="Imię i Nazwisko"
                                    value={nameFilter}
                                    onChange={(e) => setNameFilter(e.target.value)}
                                />
                                <select
                                    name="statusFilter"
                                    value={statusFilter}
                                    onChange={(e) => setStatusFilter(e.target.value)}
                                    className={styles.datepicker}
                                >
                                    <option value="">Select status</option>
                                    <option value="NMEMBER">NME</option>
                                    <option value="LESSONS">LES</option>
                                    <option value="REGISTERED">REG</option>
                                </select>
                            </div>
                        </div>
                        {isEditing &&
                            <form onSubmit={handleSubmit}>
                                <div className={styles.filterContainer}>
                                    <div>
                                        <p className={styles.datepicker}>{editingReservation.id}</p>
                                    </div>
                                    <div>
                                        <p className={styles.datepicker}>{`${editingReservation.firstName} ${editingReservation.lastName}`}</p>
                                    </div>
                                    <div>
                                        <select
                                            name="status"
                                            value={editingReservation?.status || ''}
                                            onChange={handleInputChange}
                                            className={styles.datepicker}
                                        >
                                            <option value="MEMBER">MEM</option>
                                            <option value="NMEMBER">NME</option>
                                            <option value="CANCELED">CAN</option>
                                            <option value="PAYED">PAY</option>
                                            <option value="LESSONS">LES</option>
                                            <option value="REGISTERED">REG</option>
                                            <option value="PERIODIC">PER</option>
                                        </select>
                                    </div>
                                    <div>
                                        <input
                                            type="number"
                                            step="0.01"
                                            name="cost"
                                            value={editingReservation.cost}
                                            placeholder="Kwota"
                                            onChange={handleInputChange} className={styles.cost}/>
                                    </div>
                                </div>
                                <div className={styles.buttonContainer}>
                                    <button className={styles.button} type="submit">Zapisz</button>
                                    <button className={styles.button} type="button"
                                            onClick={() => setIsEditing(false)}>Anuluj
                                    </button>
                                </div>
                            </form>
                        }
                        {
                            <div className={styles.tableContainer}>
                                <table className={styles.reservationsTable}>
                                    <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Nr</th>
                                        <th>Kort</th>
                                        <th>Czas</th>
                                        <th className={styles.totalUnpaid}>{totalUnpaid.toFixed(2)}</th>
                                        <th>Stat</th>
                                        <th>Imię i nazwisko</th>
                                        <th>W trakcie</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {filteredReservations
                                        .map(({
                                                  id,
                                                  startTime,
                                                  courtId,
                                                  endTime,
                                                  cost,
                                                  status,
                                                  firstName,
                                                  lastName,
                                                  payingInProgress
                                              }) => (
                                            <tr key={id}>
                                                <td>{formatDate(startTime)}</td>
                                                <td>{id}</td>
                                                <td>{courtId}</td>
                                                <td>{`${formatTime(startTime)}-${formatTime(endTime)}`}</td>
                                                <td>{cost.toFixed(2)}</td>
                                                <td>{getStatus(status)}</td>
                                                <td>{`${firstName} ${lastName}`}</td>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={payingInProgress}
                                                        onChange={() => handlePayingToggle(id)}
                                                    />
                                                </td>
                                                <td>
                                                    <button className={styles.paidButton}
                                                            onClick={() => openModal(id)}>Zapłacone
                                                    </button>

                                                </td>
                                                <td>
                                                    <button className={styles.paidButton}
                                                            onClick={() => handleEditReservation(id)}>Edytuj
                                                    </button>

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </>
                )}
                {unpaidFees.length > 0 && (
                    <div className={styles.tableContainer}>
                        <table className={styles.reservationsTable}>
                            <thead>
                            <tr>
                                <th>Id</th>
                                <th>Imię i Nazwisko</th>
                                <th>Rodzaj opłaty</th>
                                <th>Stat</th>
                                <th className={styles.totalUnpaid}>{totalUnpaidFees.toFixed(2)}</th>
                                <th>Płatność</th>
                            </tr>
                            </thead>
                            <tbody>
                            {unpaidFees.length > 0 && (
                                unpaidFees.map(({
                                                    id,
                                                    firstName,
                                                    lastName,
                                                    title,
                                                    status,
                                                    amount
                                                }) => (
                                    <tr key={id}>
                                        <td>{id}</td>
                                        <td>{`${firstName} ${lastName}`}</td>
                                        <td>{title}</td>
                                        <td>{getStatus(status)}</td>
                                        <td>{amount}</td>
                                        <td>
                                            <button
                                                className={styles.button}
                                                onClick={() => openModalForPayment(id)}
                                            >
                                                Zapłacone
                                            </button>
                                        </td>
                                    </tr>
                                )))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AdminPayments;
