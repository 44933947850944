import React, {useMemo} from 'react';
import styles from './RoundRobinStandings.module.css'

const RoundRobinStandings = ({standings, matches}) => {

    function getDirectDuelResult(participantIdA, participantIdB, matches) {
        // Find a match that involves both participants
        const directDuelMatch = matches.find(match =>
            (match.participant1Id === participantIdA && match.participant2Id === participantIdB) ||
            (match.participant1Id === participantIdB && match.participant2Id === participantIdA)
        );

        if (directDuelMatch) {
            // Initialize counters for sets won by each player
            let setsWonByA = 0;
            let setsWonByB = 0;

            // Determine the outcome of each set
            directDuelMatch.setResults.forEach(set => {
                if (set.playerOneGames > set.playerTwoGames) {
                    // Determine who is playerOne in this match
                    if (directDuelMatch.participant1Id === participantIdA || directDuelMatch.userId1 === participantIdA) {
                        setsWonByA++;
                    } else {
                        setsWonByB++;
                    }
                } else if (set.playerTwoGames > set.playerOneGames) {
                    // Determine who is playerTwo in this match
                    if (directDuelMatch.participant2Id === participantIdA || directDuelMatch.userId2 === participantIdA) {
                        setsWonByA++;
                    } else {
                        setsWonByB++;
                    }
                }
            });

            // Determine the winner based on sets won
            if (setsWonByA > setsWonByB) {
                return -1; // Participant A wins over Participant B
            } else if (setsWonByB > setsWonByA) {
                return 1; // Participant B wins over Participant A
            }
        }

        // No direct duel or it was a draw based on sets won; return 0 to keep the current order
        return 0;
    }


    const sortedStandings = useMemo(() => {
        return [...standings].sort((a, b) => {
            // First, sort by points descending
            if (a.points !== b.points) {
                return b.points - a.points;
            }
            // Then, sort by (gamesWon - gamesLost) descending
            const gamesDifferenceA = a.gamesWon - a.gamesLost;
            const gamesDifferenceB = b.gamesWon - b.gamesLost;
            if (gamesDifferenceA !== gamesDifferenceB) {
                return gamesDifferenceB - gamesDifferenceA;
            }
            // Finally, if necessary, consider direct duel results
            return getDirectDuelResult(a.participantId, b.participantId, matches);
        });
    }, [standings, matches]);


    const renderPlayerName = (playerName, seedNumber) => {
        let name = `${playerName}`;

        // Append seeding number if exists
        if (seedNumber) {
            name += ` (${seedNumber})`;
        }
        return name;
    };

    return (
        <>
            <div className={styles.container}>
                <h5 className={styles.title}>Tabela</h5>
                <table className={styles.table}>
                    <thead>
                    <tr>
                        <th>Nr</th>
                        <th>Gracze</th>
                        <th>Mecze</th>
                        <th>Sety</th>
                        <th>Gemy</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sortedStandings.map((participant, index) => (
                        <tr key={participant.participantId}>
                            <td>{index + 1}</td>
                            <td>{renderPlayerName(participant.participantName, participant.seedingNumber)}<br/>
                                {participant.participantPartnerName}
                            </td>
                            <td>{participant.numberOfMatchesPlayed}</td>
                            <td>{participant.points}</td>
                            <td>{participant.gamesWon - participant.gamesLost} ({participant.gamesWon}-{participant.gamesLost})</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

        </>

    );
};

export default RoundRobinStandings;
