import React, { useState, useEffect } from 'react';
import { createNews, updateNews, deleteNews, fetchNews } from "../../api/api";
import CustomModal from "../../UI/Modal/CustomModal";
import styles from './AdminNewsForm.module.css';
import { format } from 'date-fns';

const AdminNewsForm = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [news, setNews] = useState([]);
    const [editingNews, setEditingNews] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isErrorModal, setIsErrorModal] = useState(false);
    const [newsToDelete, setNewsToDelete] = useState(null);
    const [isAddFormVisible, setIsAddFormVisible] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isConfirmationNeeded, setIsConfirmationNeeded] = useState(false);


    useEffect(() => {
        if (editingNews) {
            setTitle(editingNews.title);
            setDescription(editingNews.description);
        }
    }, [editingNews]);


    const getNews = async () => {
        try {
            const fetchedNews = await fetchNews();
            setNews(fetchedNews);
        } catch (error) {
            console.error("Wystąpił błąd podczas pobierania wiadomości.", error);
        }
    };

    useEffect(() => {
        getNews();
    }, []);


    useEffect(() => {
        if (!isSubmitted) {
            setTitle('');
            setDescription('');
        }
    }, [isSubmitted]);


    const handleDelete = async (id) => {
        setNewsToDelete(id);
        setIsModalOpen(true);
        setIsConfirmationNeeded(true);
    };

    const confirmDelete = async () => {
        if (newsToDelete !== null) {
            try {
                await deleteNews(newsToDelete);
                setIsModalOpen(false);
                setIsErrorModal(false);
                getNews();
            } catch (error) {
                setIsModalOpen(true);
                setModalMessage('Wystąpił błąd podczas usuwania wiadomości.');
                setIsErrorModal(true);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        setError('');
        setIsSubmitted(true);

        try {
            if (isEditing) {
                await updateNews(editingNews.id, { title, description });
                setIsEditing(false);
                setEditingNews(null);
                setIsAddFormVisible(false);
            } else {
                await createNews({ title, description });
                setIsAddFormVisible(false);
            }
            if (error === '') {
                setNewsToDelete(null);
                setIsModalOpen(true);
                setIsConfirmationNeeded(false);
            }
            getNews();
        } catch (err) {
            setError('Wystąpił błąd podczas dodawania/aktualizacji wiadomości.');
        } finally {
            if (isEditing) {
                setIsEditing(false);
            }
            setSubmitting(false);
            setIsSubmitted(false);
        }
    };



    const handleEdit = (id) => {
        const newsToEdit = news.find((item) => item.id === id);
        setEditingNews(newsToEdit);
        setIsEditing(true);
        setTitle(newsToEdit.title);
        setDescription(newsToEdit.description);
        toggleAddFormVisibility(true);
    };



    const toggleAddFormVisibility = (editing = false) => {
        setIsAddFormVisible(!isAddFormVisible);
        if (!editing) {
            setIsEditing(false);
            setEditingNews(null);
        }
    };


    const handleCancelEdit = () => {
        setIsEditing(false);
        setEditingNews(null);
        setTitle('');
        setDescription('');
        setIsAddFormVisible(false);
    };

    const sortedNews = news.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
    });




    return (
        <div className={styles.formContainer}>
            <button
                type="button"
                className={styles.addButton}
                onClick={() => toggleAddFormVisibility(false)}
            >
                Dodaj wiadomość
            </button>
            {isAddFormVisible && (
                <>
                    <h2 className={styles.formHeader}>{isEditing ? 'Edytuj wiadomość' : 'Dodaj wiadomość'}</h2>
                    {error && <p className={styles.errorMessage}>{error}</p>}
                    <form onSubmit={handleSubmit} className={styles.form}>
                        <label className={styles.formLabel} htmlFor="title">
                            Tytuł
                        </label>
                        <input
                            id="title"
                            name="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className={styles.formInput}
                        />
                        <label className={styles.formLabel} htmlFor="description">
                            Opis
                        </label>
                        <textarea
                            id="description"
                            name="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className={styles.formTextarea}
                        ></textarea>
                        <button
                            type="submit"
                            disabled={submitting}
                            className={styles.submitButton}
                        >
                            {submitting ? 'Wysyłanie...' : (isEditing ? 'Zapisz zmiany' : 'Dodaj wiadomość')}
                        </button>
                        <br />
                        <button
                            type="button"
                            onClick={handleCancelEdit}
                            className={styles.cancelButton}
                        >
                            Anuluj
                        </button>
                    </form>
                </>
            )}
            <div className={styles.tableWrapper}>
                <table className={styles.table}>
                    <thead>
                    <tr>
                        <th>Tytuł</th>
                        <th>Data</th>
                        <th>Akcje</th>
                    </tr>
                    </thead>

                    <tbody>
                    {sortedNews.map((item) => (
                        <tr key={item.id}>
                            <td>{item.title}</td>
                            <td>{format(new Date(item.createdAt), 'dd-MM-yyyy')}</td>
                            <td>
                                <button className={styles.editButton} onClick={() => handleEdit(item.id)}>Edytuj</button>
                                <button className={styles.deleteButton} onClick={() => handleDelete(item.id)}>Usuń</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <CustomModal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                message={modalMessage}
                isPositiveMessage={!isErrorModal}
                confirmLabel={isConfirmationNeeded ? (newsToDelete !== null ? "Usuń" : "Zamknij") : null}
                onConfirm={isConfirmationNeeded ? (newsToDelete !== null ? confirmDelete : () => setIsModalOpen(false)) : null}
                cancelLabel={isConfirmationNeeded ? "Anuluj" : "Zamknij"}
                onCancel={isConfirmationNeeded ? (newsToDelete !== null ? (() => setIsModalOpen(false)) : null) : null}
            />
        </div>
    );
};

export default AdminNewsForm;
