import React, {useState, useContext} from 'react';
import ConfigContext from "../../contexts/ConfigContext";
import styles from './MemberFee.module.css';
import Przelewy24Logo from "../../Przelewy24_logo.svg";
import {Link} from "react-router-dom";
import CustomModal from "../../UI/Modal/CustomModal";
import {initiatePaymentOnServer} from '../../api/api';

const MemberFee = () => {
    const {config} = useContext(ConfigContext);
    const [selectedFeeType, setSelectedFeeType] = useState('normal');
    const [hasAgreedToStatute, setHasAgreedToStatute] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isPositiveMessage, setIsPositiveMessage] = useState(true);
    const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);


    const handleFeeTypeChange = (event) => {
        setSelectedFeeType(event.target.value);
    };

    const getFeeDetails = () => {
        switch (selectedFeeType) {
            case 'normal':
                return {
                    amount: config.normalMembershipFee,
                    description: 'Opłata dla jednej osoby za cały sezon tenisowy.'
                };
            case 'discounted':
                return {
                    amount: config.discountedMembershipFee,
                    description: 'Opłata ulgowa za cały sezon tenisowy przysługuje wszystkim osobom uczącym się do 25 roku życia\n' +
                        '                    oraz tym, którzy ukończyli 60 lat.'

                };
            case 'family':
                return {
                    amount: config.familyMembershipFee,
                    description: 'Opłata rodzinna za cały sezon tenisowy dotyczy najbliższej rodziny (Rodzice i ich dzieci w wieku do 18 lat lub uczące się\n' +
                        '  do 25 roku życia).'
                };
            default:
                return {amount: 0, description: ''};
        }
    };

    const {amount, description} = getFeeDetails();


    const initiateMembershipFeePayment = async (selectedFeeType, amount) => {
        if (isPaymentInProgress) {
            return;
        }

        setIsPaymentInProgress(true);

        try {
            const paymentDetails = {
                feeType: selectedFeeType,
                amount: amount, // Ensure this is a number. If not, convert it.
            };

            // Construct the payment object for the API call
            const payment = {
                paymentType: 'membership', // Assuming the backend recognizes this type for membership fees
                paymentDetails: paymentDetails,
            };

            // Call the API function to initiate the payment on the server
            const paymentUrl = await initiatePaymentOnServer(payment);

            if (paymentUrl) {
                // If a payment URL is returned, redirect the user to that URL to complete the payment
                window.location.href = paymentUrl;
            } else {
                // Handle cases where no payment URL is returned
                console.error('No payment URL returned');
            }
        } catch (error) {
            console.error('Error initiating membership fee payment:', error);
            // Handle errors, such as by displaying a message to the user
        }
    };

    const handleSubmit = () => {
        if (!hasAgreedToStatute) {
            setModalMessage("Musisz zaakceptować Statut BTT.");
            setIsPositiveMessage(false);
            setIsModalOpen(true);
            return;
        }

        // Get the details for the selected fee type
        const {amount} = getFeeDetails();

        // Call the payment initiation function
        initiateMembershipFeePayment(selectedFeeType, amount).then(() => {
            // Handle post-payment initiation logic, if any
        }).catch((error) => {
            // Handle errors from the payment initiation
            console.error('Payment initiation failed:', error);
        });
    };


    return (
        <>
            <div className={styles.memberFeeContainer}>
                <div className={styles.radioGroup}>
                    <label className={styles.radioLabel}>
                        <input
                            type="radio"
                            value="normal"
                            checked={selectedFeeType === 'normal'}
                            onChange={handleFeeTypeChange}
                            className={styles.radioInput}
                        />
                        Składka normalna
                    </label>
                    <label className={styles.radioLabel}>
                        <input
                            type="radio"
                            value="discounted"
                            checked={selectedFeeType === 'discounted'}
                            onChange={handleFeeTypeChange}
                            className={styles.radioInput}
                        />
                        Składka ulgowa
                    </label>
                    <label className={styles.radioLabel}>
                        <input
                            type="radio"
                            value="family"
                            checked={selectedFeeType === 'family'}
                            onChange={handleFeeTypeChange}
                            className={styles.radioInput}
                        />
                        Składka rodzinna
                    </label>
                </div>
                <div className={styles.feeTypeCard}>
                    <p>{description}</p>
                    <p>Kwota: <b>{amount} zł</b></p>
                </div>
                <div className={styles.terms}>
                    <input
                        type="checkbox"
                        id="statuteCheckbox"
                        checked={hasAgreedToStatute}
                        onChange={(e) => setHasAgreedToStatute(e.target.checked)}
                    />
                    <label htmlFor="statuteCheckbox">
                        Akceptuję <Link to="/statute" target="_blank">Statut BTT</Link>
                    </label>
                </div>
                <button
                    className={styles.submitButton}
                    disabled={isPaymentInProgress}
                    onClick={handleSubmit}
                >
                    {isPaymentInProgress ? 'Proszę czekać...' : 'Płacę'}
                </button>
            </div>
            <div className={styles.paymentInfo}>
                <p>Płatności realizowane za pośrednictwem:</p>
                <img src={Przelewy24Logo} alt="Przelewy24" className={styles.przelewy24Logo}/>
            </div>
            <CustomModal
                isOpen={isModalOpen}
                onRequestClose={() => {
                    setIsModalOpen(false);
                }}
                message={modalMessage}
                isPositiveMessage={isPositiveMessage}
                cancelLabel="Zamknij"
            />
        </>

    );
};

export default MemberFee;