import React from 'react';
import styles from './PrivacyPolicy.module.css';

const PrivacyPolicy = () => {
    return (
        <div className={styles.policyContainer}>
            <h1>Polityka Prywatności</h1>
            <h5>Data ostatniej aktualizacji: 24-08-2023</h5>

            <div className={styles.policyText}>
                <h5>1. Wprowadzenie</h5>
                <p>Witaj na stronie Bocheńskiego Towarzystwa Tenisowego. Dbamy o Twoją prywatność.</p>

                <h5>2. Jakie dane zbieramy?</h5>
                <p>Informacje, które nam podajesz podczas rejestracji: adres e-mail, imię, nazwisko, numer telefonu.</p>

                <h5>3. Jak wykorzystujemy Twoje dane?</h5>
                <p>Używamy zebranych danych w celu realizacji naszych usług np. obsługa systemu rezerwacji, organizacja turniejów tenisowych.</p>

                <h5>4. Z kim dzielimy się Twoimi danymi?</h5>
                <p>Nie sprzedajemy ani nie udostępniamy Twoich danych  podmiotom trzecim, chyba że: otrzymamy Twoją wyraźną zgodę lub jest to wymagane przez prawo.</p>

                <h5>5. Twoje prawa</h5>
                <p>Masz prawo do: dostępu do swoich danych, poprawienia błędnych danych, usunięcia swoich danych z naszej bazy.</p>
                <p>Na Twoją prośbę dotyczącą dostępu, poprawienia lub usunięcia danych odpowiemy w ciągu 14 dni od daty jej otrzymania.</p>

                <h5>6. Bezpieczeństwo danych</h5>
                <p>Zabezpieczamy Twoje dane poprzez szyfrowane połączenie, autoryzowany dostęp do serwisu, regularne kopie zapasowe.</p>

                <h5>7. Ciasteczka (Cookies)</h5>
                <p>Na naszej stronie nie używamy ciasteczek.</p>

                <h5>8. Zmiany w Polityce Prywatności</h5>
                <p>Możemy aktualizować naszą Politykę Prywatności. Najnowszą wersję zawsze znajdziesz na tej stronie.</p>

                <h5>9. Przechowywanie danych</h5>
                <p>Do celów uwierzytelniania i zapewnienia bezproblemowego działania naszej strony, korzystamy z tokenów JWT. Tokeny te przechowujemy w pamięci lokalnej Twojej przeglądarki (local storage).</p>
                <p>Tokeny JWT mają ograniczony czas ważności. Jeżeli korzystasz z naszego serwisu na komputerach publicznych lub obcych, zalecamy wylogowanie się po zakończeniu sesji w celu usunięcia tokenu z przeglądarki. Dzięki temu zabezpieczasz się przed potencjalnym nieautoryzowanym dostępem do Twojego konta.</p>
                <p>Zapewniamy, że tokeny te są bezpieczne i służą jedynie do poprawy działania naszej strony oraz do identyfikacji użytkownika podczas korzystania z naszych usług.</p>

                <h5>10. Kontakt</h5>
                <p>W razie pytań dotyczących Polityki Prywatności prosimy o kontakt pod adresem e-mail: btt.wysylka.maili@gmail.com.</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;