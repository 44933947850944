import React, {useState} from 'react';
import styles from './Info.module.css';
import Przelewy24Logo from '../../Przelewy24_logo.svg';
import {FaEnvelope, FaFacebook} from 'react-icons/fa';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebookMessenger} from '@fortawesome/free-brands-svg-icons';
import {MdSportsTennis} from 'react-icons/md';
import {Link} from 'react-router-dom';
import {useCourtConfig} from "../../utils";
import Spinner from "../../components/Spinner/Spinner";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";

const Tab = ({children}) => <div >{children}</div>;

const Info = () => {
    const [config, , loading, error] = useCourtConfig();
    const [activeTab, setActiveTab] = useState('about');


    if (loading) return <Spinner size={"large"}/>;
    if (error) return <ErrorMessage error={"Błąd pobrania danych"}/>;

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className={styles.container}>
            <div className={styles.tabs}>
                <button className={activeTab === 'about' ? styles.activeTab : ''}
                        onClick={() => handleTabChange('about')}>O nas
                </button>
                <button className={activeTab === 'payments' ? styles.activeTab : ''}
                        onClick={() => handleTabChange('payments')}>Opłaty
                </button>
                <button className={activeTab === 'regulations' ? styles.activeTab : ''}
                        onClick={() => handleTabChange('regulations')}>Regulacje
                </button>
                <button className={activeTab === 'contact' ? styles.activeTab : ''}
                        onClick={() => handleTabChange('contact')}>Kontakt
                </button>
            </div>

            {activeTab === 'about' && (
                <Tab>
                    <p className={styles.text}>
                        <b>Bocheńskie Towarzystwo Tenisowe</b> (BTT) jest organizacją non-profit, które swoją
                        działalność
                        opiera na pracy społecznej jej członków. Statut BTT dostępny jest w dziale 'Regulacje'.
                        Głównym celem Towarzystwa jest prowadzenie i obsługa kortów tenisowych w Bochni oraz
                        popularyzacja gry w
                        tenisa ziemnego.
                    </p>
                    <div className={styles.regulations}>
                        <a href="/instructions"
                           target="_blank">Jak zarezerwować kort</a><br/>
                    </div>
                </Tab>
            )}

            {activeTab === 'payments' && (
                <Tab>
                    <div>
                        <table className={styles.feesTable}>
                            <thead>
                            <tr>
                                <th>Rodzaj opłaty</th>
                                <th>zł</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1 godzina wynajmu kortu
                                    do {config.priceChangeTime ? config.priceChangeTime.substring(0, 5) : ''}</td>
                                <td>{parseFloat(config.priceBeforeChange)}</td>
                            </tr>
                            <tr>
                                <td>1 godzina wynajmu kortu
                                    od {config.priceChangeTime ? config.priceChangeTime.substring(0, 5) : ''}</td>
                                <td>{parseFloat(config.priceAfterChange)}</td>
                            </tr>
                            <tr>
                                <td>Opłata okresowa wynajmu kortu (2 godz. na dzień) - 7 dni</td>
                                <td>{config.weeklyFee7Days}</td>
                            </tr>
                            <tr>
                                <td>Opłata okresowa wynajmu kortu (2 godz. na dzień) - 14 dni</td>
                                <td>{config.weeklyFee14Days}</td>
                            </tr>
                            <tr>
                                <td>Opłata okresowa wynajmu kortu (2 godz. na dzień) - 30 dni</td>
                                <td>{config.monthlyFee30Days}</td>
                            </tr>
                            <tr>
                                <td>Składka członkowska ulgowa</td>
                                <td>{config.discountedMembershipFee}</td>
                            </tr>
                            <tr>
                                <td>Składka członkowska normalna</td>
                                <td>{config.normalMembershipFee}</td>
                            </tr>
                            <tr>
                                <td>Składka członkowska rodzinna</td>
                                <td>{config.familyMembershipFee}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <p>Celem opłaty okresowej jest obniżenie kosztów wynajmu kurtu przy częstej grze.
                            W wybranym okresie można zarezerwować kort w wymiarze dwie godziny każdego dnia.
                            Opłatę wnosi się tylko raz i nie ponosi się już dodatkowych kosztów za inne osoby.
                        </p>
                        <p>Składka rodzinna dotyczy najbliższej rodziny (Rodzice i ich dzieci w wieku do 18 lat lub
                            uczące się do 25 roku życia). Składka ulgowa przysługuje wszystkim osobom uczącym się do 25
                            roku życia
                            oraz tym, którzy ukończyli 60 lat.</p>
                    </div>
                    <div className={styles.paymentOptions}>
                        <div className={styles.przelewy24}>
                            <img src={Przelewy24Logo} alt="Logo Przelewy24"/>
                            <p>Zalecamy regulowanie opłat za pomocą serwisu <a
                                href="https://www.przelewy24.pl/metody-platnosci" target="_blank">Przelewy24</a>.
                                Rozpoczęcie płatności odbywa się w "Dane" , zakładka "Płatności" </p>
                        </div>
                        <div className={styles.bankTransfer}>
                            <p>Alternatywnie, można dokonać tradycyjnego przelewu na konto BTT: <br/> <b>53 1240 5208
                                1111 0000
                                5474 1582</b>,
                                podając w tytule swoje Imię i Nazwisko oraz przedmiot wnoszonej opłaty.</p>
                        </div>
                    </div>
                </Tab>
            )}

            {activeTab === 'regulations' && (
                <Tab>
                    <div className={styles.regulations}>
                        <a href="/regulation" target="_blank">Regulamin korzystania z kortów BTT</a>
                        <a href="/privacy-policy" target="_blank">Polityka Prywatności</a>
                        <a href="/statute" target="_blank">Statut BTT</a>
                    </div>
                </Tab>
            )}

            {activeTab === 'contact' && (
                <Tab>
                    <div className={styles.contactLinks}>
                        <a href="mailto:btt.wysylka.maili@gmail.com" className={styles.emailLink}>
                            <FaEnvelope size={20}/> Napisz do nas
                        </a>
                        <a href="https://www.facebook.com/groups/261960086358911" target="_blank"
                           rel="noopener noreferrer">
                            <FaFacebook size={20}/> Dołącz do grupy na facebooku
                        </a>
                        <a href="https://m.me/ch/AbaDFp4REFwnbDRC/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebookMessenger} size="lg"/>
                               Dołącz do czatu dostępności kortów
                        </a>
                        <a href="https://m.me/ch/AbYBlVAAHnRXzvtj/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebookMessenger} size="lg"/>
                            Dołącz do czatu turniejów BTT
                        </a>
                        <Link to="/coaches" className={styles.coachLink}>
                            <MdSportsTennis size={30}/> Lista dostępnych trenerów tenisa
                        </Link>
                    </div>
                </Tab>
            )}
        </div>
    );
};

export default Info;
