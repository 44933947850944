import React, {useEffect, useState} from 'react';
import styles from './BrazilianBracket.module.css';
import {
    checkIfResultsEntered,
    renderMatchResultForSingleElimination,
    renderPartnerName,
    renderPlayerName
} from "../../../utils";
import {getUserData} from "../../../api/api";
import {TfiAngleDoubleLeft, TfiAngleDoubleRight} from "react-icons/tfi";

const BrazilianBracket = ({matches, onEditMatch, isArchived}) => {
    const [userData, setUserData] = useState({});
    const [currentRound, setCurrentRound] = useState(1);
    const [totalRounds, setTotalRounds] = useState(0); // Total rounds will be determined based on matches
    const [hoveredMatch, setHoveredMatch] = useState(null);


    const handleMouseEnter = (matchNumber) => {
        setHoveredMatch(matchNumber);
    };

    const handleMouseLeave = () => {
        setHoveredMatch(null);
    };

    const matchHighlightMap30 = {
        1: [1, 9, 16, 21, 27, 29],
        2: [2, 9, 16, 21, 27, 29],
        3: [3, 10, 15, 21, 27, 29],
        4: [4, 10, 15, 21, 27, 29],
        5: [5, 11, 14, 22, 28, 29],
        6: [6, 11, 14, 22, 28, 29],
        7: [7, 12, 13, 22, 28, 29],
        8: [8, 12, 13, 22, 28, 29],
        9: [9, 21, 17, 21, 27, 29],
        10: [10, 21, 18, 21, 27, 29],
        11: [11, 22, 19, 22, 28, 29],
        12: [12, 22, 20, 22, 28, 29],
        13: [13, 17, 23, 25, 27, 29],
        14: [14, 18, 23, 25, 27, 29],
        15: [15, 19, 24, 26, 28, 29],
        16: [16, 20, 24, 26, 28, 29],
        17: [17, 23, 25, 27, 29],
        18: [18, 23, 25, 27, 29],
        19: [19, 24, 26, 28, 29],
        20: [20, 24, 26, 28, 29],
        21: [21, 27, 26, 27, 29],
        22: [22, 28, 25, 28, 29],
        23: [23, 25, 27, 29],
        24: [24, 26, 28, 29],
        25: [25, 27, 29],
        26: [26, 28, 29],
        27: [27, 29, 30],
        28: [28, 29, 30]
    };

    const matchHighlightMap14 = {
        1: [1, 7, 5, 11, 13],
        2: [2, 7, 5, 11, 13],
        3: [3, 8, 6, 12, 13],
        4: [4, 8, 6, 12, 13],
        5: [5, 10, 11, 13],
        6: [6, 9, 12, 13],
        7: [7, 9, 11, 13],
        8: [8, 10, 12, 13],
        9: [9, 11, 13],
        10: [10, 12, 13],
        11: [11, 13, 14],
        12: [12, 13, 14]
    };


    useEffect(() => {
        const fetchData = async () => {
            const data = await getUserData();
            setUserData(data);
        };
        fetchData();
    }, []);


    useEffect(() => {
        // Ensure there are matches before proceeding
        if (matches.length === 0) {
            return; // Exit the effect if no matches have been loaded yet
        }

        const matchCount = matches.length;
        switch (matchCount) {
            case 30:
                setTotalRounds(4);
                break;
            case 14:
                setTotalRounds(4);
                break;
            default:
                console.error("Unsupported number of matches");
        }
    }, [matches]);



    // Helper function to find match by number
    const findMatchByNumber = (number) => {
        return matches.find(match => match.matchNumber === number);
    };

    const canUserEditMatch = (match) => {
        // Check if the user is logged in and has the necessary role or is a participant of the match
        const userRoles = new Set(userData.authorities || []);
        const isUserAdminOrViewer = userRoles.has("ROLE_ADMIN") || userRoles.has("ROLE_SUPERVISOR");
        const isUserParticipant = match.userId1 === userData.id || match.userId2 === userData.id || match.userId1Partner === userData.id || match.userId2Partner === userData.id;

        return isUserAdminOrViewer || isUserParticipant;
    };


    // Function to go to the next round
    const goToNextRound = () => {
        setCurrentRound((prevRound) => (prevRound < totalRounds ? prevRound + 1 : prevRound));
    };

    // Function to go to the previous round
    const goToPreviousRound = () => {
        setCurrentRound((prevRound) => (prevRound > 1 ? prevRound - 1 : prevRound));
    };

    const renderMatchCell = (matchNumber) => {

        const matchCount = matches.length;
        let currentMap = matchCount === 14 ? matchHighlightMap14 : matchHighlightMap30;


        const match = findMatchByNumber(matchNumber);
        const isHighlighted = hoveredMatch && currentMap[hoveredMatch]?.includes(matchNumber);
        const hasParticipants = match && (match.participant1Id && match.participant2Id);
        return (
            <div
                className={`${styles.matchCell} ${isHighlighted ? styles.highlighted : ''}`}
                onMouseEnter={() => handleMouseEnter(matchNumber)}
                onMouseLeave={handleMouseLeave}
            >
                {match ? (
                    <>
                        <div className={styles.playerNames}>
                            {match.participant1Id ?
                                <div>
                                    {renderPlayerName(match.participant1Id, match.participant1LastName, match.participant1FirstName, match, match.seedNumberParticipant1)}
                                    {match.participant1PartnerLastName && (
                                        <>
                                            <br/>
                                            <span
                                                className={styles.partnerName}>{renderPartnerName(match.participant1Id, match.participant1PartnerLastName, match.participant1PartnerFirstName, match)}</span>
                                        </>
                                    )}
                                </div> : <div>&nbsp;</div>}
                            {match.participant2Id ?
                                <div>
                                    {renderPlayerName(match.participant2Id, match.participant2LastName, match.participant2FirstName, match, match.seedNumberParticipant2)}
                                    {match.participant2PartnerLastName && (
                                        <>
                                            <br/>
                                            <span
                                                className={styles.partnerName}>{renderPartnerName(match.participant2Id, match.participant2PartnerLastName, match.participant2PartnerFirstName, match)}</span>
                                        </>
                                    )}
                                </div> :
                                <div>&nbsp;</div>}
                        </div>
                            {match.setResults && renderMatchResultForSingleElimination(match.setResults)}
                        <div>
                            {!isArchived && hasParticipants && canUserEditMatch(match) && (
                                <button className={styles.button} onClick={() => onEditMatch(match.id)}>
                                    {checkIfResultsEntered(match) ? 'E' : 'W'}
                                </button>
                            )}
                        </div>
                    </>
                ) : (
                    `match ${matchNumber}`
                )}
            </div>
        );
    };

    const renderRoundTitle = (roundName, roundNumber) => (
        <div>
            <h5 className={styles.titleOfRound}>
                {currentRound > 1 && roundNumber === currentRound && (
                    <button className={styles.navigationButton} onClick={goToPreviousRound}>
                        <TfiAngleDoubleLeft/>
                    </button>
                )}
                {roundName}
                {currentRound < totalRounds && roundNumber === currentRound && (
                    <button className={styles.navigationButton} onClick={goToNextRound}>
                        <TfiAngleDoubleRight/>
                    </button>
                )}
            </h5>
        </div>
    );


    const renderRound = (roundName, roundNumber, matchesNumbers) => (
        <div
            className={`${styles.round} ${styles[`round${roundNumber}`]} ${currentRound === roundNumber ? styles.current : ''}`}>
            {renderRoundTitle(roundName, roundNumber)}
            {matchesNumbers.map(renderMatchCell)}
        </div>
    );

    const generateBracket = () => {
        const matchCount = matches.length;
        switch (matchCount) {
            case 30:
                return (
                    <div className={styles.bracketContainer}>
                        <div className={styles.finalRounds}>
                            {renderRound('1 runda', 1, [1, 2, 3, 4, 5, 6, 7, 8])}
                            {renderRound('Druga szansa', 1, [13, 14, 15, 16])}
                        </div>
                        <div className={styles.finalRounds}>
                            {renderRound('2 runda', 2, [9, 10, 11, 12])}
                            {renderRound('Druga szansa', 2, [17, 18, 19, 20])}
                        </div>
                        <div className={styles.finalRounds}>
                            {renderRound('3 runda', 3, [21, 22])}
                            {renderRound('Druga szansa', 3, [25, 26, 23, 24])}
                        </div>
                        <div className={styles.finalRounds}>
                            {renderRound('Runda Finałowa', 4, [27, 28])}
                            {renderRound('Mecz o 3 miejsce', 4, [30])}
                            {renderRound('Finał', 4, [29])}
                        </div>
                    </div>
                );
            case 14:
                return (
                    <div className={styles.bracketContainer}>
                        <div className={styles.finalRounds}>
                            {renderRound('1 runda', 1, [1, 2, 3, 4])}
                            {renderRound('Druga szansa', 1, [7, 8])}
                        </div>
                        <div className={styles.finalRounds}>
                            {renderRound('2 runda', 2, [5, 6])}
                            {renderRound('Druga szansa', 2, [9, 10])}
                        </div>
                        {renderRound('3 runda', 3, [11, 12])}
                        <div className={styles.finalRounds}>
                            {renderRound('Mecz o 3 miejsce', 4, [14])}
                            {renderRound('Finał', 4, [13])}
                        </div>
                    </div>
                );
            default:
                return <div>Unsupported number of matches</div>;
        }
    };


    return <>
        {generateBracket()}
    </>
};

export default BrazilianBracket;
