import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {addTournament, deleteTournament, fetchTournaments, getUserData, updateTournament} from "../../api/api";
import styles from './AdminTournaments.module.css'
import {formatDateTime} from "../../utils";
import CustomModal from "../../UI/Modal/CustomModal";
import Spinner from "../Spinner/Spinner";

const initialDescription = "Gwarantowane rozegranie dwóch meczów. Nagrodami w turnieju będą piłki tenisowe. " +
    "Wpisowe od osoby: 30 zł członkowie BTT, 50 zł pozostali gracze. Informacje, dotyczące zmiany godziny rozpoczęcia, przełożenia lub odwołania turnieju będą przekazywane" +
    " na czacie turniejowym (link poniżej).";

const AdminTournaments = () => {
    const [tournaments, setTournaments] = useState([]);
    const [selectedTournament, setSelectedTournament] = useState(null);
    const [title, setTitle] = useState('');
    const [participantLimit, setParticipantLimit] = useState('');
    const [numberOfPointsInSet, setNumberOfPointsInSet] = useState('');
    const [tournamentEntryFeeMember, setTournamentEntryFeeMember] = useState('');
    const [tournamentEntryFeeNonMember, setTournamentEntryFeeNonMember] = useState('');
    const [leagueMatchFee, setLeagueMatchFee] = useState('');
    const [isThirdSetTiebreak, setIsThirdSetTiebreak] = useState(false);
    const [isLeague, setIsLeague] = useState(false);
    const [description, setDescription] = useState(initialDescription);
    const [tournamentDate, setTournamentDate] = useState('');
    const [registrationStartDate, setRegistrationStartDate] = useState('');
    const [registrationEndDate, setRegistrationEndDate] = useState('');
    const [tournamentStatus, setTournamentStatus] = useState('');
    const [tournamentType, setTournamentType] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showConfirmButton, setShowConfirmButton] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [confirmLabel, setConfirmLabel] = useState("");
    const [showForm, setShowForm] = useState(false);
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const fetchData = async () => {
            const data = await getUserData();
            setUserData(data);
        };
        fetchData();
    }, []);

    const canUserSeeTournaments = () => {
        const userRoles = new Set(userData.authorities || []);
        return userRoles.has("ROLE_ADMIN") || userRoles.has("ROLE_SUPERVISOR");
    };

    const navigate = useNavigate();


    const sortTournaments = (tournaments) => {
        return tournaments.sort((a, b) => new Date(b.tournamentDate) - new Date(a.tournamentDate));
    };


    useEffect(() => {
        const fetchData = async () => {
            const result = await fetchTournaments();
            if (result.success) {
                const sortedTournaments = sortTournaments(result.data);
                setTournaments(sortedTournaments);
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleAddTournament = async () => {
        const result = await addTournament({
            title,
            participantLimit,
            numberOfPointsInSet: numberOfPointsInSet,
            tournamentEntryFeeMember: tournamentEntryFeeMember,
            tournamentEntryFeeNonMember: tournamentEntryFeeNonMember,
            leagueMatchFee: leagueMatchFee,
            isThirdSetTiebreak,
            isLeague,
            description,
            tournamentDate,
            registrationStartDate,
            registrationEndDate,
            status: tournamentStatus,
            type: tournamentType
        });
        if (result.success) {
            const sortedTournaments = sortTournaments(result.data);
            setTournaments(sortedTournaments);
            setShowForm(false);
            setTitle('');
            setParticipantLimit('');
            setNumberOfPointsInSet('');
            setTournamentEntryFeeMember('');
            setTournamentEntryFeeNonMember('');
            setLeagueMatchFee('');
            setIsThirdSetTiebreak(false);
            setIsLeague(false);
            setDescription(initialDescription);
            setTournamentDate('');
            setRegistrationStartDate('');
            setRegistrationEndDate('');
            setTournamentStatus('');
            setTournamentType('');
        }
    };

    const handleUpdateTournament = async () => {
        const result = await updateTournament(selectedTournament.id, {
            title,
            participantLimit,
            numberOfPointsInSet: numberOfPointsInSet,
            tournamentEntryFeeMember: tournamentEntryFeeMember,
            tournamentEntryFeeNonMember: tournamentEntryFeeNonMember,
            leagueMatchFee: leagueMatchFee,
            isThirdSetTiebreak,
            isLeague,
            description,
            tournamentDate,
            registrationStartDate,
            registrationEndDate,
            status: tournamentStatus,
            type: tournamentType
        });
        if (result.success) {
            const sortedTournaments = sortTournaments(result.data);
            setTournaments(sortedTournaments);
            setShowForm(false);
            setSelectedTournament(null);
            setTitle('');
            setParticipantLimit('');
            setNumberOfPointsInSet('');
            setTournamentEntryFeeMember('');
            setTournamentEntryFeeNonMember('');
            setLeagueMatchFee('');
            setIsThirdSetTiebreak(false);
            setIsLeague(false);
            setDescription(initialDescription);
            setTournamentDate('');
            setRegistrationStartDate('');
            setRegistrationEndDate('');
            setTournamentStatus('');
            setTournamentType('');
        }
    };

    const handleDeleteTournament = async (tournament) => {
        setSelectedTournament(tournament);
        setModalMessage("Czy napewo chcesz usunąć: " + tournament.title);
        setConfirmLabel("Usuwam");
        setShowConfirmButton(true);
        setIsModalOpen(true)
    };

    const handleConfirmAction = async () => {
        const id = selectedTournament.id;
        const result = await deleteTournament(id);
        if (result.success) {
            setTournaments(tournaments.filter((tournament) => tournament.id !== id));
            setModalMessage("Turniej usunięty");
            setShowConfirmButton(false);
            setIsModalOpen(true)
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleStartTournament = (tournamentId) => {
        navigate(`/start-tournament/${tournamentId}`);
    };

    if(isLoading) return <Spinner size={"large"}/>

    return (
            canUserSeeTournaments() ? (
                    <div className={styles.formContainer}>
                        <CustomModal
                            isOpen={isModalOpen}
                            onRequestClose={handleCloseModal}
                            message={modalMessage}
                            confirmLabel={showConfirmButton ? confirmLabel : undefined}
                            onConfirm={showConfirmButton ? handleConfirmAction : undefined}
                            isPositiveMessage={modalMessage.includes("Dodano") || modalMessage.includes("anulowane")
                                || modalMessage.includes("Zapisano") || modalMessage.includes("usunięty")}
                            cancelLabel="Zamknij"
                            confirmButtonColor="red"
                            cancelButtonColor="green"
                        />
                        <div>
                            <button className={styles.addTournament} onClick={() => setShowForm(!showForm)}>
                                {showForm ? "Zamknij formularz" : "Nowy turniej"}
                            </button>
                        </div>
                        {showForm && (
                            <div>
                                <div className={styles.formRow}>
                                    <label>
                                        Nazwa turnieju:
                                        <input
                                            className={styles.input}
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}/>
                                    </label>
                                </div>
                                <div className={styles.formRow}>
                                    <label>
                                        Typ:
                                        <select
                                            className={styles.input}
                                            value={tournamentType}
                                            onChange={(e) => setTournamentType(e.target.value)}
                                        >
                                            <option value="">Select type</option>
                                            <option value="SINGLES">Singles</option>
                                            <option value="DOUBLES">Doubles</option>
                                        </select>
                                    </label>
                                </div>
                                <div className={styles.formRow}>
                                    <label>
                                        Limit uczestników:
                                        <input
                                            className={styles.input}
                                            type="number"
                                            value={participantLimit}
                                            onChange={(e) => setParticipantLimit(e.target.value)}/>
                                    </label>
                                </div>
                                <div className={styles.formRow}>
                                    <label>
                                        Ilość gemów w secie:
                                        <input
                                            className={styles.input}
                                            type="number"
                                            value={numberOfPointsInSet}
                                            onChange={(e) => setNumberOfPointsInSet(e.target.value)}/>
                                    </label>
                                </div>
                                <div className={styles.formRow}>
                                    <label>
                                        Wpisowe BTT:
                                        <input
                                            className={styles.input}
                                            type="number"
                                            value={tournamentEntryFeeMember}
                                            onChange={(e) => setTournamentEntryFeeMember(e.target.value)}/>
                                    </label>
                                </div>
                                <div className={styles.formRow}>
                                    <label>
                                        Wpisowe Pozostali:
                                        <input
                                            className={styles.input}
                                            type="number"
                                            value={tournamentEntryFeeNonMember}
                                            onChange={(e) => setTournamentEntryFeeNonMember(e.target.value)}/>
                                    </label>
                                </div>
                                <div className={styles.formRow}>
                                    <label>
                                        Oplata za mecz ligowy:
                                        <input
                                            className={styles.input}
                                            type="number"
                                            value={leagueMatchFee}
                                            onChange={(e) => setLeagueMatchFee(e.target.value)}/>
                                    </label>
                                </div>
                                <div className={styles.formRow}>
                                    <label>
                                        Tiebreak w 3 secie ?
                                        <input
                                            type="checkbox"
                                            checked={isThirdSetTiebreak}
                                            onChange={(e) => setIsThirdSetTiebreak(e.target.checked)}
                                            className={styles.checkbox}/>
                                    </label>
                                </div>
                                <div className={styles.formRow}>
                                    <label>
                                        Liga ?
                                        <input
                                            type="checkbox"
                                            checked={isLeague}
                                            onChange={(e) => setIsLeague(e.target.checked)}
                                            className={styles.checkbox}/>
                                    </label>
                                </div>
                                <div className={styles.formRow}>
                                    <label>
                                        Opis:
                                        <textarea
                                            className={styles.textarea}
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}/>
                                    </label>
                                </div>
                                <div className={styles.formRow}>
                                    <label>
                                        Data turnieju:
                                        <input
                                            className={styles.input}
                                            type="datetime-local"
                                            value={tournamentDate}
                                            onChange={(e) => setTournamentDate(e.target.value)}/>
                                    </label>
                                </div>
                                <div className={styles.formRow}>
                                    <label>
                                        Start zapisów:
                                        <input
                                            className={styles.input}
                                            type="datetime-local"
                                            value={registrationStartDate}
                                            onChange={(e) => setRegistrationStartDate(e.target.value)}/>
                                    </label>
                                </div>
                                <div className={styles.formRow}>
                                    <label>
                                        Koniec zapisów:
                                        <input
                                            className={styles.input}
                                            type="datetime-local"
                                            value={registrationEndDate}
                                            onChange={(e) => setRegistrationEndDate(e.target.value)}/>
                                    </label>
                                </div>
                                <div className={styles.formRow}>
                                    <label>
                                        Status:
                                        <select
                                            className={styles.input}
                                            value={tournamentStatus}
                                            onChange={(e) => setTournamentStatus(e.target.value)}
                                        >
                                            <option value="">Select status</option>
                                            <option value="PLANNED">Zaplanowany</option>
                                            <option value="ACTIVE">Trwający</option>
                                            <option value="COMPLETED">Zakończony</option>
                                            <option value="HOLDED">Wstrzymany</option>
                                        </select>
                                    </label>
                                </div>
                                <div>
                                    <button
                                        className={selectedTournament ? styles.buttonUpdate : styles.buttonAdd}
                                        onClick={selectedTournament ? handleUpdateTournament : handleAddTournament}
                                    >
                                        {selectedTournament ? 'Aktualizuj turniej' : 'Dodaj turniej'}
                                    </button>
                                </div>
                            </div>
                        )}
                        <div className={styles.tableContainer}>
                            <table className={styles.table}>
                                <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Tytuł</th>
                                    <th>Akcje</th>
                                    <th>Typ</th>
                                    <th>Status</th>
                                    <th>Limit</th>
                                    <th>Gemy</th>
                                    <th>Wpisowe BTT</th>
                                    <th>Wpisowe inni</th>
                                    <th>opłata ligowa</th>
                                    <th>Data</th>
                                    <th>Start zapisów</th>
                                    <th>Koniec zapisów</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tournaments.map((tournament) => (
                                    <tr key={tournament.id}>
                                        <td>{tournament.id}</td>
                                        <td>{tournament.title}</td>
                                        <td className={styles.actions}>
                                            {(tournament.status === 'PLANNED' || tournament.status === 'ACTIVE') && (
                                                <>
                                                    <button
                                                        className={styles.buttonEdit}
                                                        onClick={() => {
                                                            setSelectedTournament(tournament);
                                                            setTitle(tournament.title);
                                                            setParticipantLimit(tournament.participantLimit.toString());
                                                            setNumberOfPointsInSet(tournament.numberOfPointsInSet.toString());
                                                            setTournamentEntryFeeMember(tournament.tournamentEntryFeeMember.toString());
                                                            setTournamentEntryFeeNonMember(tournament.tournamentEntryFeeNonMember.toString());
                                                            setLeagueMatchFee(tournament.leagueMatchFee);
                                                            setIsThirdSetTiebreak(tournament.isThirdSetTiebreak);
                                                            setIsLeague(tournament.isLeague);
                                                            setDescription(tournament.description);
                                                            setTournamentDate(tournament.tournamentDate.slice(0, 16));
                                                            setRegistrationStartDate(tournament.registrationStartDate.slice(0, 16));
                                                            setRegistrationEndDate(tournament.registrationEndDate.slice(0, 16));
                                                            setTournamentStatus(tournament.status);
                                                            setTournamentType(tournament.type);
                                                            setShowForm(true);
                                                        }}
                                                    >
                                                        Edytuj
                                                    </button>
                                                </>
                                            )}
                                            {tournament.status === 'PLANNED' && (
                                                <>
                                                    <button
                                                        className={styles.buttonStart}
                                                        onClick={() => handleStartTournament(tournament.id)}
                                                    >
                                                        Start
                                                    </button>
                                                    <button className={`${styles.buttonDelete}`}
                                                            onClick={() => handleDeleteTournament(tournament)}>
                                                        Usuń
                                                    </button>
                                                </>
                                            )}
                                        </td>
                                        <td>{tournament.type}</td>
                                        <td>{tournament.status}</td>
                                        <td>{tournament.participantLimit}</td>
                                        <td>{tournament.numberOfPointsInSet}</td>
                                        <td>{tournament.tournamentEntryFeeMember}</td>
                                        <td>{tournament.tournamentEntryFeeNonMember}</td>
                                        <td>{tournament.leagueMatchFee}</td>
                                        <td>{formatDateTime(tournament.tournamentDate)}</td>
                                        <td>{formatDateTime(tournament.registrationStartDate)}</td>
                                        <td>{formatDateTime(tournament.registrationEndDate)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) :
                (<p className={styles.access}>Brak uprawnień</p>)
    );
};

export default AdminTournaments;
