import React, {useState, useEffect, useCallback, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {format} from "date-fns-tz";
import {pl} from 'date-fns/locale';
import {cloneDeep} from 'lodash';
import {cancelReservation as cancelReservationApi, createReservation, getUserData} from "../../api/api";
import ReservationCell from "./ReservationCell";
import TimeCell from "../TimeCell/TimeCell";
import PropTypes from 'prop-types';
import styles from './ReservationList.module.css'
import CustomModal from "../../UI/Modal/CustomModal";
import Spinner from "../Spinner/Spinner";

const ReservationList = ({
                             courts,
                             selectedDate,
                             reservations,
                             userId,
                             startHour,
                             endHour,
                             updateReservations,
                             showAfter14,
                             priceChangeTime,
                             priceBeforeChange,
                             priceAfterChange,
                             coachFee,
                             enableReservation
                         }) => {

    const [cellData, setCellData] = useState({});
    const [showReserveButton, setShowReserveButton] = useState(false);
    const [updateKey, setUpdateKey] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [reservationProjection, setReservationProjection] = useState([]);
    const [userStatus, setUserStatus] = useState({isMember: null, isCoach: null, payedToDate: null});
    const [payedToDateTime, setPayedToDateTime] = useState(null);
    const [reservationIdToCancel, setReservationIdToCancel] = useState(null);
    const [confirmLabel, setConfirmLabel] = useState("");
    const [showConfirmButton, setShowConfirmButton] = useState(false);
    const [isNonMemberPlayerIncluded, setIsNonMemberPlayerIncluded] = useState(false);
    const [isTennisLessons, setIsTennisLessons] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (userStatus.payedToDate) {
            const date = new Date(userStatus.payedToDate);
            date.setDate(date.getDate() + 1);
            setPayedToDateTime(date);
        } else {
            setPayedToDateTime(null);
        }
    }, [userStatus]);

    useEffect(() => {
        let isMounted = true; // aby uniknąć ustawiania stanu na niezamontowanym komponencie

        const fetchUserData = async () => {
            try {
                const data = await getUserData();
                if (isMounted) {
                    setUserStatus({isMember: data.member, isCoach: data.coach, payedToDate: data.payedToDate});
                }
            } catch (error) {
                console.error("Wystąpił błąd podczas pobierania danych użytkownika:", error);
            }
        };

        fetchUserData();

        return () => {
            isMounted = false
        };
    }, []);


    useEffect(() => {
        if (userStatus.isMember && userStatus.isCoach) {
            setIsTennisLessons(true);
        }
    }, [userStatus]);


    useEffect(() => {
        setShowReserveButton(false);
    }, [selectedDate]);

    useEffect(() => {
        setUpdateKey(prevUpdateKey => prevUpdateKey + 1);
    }, [cellData]);

    const generateCellKey = (courtId, time) => {
        const date = new Date(time);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');

        return `court-${courtId}-date-${year}.${month}.${day}-time-${hour}:${minute}`;
    };

    const processReservations = useCallback((reservations, cellData, selectedDate, userId) => {
        const updatedCellData = cloneDeep(cellData);
        reservations.forEach((reservation) => {
            const startTime = new Date(reservation.startTime);
            const endTime = new Date(reservation.endTime);

            const reservationDateString = startTime.toISOString().slice(0, 10);
            const selectedDateString = selectedDate.toISOString().slice(0, 10);

            if (reservationDateString !== selectedDateString) {
                return;
            }

            for (let time = startTime; time < endTime; time.setMinutes(time.getMinutes() + 30)) {
                const key = generateCellKey(reservation.courtId, time);
                if (updatedCellData[key]) {

                    if (Number(reservation.userId) === Number(userId)) {
                        updatedCellData[key].color = "green";
                        updatedCellData[key].resevationID = reservation.id;
                    } else {
                        updatedCellData[key].color = "red";
                    }
                } else {
                    console.warn("No cell found for reservation:", key);
                }
            }
        });

        return updatedCellData;
    }, []);

    const generateEmptyCellData = useCallback((courts, selectedDate, startHour, endHour) => {
        const cellData = {};
        courts.forEach((court) => {
            for (let i = startHour; i < endHour; i += 0.5) {
                const time = new Date(selectedDate);
                time.setHours(Math.floor(i), Math.floor((i % 1) * 60), 0, 0);
                const key = generateCellKey(court.id, time);
                cellData[key] = {court: court, time, color: "none", selected: false};
                // console.log(`Cell value: ${JSON.stringify(cellData[key])}`);
            }
        });
        return cellData;
    }, []);


    useEffect(() => {
        if (courts.length === 0 || !reservations || !userId) {
            return;
        }
        const emptyCellData = generateEmptyCellData(courts, selectedDate, startHour, endHour);
        const updatedCellData = processReservations(reservations, emptyCellData, selectedDate, userId);
        setCellData(updatedCellData);
    }, [reservations, selectedDate, courts, userId, generateEmptyCellData, processReservations]);

    const handleEmptyCellClick = (key) => {
        setCellData((prevData) => {
            const newData = {...prevData};

            if (!newData.hasOwnProperty(key)) {
                console.error(`Key ${key} not found in newData`);
            }

            if (newData[key].color === "none") {
                newData[key] = {
                    ...newData[key],
                    color: "yellow",
                    selected: true,
                };
                setShowReserveButton(true);
            } else if (newData[key].color === "yellow") {
                newData[key] = {
                    ...newData[key],
                    color: "none",
                    selected: false,
                };
                setShowReserveButton(Object.values(newData).some((cell) => cell.selected));
            }

            const selectedCells = Object.values(newData).filter((cell) => cell.selected);
            if (selectedCells.length > 0) {
                const cellsByCourt = selectedCells.reduce((acc, cell) => {
                    if (!acc[cell.court.id]) {
                        acc[cell.court.id] = [];
                    }
                    acc[cell.court.id].push(cell);
                    return acc;
                }, {});

                const reservationProjections = [];

                Object.values(cellsByCourt).forEach(courtCells => {
                    const sortedCells = courtCells.sort((a, b) => a.time - b.time);
                    const courtId = sortedCells[0].court.id;
                    const courtName = sortedCells[0].court.name;
                    let currentStartTime = sortedCells[0].time;

                    for (let i = 1; i < sortedCells.length; i++) {
                        const timeDifferenceInMinutes = (sortedCells[i].time - sortedCells[i - 1].time) / (60 * 1000);

                        if (timeDifferenceInMinutes > 30) {
                            const endTime = new Date(sortedCells[i - 1].time.getTime() + 30 * 60 * 1000);
                            const currentStartTimeIndex = sortedCells.findIndex(cell => cell.time.getTime() === currentStartTime.getTime());
                            const costCells = sortedCells.slice(currentStartTimeIndex, i);
                            reservationProjections.push({
                                courtId: courtId,
                                courtName: courtName,
                                startTime: currentStartTime,
                                endTime,
                                cost: calculateReservationCost(costCells)
                            });
                            currentStartTime = sortedCells[i].time;
                        }
                    }

                    const endTime = new Date(sortedCells[sortedCells.length - 1].time.getTime() + 30 * 60 * 1000);
                    const currentStartTimeIndex = sortedCells.findIndex(cell => cell.time.getTime() === currentStartTime.getTime());
                    const costCells = sortedCells.slice(currentStartTimeIndex);
                    reservationProjections.push({
                        courtId: courtId,
                        courtName: courtName,
                        startTime: currentStartTime,
                        endTime,
                        cost: calculateReservationCost(costCells)
                    });
                });

                setReservationProjection(reservationProjections);

            } else {
                setReservationProjection(null);
            }

            return newData;
        });
    };
    const generateTimeLabels = useCallback(
        (selectedDate, startHour, endHour) => {
            const timeLabels = [];
            const start = Math.floor(startHour * 2);
            const end = Math.floor(endHour * 2);
            for (let i = start; i <= end - 0.5; i++) {
                const time = new Date(selectedDate);
                time.setUTCHours(Math.floor(i / 2), (i % 2) * 30, 0, 0);
                timeLabels.push(time);
            }
            return timeLabels;
        },
        []
    );

    const timeLabels = useMemo(() => {
        return generateTimeLabels(selectedDate, showAfter14 ? 14 : startHour, endHour);
    }, [showAfter14, startHour, endHour]);

    const handleReserveButtonClick = async () => {
        setShowReserveButton(false);
        if (!reservationProjection || reservationProjection.length === 0) {
            setModalMessage("Nie wybrano żadnych rezerwacji.");
            setIsModalOpen(true);
            return;
        }

        setIsLoading(true);

        for (const projection of reservationProjection) {

            let additionalInfo = "";
            if (isNonMemberPlayerIncluded) {
                additionalInfo = "nonMemberPlayerIncluded";
            } else if (isTennisLessons) {
                additionalInfo = "tennisLessons";
            }

            const reservation = {
                courtId: projection.courtId,
                startTime: format(new Date(projection.startTime), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", {timeZone: 'UTC'}),
                endTime: format(new Date(projection.endTime), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", {timeZone: 'UTC'}),
                cost: projection.cost,
                additionalInfo: additionalInfo
            };

            try {
                const response = await createReservation(reservation);
                setIsLoading(false);
                setModalMessage(response.data);
                setIsModalOpen(true);
                setShowConfirmButton(false);
            } catch (error) {
                console.error("Error while creating reservation:", error);
                setModalMessage(error.response.data);
                console.log(error.response.data);
                setIsModalOpen(true);
                setIsLoading(false);
            }
        }

        // Resetujemy stan po zakończeniu rezerwacji
        const initialCellData = {};
        setCellData(initialCellData);
        setReservationProjection(null);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        updateReservations();
    };

    const calculateReservationCost = (reservations) => {
        let cost = 0;
        reservations.forEach((reservation) => {
            const time = new Date(reservation.time);
            const changeTime = new Date(time);
            const [hours, minutes] = priceChangeTime.split(":");
            changeTime.setHours(hours, minutes, 0, 0);

            if (userStatus.isCoach) {
                cost += coachFee / 2;
                return
            }

            if (time >= changeTime) {
                cost += (priceAfterChange / 2);
            } else {
                cost += (priceBeforeChange / 2);
            }
        });
        if (userStatus.isMember && !userStatus.isCoach) {
            cost *= 0.4;
        }
        return cost;
    };

    const handleReservedCellClick = (reservationId) => {
        setReservationIdToCancel(reservationId);
        setModalMessage("Czy na pewno chcesz anulować rezerwację?");
        setConfirmLabel("Anuluj rezerwację");
        setShowConfirmButton(true);
        setIsModalOpen(true);
    };

    const handleConfirmCancel = async () => {
        if (reservationIdToCancel) {
            try {
                const response = await cancelReservationApi(reservationIdToCancel);
                setModalMessage(response.data);
                setReservationIdToCancel(null);
                setShowConfirmButton(false);
                setIsModalOpen(true);
            } catch (error) {
                if (error.message === "Invalid or missing token") {
                    navigate("/login", {replace: true});
                }
                console.error("Error while canceling reservation:", error);
                setModalMessage(error.response.data);
                console.log(error.response.data);
                setShowConfirmButton(false);
                setIsModalOpen(true);
            }
        }
    };

    if (isLoading) {
        return <Spinner size={"large"}/>;
    }

    return (
        <div>
            <CustomModal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                message={modalMessage}
                confirmLabel={showConfirmButton ? confirmLabel : undefined}
                onConfirm={showConfirmButton ? handleConfirmCancel : undefined}
                isPositiveMessage={modalMessage.includes("dodana") || modalMessage.includes("anulowana")}
                cancelLabel="Zamknij"
                confirmButtonColor="red"
                cancelButtonColor="green"
            />
            {!isLoading && (
                <div className={styles.mainContainer}>
                    <div className={styles.container}>
                        {courts.map((court) => {
                            const filteredCellData = Object.values(cellData)
                                .filter((cell) => cell.court.id === court.id)
                                .filter((cell) => {
                                    const localTime = new Date(cell.time).toLocaleString("pl-PL", {
                                        timeZone: "Europe/Warsaw",
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    });
                                    const localHour = parseInt(localTime.slice(0, 2));
                                    return !showAfter14 || localHour >= 14;
                                })
                                .sort((a, b) => a.time - b.time);
                            return (
                                <div key={court.id} className={styles.courtRow}>
                                    <span className={styles.courtName}>{court.name}</span>
                                    <div className={styles.timeAndCells}>
                                        <div className={styles.timeRow}>
                                            {timeLabels.map((time) => {
                                                const formattedTime = time.toISOString().substr(11, 5);
                                                return <TimeCell key={`time-${formattedTime}`} time={formattedTime}/>;
                                            })}
                                        </div>
                                        <div className={styles.cellsRow}>
                                            {filteredCellData.map((cell) => {
                                                return (
                                                    <ReservationCell
                                                        key={`${generateCellKey(cell.court.id, cell.time)}-${updateKey}`}
                                                        cell={cell}
                                                        onClick={() => cell.color === "green" ? handleReservedCellClick(cell.resevationID) : handleEmptyCellClick(generateCellKey(cell.court.id, cell.time))}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            {
                showReserveButton && (
                    <div className={styles.buttonWithProjection}>
                        {enableReservation ? (
                            <button className={styles.reserveButton}
                                    onClick={handleReserveButtonClick}
                            >
                                Rezerwuj
                            </button>
                        ) : (
                            <p className={styles.holdReservation}>Rezerwacje wstrzymane</p>
                        )}
                        {reservationProjection && (
                            <div className={styles.reservationProjectionContainer}>
                                <p>{format(selectedDate, 'dd-MM eeee', {locale: pl})}</p>
                                {reservationProjection.map((projection, index) => (
                                    <div key={index} className={styles.reservationProjection}>
                                        <p>
                                            {projection.courtName} &nbsp;
                                            {format(projection.startTime, 'HH:mm')} - {format(projection.endTime, 'HH:mm')} &nbsp;
                                            {
                                                (userStatus.isMember === false || isTennisLessons || isNonMemberPlayerIncluded) &&
                                                (payedToDateTime === null || payedToDateTime <= selectedDate) && (
                                                    <>koszt: {projection.cost.toFixed(2)} zł</>
                                                )
                                            }
                                        </p>
                                    </div>
                                ))}
                                <div className={styles.checkboxContainer}>
                                    {
                                        userStatus.isMember && !userStatus.isCoach && (
                                            <label className={styles.checkboxLabel}>
                                                <input
                                                    type="checkbox"
                                                    className={styles.checkboxInput}
                                                    checked={isNonMemberPlayerIncluded}
                                                    onChange={(e) => {
                                                        setIsNonMemberPlayerIncluded(e.target.checked);
                                                    }}
                                                />
                                                <span className={styles.labelText}>Dopłata za gracza spoza BTT</span>
                                            </label>
                                        )
                                    }
                                </div>
                                <div className={styles.checkboxContainer}>
                                    {
                                        userStatus.isMember && userStatus.isCoach && (
                                            <label className={styles.checkboxLabel}>
                                                <input
                                                    type="checkbox"
                                                    className={styles.checkboxInput}
                                                    checked={isTennisLessons}
                                                    onChange={(e) => {
                                                        console.log('Checkbox onChange:', e.target.checked);
                                                        setIsTennisLessons(e.target.checked);
                                                    }}
                                                />
                                                <span className={styles.labelText}>Lekcje tenisa</span>
                                            </label>
                                        )
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                )
            }
        </div>
    );
};

ReservationList.propTypes = {
    courts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    selectedDate: PropTypes.instanceOf(Date).isRequired,
    reservations: PropTypes.array.isRequired,
    startHour: PropTypes.number.isRequired,
    endHour: PropTypes.number.isRequired,
};

export default ReservationList;