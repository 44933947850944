import React from 'react';
import styles from '../ScoreRules/ScoreRules.module.css';
import competitionSystems from "../../assets/images/systemybtt.png";

const CompetitionSystemsDescription = () => {
    return (
        <div className={styles.scoreRulesContainer}>
            <h1>Systemy rozgrywek w turniejach BTT</h1>

            <div className={styles.scoreRulesText}>

                <h5>1. <i>Każdy z każdym</i></h5>
                <p className={styles.competitionDescription}>W tym systemie każdy uczestnik gra z każdym innym
                    uczestnikiem turnieju. Kolejność meczów jest ustalana według metody Bergera parowania rywali w
                    systemie kołowym.</p>
                <p className={styles.competitionDescription}>Klasyfikacja w tabeli jest określana według
                    następuących
                    kryteriów:</p>
                <li>Liczba wygranych setów,</li>
                <li>Liczba, będąca różnicą pomiędzy gemami wygranymi i przegranymi. Punkty tiebreaka trzeciego seta
                    przeliczane są proporcjonalnie do liczby gemów w secie,
                    np. jeżeli pierwsze dwa sety toczą się do 4 wygranych gemów, to 10 pkt tiebreka przeliczana jest na
                    4 pkt. ,
                </li>
                <li>W przypadku, gdy dwóch graczy posiada równą liczby punktów oraz ten sam wynik, będący różnicą gemów
                    wygranych i przegranych,
                    decyduje bezpośredni wynik meczu pomiędzy tymi graczami.
                </li>

                <h5>2. <i>System pucharowy</i></h5>
                <p className={styles.competitionDescription}>Uczestnicy są eliminowani po każdej przegranej.
                    Turniej trwa, dopóki nie pozostanie jeden zwycięzca. Ponieważ w BTT przyjęliśmy zasadę rozegrania
                    przynamniej dwóch meczów w turnieju, to system ten używany jest głównie jako druga część systemu
                    'Faza grupowa + faza finałowa'.</p>

                <h5>3. <i>Brazylijski do dwóch przegranych</i></h5>
                <p className={styles.competitionDescription}>Podobny do systemu pucharowego,
                    z tą różnicą, że po pierwszej przegranej gracze otrzymują drugą szansę na wygranie nawet całego
                    turnieju. Po drugiej porażce
                    zawodnik kończy swój udział w zawodach.
                </p>

                <h5>4. <i>Faza grupowa + faza finałowa</i></h5>
                <p className={styles.competitionDescription}>
                    Turniej rozpoczyna się fazą grupową, w której uczestnicy grają w grupach każdy z każdym.
                    Dwóch najlepszych z każdej grupy awansuje do fazy finałowej, gdzie grają w systemie pucharowym.
                </p>

                <br/>
                <br/>

                <h5><i>Ilość meczów w zależności od liczby graczy</i></h5>
                <p className={styles.competitionDescription}>
                    Poniższa tabela prezentuje ilość meczów, które należy rozegrać w turnieju dla wybranego systemu rozgrywek.
                    Druga liczba wskazuje na minimalną ilość meczów, którą zawodnik winien zagrać aby mieć możliwość zwycieżenia w turnieju.
                </p>

                <div className={styles.imageContainer}>
                </div>
                <div className={styles.imageContainer}>
                    <img src={competitionSystems} alt="systemy zestawienie" className={styles.picturesOfCompetition}/>
                </div>

            </div>
        </div>
    );
};

export default CompetitionSystemsDescription;
