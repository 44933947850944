import {updateConfig} from "../../api/api";
import styles from './ConfigurationTable.module.css';
import {useCourtConfig} from "../../utils";

const ConfigurationTable = () => {
    const [config, setConfig, loading] = useCourtConfig();


    const handleUpdateConfig = async () => {
        const result = await updateConfig(config);
        if (result.success) {
            setConfig(result.data);
        }
    };

    if (loading) return <div>Loading...</div>;

    return (
        <div className={styles.container}>
            <h3>Konfiguracja</h3>
            <br/>
            <div>
                <label className={styles.label}>
                    Godzina rozpoczęcia rezerwacji:
                    <input
                        type="time"
                        className={styles.input}
                        value={config.reservationStartTime}
                        onChange={(e) => setConfig({...config, reservationStartTime: e.target.value})}
                    />
                </label>
            </div>
            <div>
                <label className={styles.label}>
                    Godzina zakończenia rezerwacji:
                    <input
                        type="time"
                        className={styles.input}
                        value={config.reservationEndTime}
                        onChange={(e) => setConfig({...config, reservationEndTime: e.target.value})}
                    />
                </label>
            </div>
            <div>
                <label className={styles.label}>
                    Liczba kortów:
                    <input
                        type="number"
                        className={styles.input}
                        value={config.numberOfCourts}
                        onChange={(e) => setConfig({...config, numberOfCourts: e.target.value})}
                    />
                </label>
            </div>
            <div>
                <label className={styles.label}>
                    Godzina zmiany ceny:
                    <input
                        type="time"
                        className={styles.input}
                        value={config.priceChangeTime}
                        onChange={(e) => setConfig({...config, priceChangeTime: e.target.value})}
                    />
                </label>
            </div>
            <div>
                <label className={styles.label}>
                    Cena 1 h przed czasem zmiany:
                    <input
                        type="number"
                        step="0.01"
                        className={styles.input}
                        value={config.priceBeforeChange}
                        onChange={(e) => setConfig({...config, priceBeforeChange: e.target.value})}
                    />
                </label>
            </div>
            <div>
                <label className={styles.label}>
                    Cena 1 h po czasie zmiany:
                    <input
                        type="number"
                        step="0.01"
                        className={styles.input}
                        value={config.priceAfterChange}
                        onChange={(e) => setConfig({...config, priceAfterChange: e.target.value})}
                    />
                </label>
            </div>
            <div>
                <label className={styles.label}>
                    Opłata za 7 dni:
                    <input
                        type="number"
                        step="0.01"
                        className={styles.input}
                        value={config.weeklyFee7Days}
                        onChange={(e) => setConfig({...config, weeklyFee7Days: e.target.value})}
                    />
                </label>
            </div>
            <div>
                <label className={styles.label}>
                    Opłata za 14 dni:
                    <input
                        type="number"
                        step="0.01"
                        className={styles.input}
                        value={config.weeklyFee14Days}
                        onChange={(e) => setConfig({...config, weeklyFee14Days: e.target.value})}
                    />
                </label>
            </div>
            <div>
                <label className={styles.label}>
                    Opłata za 30 dni:
                    <input
                        type="number"
                        step="0.01"
                        className={styles.input}
                        value={config.monthlyFee30Days}
                        onChange={(e) => setConfig({...config, monthlyFee30Days: e.target.value})}
                    />
                </label>
            </div>
            <div>
                <label className={styles.label}>
                    Opłata członkowska ulgowa:
                    <input
                        type="number"
                        step="0.01"
                        className={styles.input}
                        value={config.discountedMembershipFee}
                        onChange={(e) => setConfig({...config, discountedMembershipFee: e.target.value})}
                    />
                </label>
            </div>
            <div>
                <label className={styles.label}>
                    Opłata członkowska normalna:
                    <input
                        type="number"
                        step="0.01"
                        className={styles.input}
                        value={config.normalMembershipFee}
                        onChange={(e) => setConfig({...config, normalMembershipFee: e.target.value})}
                    />
                </label>

                <label className={styles.label}>
                    Opłata członkowska rodzinna:
                    <input
                        type="number"
                        step="0.01"
                        className={styles.input}
                        value={config.familyMembershipFee}
                        onChange={(e) => setConfig({...config, familyMembershipFee: e.target.value})}
                    />
                </label>
            </div>
            <div>
                <label className={styles.label}>
                    Opłata trenerska za 1 godzinę:
                    <input
                        type="number"
                        step="0.01"
                        className={styles.input}
                        value={config.coachFee}
                        onChange={(e) => setConfig({...config, coachFee: e.target.value})}
                    />
                </label>
            </div>            <div>
                <label className={styles.label}>
                    Czy można rezerwować ?
                    <input
                        type="checkbox"
                        className={styles.input}
                        checked={config.enableReservation}
                        onChange={(e) => setConfig({...config, enableReservation: e.target.checked})}
                    />
                </label>
            </div>
            <button className={styles.buttonUpdate} onClick={handleUpdateConfig}>
                Aktualizuj Konfigurację
            </button>
        </div>
    );
};

export default ConfigurationTable;
