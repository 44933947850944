// TimeCell.js
import React from "react";
import styles from './TimeCell.module.css';

const TimeCell = ({ time }) => {
    return (
        <div className={styles.timeCell}>
            {time}
        </div>
    );
};

export default TimeCell;
