import React from "react";
import { useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledCell = styled.div`
    width: 80px;
    height: 25px;
    border: 1px solid #ccc;
    margin: 2px 5px 2px 2px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({isClickable}) => (isClickable ? 'pointer' : 'default')};
    background-color: ${({backgroundColor}) => backgroundColor};
    transition: background-color 0.3s;

    &:hover {
        background-color: ${({isClickable,backgroundColor}) => (isClickable ? 'rgba(128, 128, 128, 0.5)' : backgroundColor)};
    }
`;

const ReservationCell = ({ cell, onClick }) => {
    const { color } = cell;

    let backgroundColor = "transparent";
    let isClickable = true;

    const now = new Date();
    const isPast = cell.time < now;

    let handleClick = onClick;

    if (color === "green" || color === "red" || (color === "none" && isPast)) {
        backgroundColor = color === "green" ? "rgba(0, 255, 0, 0.9)" : color === "red" ? "rgba(255, 50, 10, 0.8)" : backgroundColor;
        if (color !== "green" || isPast) {
            isClickable = false;
        }
    } else if (color === "yellow") {
        backgroundColor = "rgba(60, 130, 240, 0.8)";
    }

    const handleCellClick = useCallback(() => {
        if (isClickable) {
            handleClick();
        }
    }, [isClickable, handleClick]);

    return (
        <StyledCell
            backgroundColor={backgroundColor}
            isClickable={isClickable}
            onClick={handleCellClick}
        />
    );
};

ReservationCell.propTypes = {
    cell: PropTypes.shape({
        court: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        time: PropTypes.instanceOf(Date).isRequired,
        color: PropTypes.string.isRequired,
        selected: PropTypes.bool.isRequired,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
};

export default ReservationCell;
