import moment from 'moment-timezone';
import React, {useState, useEffect} from "react";
import {fetchConfig} from "./api/api";
import styles from "./components/CompetitionSystems/MatchesTable/MatchesTable.module.css";

export const formatDateDDMMYY = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
        "sty",
        "lut",
        "mar",
        "kwi",
        "maj",
        "cze",
        "lip",
        "sie",
        "wrz",
        "paź",
        "lis",
        "gru",
    ];
    return `${date.getDate().toString().padStart(2, "0")} ${monthNames[date.getMonth()]}`;
};

export const getStatus = (status) => {
    switch (status) {
        case "REGISTERED":
            return "REG";
        case "CANCELED":
            return "CAN";
        case "BLOCKED":
            return "BLO";
        case "MEMBER":
            return "MEM";
        case "PERIODIC":
            return "PER";
        case "PAYED":
            return "PAY";
        case "LESSONS":
            return "LES";
        case "NMEMBER":
            return "NME";
        case "PAID":
            return "PAY";
        case "INITIATED":
            return "INI";
        case "PENDING":
            return "PEN";
        default:
            return status;
    }
};

export const formatFullDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('pl-PL', {day: 'numeric', month: 'long', year: 'numeric'});
};

export const formatTime = (timeString) => {
    const date = new Date(timeString);
    return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
};

export const canAdminCancelReservation = (status) => {
    return status !== "CANCELED";
};

export const canCancelReservation = (startTime, status) => {
    const now = moment.tz("Europe/Warsaw").toDate();
    return new Date(startTime) > now && status !== "CANCELED" || status === "BLOCKED";
};

export function useCourtConfig() {
    const [config, setConfig] = useState({
        // ... initial state
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const result = await fetchConfig();
                if (result.success) {
                    setConfig(result.data);
                }
            } catch (error) {
                console.error("Failed to fetch configuration:", error);
                setError(error);
                // Handle the error appropriately
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return [config, setConfig, loading, error];
}

export const formatDateTime = (isoDateString) => {
    if (!isoDateString) return ''; // Return an empty string if the input is falsy
    const date = new Date(isoDateString);
    const padTo2Digits = (num) => num.toString().padStart(2, '0');
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1), // getMonth() is zero-based
        date.getFullYear(),
    ].join('/') + ' ' + [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
    ].join(':');
};

export const formatFirstLetterOfName = (name) => {
    if (!name) {
        return '';
    }
    return `${name.charAt(0)}`;
};

export const formatSetScore = (set) => {
    if (set.playerOneGames === 0 && set.playerTwoGames === 0) {
        return null; // Don't display sets with 0-0 scores
    }

    // Check for tiebreak and format the score string accordingly
    const tiebreakScore1player = set.isTiebreak ? (
        <sup>{set.playerOneTiebreakPoints}</sup>
    ) : null;

    const tiebreakScore2player = set.isTiebreak ? (
        <sup>{set.playerTwoTiebreakPoints}</sup>
    ) : null;

    return (
        <div className={styles.setScore}>
            <div>
                {set.playerOneGames} {tiebreakScore1player}
            </div>
            <div>
                {set.playerTwoGames} {tiebreakScore2player}
            </div>
        </div>
    );
};

styles.setWinning = undefined;
export const renderMatchResult = (setResults) => {
    let player1SetWins = 0;
    let player2SetWins = 0;

    const setScores = setResults.map((set, index) => {
        // Calculate set wins
        if (set.playerOneGames > set.playerTwoGames) {
            player1SetWins++;
        } else if (set.playerTwoGames > set.playerOneGames) {
            player2SetWins++;
        }

        // Return formatted set scores
        return formatSetScore(set);
    }).filter(Boolean); // Filter out null values (0-0 scores)
    return (
        <>
            <div className={styles.resultOfMatchContainer}>
                <div className={styles.resultOfMatch}>
                    <div className={styles.setWinning}>
                        {!(player1SetWins === 0 && player2SetWins === 0) &&
                            <>
                                <div><strong>{player1SetWins}</strong></div>
                                <div><strong>{player2SetWins}</strong></div>
                            </>
                        }
                    </div>
                    <div>
                        {setScores.length > 0 ? setScores[0] : null}
                    </div>
                    <div>
                        {setScores.length > 0 ? setScores[1] : null}
                    </div>
                    <div>
                        {setScores.length > 0 ? setScores[2] : null}
                    </div>
                    <div></div>
                </div>
            </div>

        </>
    );
};

export const renderMatchResultForSingleElimination = (setResults) => {
    let player1SetWins = 0;
    let player2SetWins = 0;

    const setScores = setResults.map((set, index) => {
        // Calculate set wins
        if (set.playerOneGames > set.playerTwoGames) {
            player1SetWins++;
        } else if (set.playerTwoGames > set.playerOneGames) {
            player2SetWins++;
        }

        // Return formatted set scores
        return formatSetScore(set);
    }).filter(Boolean); // Filter out null values (0-0 scores)
    return (
        <>
            <div className={styles.resultOfMatchContainer}>
                <div className={styles.resultOfMatchForSingleEliminationBracket}>
                    <div className={styles.setWinning}>
                        {!(player1SetWins === 0 && player2SetWins === 0) &&
                            <>
                                <div><strong>{player1SetWins}</strong></div>
                                <div><strong>{player2SetWins}</strong></div>
                            </>
                        }
                    </div>
                    <div>
                        {setScores.length > 0 ? setScores[0] : null}
                    </div>
                    <div>
                        {setScores.length > 0 ? setScores[1] : null}
                    </div>
                    <div>
                        {setScores.length > 0 ? setScores[2] : null}
                    </div>
                    <div></div>
                </div>
            </div>

        </>
    );
};


export const checkIfResultsEntered = (match) => {
    if (match.injuryResignation || match.walkover) {
        return true; // If either is true, we consider the match has results
    }
    return match.setResults.some(set => {
        return set.playerOneGames > 0 ||
            set.playerTwoGames > 0 ||
            set.playerOneTiebreakPoints > 0 ||
            set.playerTwoTiebreakPoints > 0;
    });
};

export const getMatchWinnerId = (match) => {
    // If there's a walkover or injury resignation, return the winner directly
    if (match.walkover) return match.walkoverWinnerId;
    if (match.injuryResignation) {
        // The winner is the one who did not resign due to injury
        return match.injuryResignationParticipantId === match.participant1Id ? match.participant2Id : match.participant1Id;
    }

    // Count sets won by each player
    let player1SetsWon = 0;
    let player2SetsWon = 0;

    match.setResults.forEach(set => {
        if (set.playerOneGames > set.playerTwoGames) player1SetsWon++;
        if (set.playerTwoGames > set.playerOneGames) player2SetsWon++;
    });

    // Determine the winner based on sets won
    if (player1SetsWon > player2SetsWon) return match.participant1Id;
    if (player2SetsWon > player1SetsWon) return match.participant2Id;

    return null; // In case of a draw, which should not happen in tennis
};


export const renderPlayerName = (playerId, playerLastName, playerFirstName, match, seedNumber) => {
    let name = `${playerLastName} ${formatFirstLetterOfName(playerFirstName)}`;
    const winnerId = getMatchWinnerId(match);

    // Append seeding number if exists
    if (seedNumber) {
        name += ` (${seedNumber})`;
    }

    // Append (WO) or (Ret) as appropriate
    if (match.walkover && match.walkoverWinnerId === playerId) {
        name += " (wo)";
    } else if (match.injuryResignation && match.injuryResignationParticipantId === playerId) {
        name += " (ret)";
    }

    // Apply bold style conditionally
    return playerId === winnerId ? <strong>{name}</strong> : name;
};

export const renderPartnerName = (playerId, playerPartnerLastName, playerPartnerFirstName, match) => {
    let name = `${playerPartnerLastName} ${formatFirstLetterOfName(playerPartnerFirstName)}`;
    const winnerId = getMatchWinnerId(match);

    // Apply bold style conditionally
    return playerId === winnerId ? <strong>{name}</strong> : name;
};