import {useState, useEffect, useContext} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import jwt_decode from "jwt-decode";
import CalendarBar from "../../components/CalendarBar/CalendarBar";
import ReservationList from "../../components/ReservationList/ReservationList";
import ConfigContext from "../../contexts/ConfigContext";
import {fetchReservations, fetchCourts, redirectToLoginIfInvalidToken} from '../../api/api';
import styles from './Reservations.module.css';
import moment from 'moment';
import 'moment/locale/pl';
import Spinner from "../../components/Spinner/Spinner";

const Reservations = () => {
    const [selectedDate, setSelectedDate] = useState(moment().toDate());
    const [reservations, setReservations] = useState([]);
    const [courts, setCourts] = useState([]);
    const [userId, setUserId] = useState(null);
    const [showAfter14, setShowAfter14] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { config } = useContext(ConfigContext);

    const getHourFromTimeString = (timeString) => {
        return timeString ? parseInt(timeString.split(':')[0], 10) : 0;
    };

    const startHour = getHourFromTimeString(config.reservationStartTime);
    const endHour = getHourFromTimeString(config.reservationEndTime);
    const numberOfCourts = config.numberOfCourts;

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        redirectToLoginIfInvalidToken(navigate, location.pathname);
    }, [navigate, location.pathname]);

    useEffect(() => {
        const token = localStorage.getItem("token");
        setIsLoading(true);
        if (token) {
            fetchReservations(selectedDate, navigate).then((data) => {
                setReservations(data);
                setIsLoading(false);
            });
        }
    }, [selectedDate, navigate]);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            fetchCourts(navigate).then((data) => {
                const limitedCourts = data.slice(0, numberOfCourts);
                setCourts(limitedCourts);
            });
        }
    }, [numberOfCourts]);


    useEffect(() => {
        const checkToken = () => {
            const token = localStorage.getItem("token");
            if (!token) {
                navigate("/login");
            }
        };

        window.addEventListener("storage", checkToken);
        return () => window.removeEventListener("storage", checkToken);
    }, [navigate]);


    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const decoded = jwt_decode(token);
                const currentTime = Date.now() / 1000; // Convert to seconds

                if (decoded.exp && decoded.exp > currentTime) {
                    setUserId(decoded.sub);
                }
            } catch (error) {
                console.error("Error decoding JWT token:", error);
            }
        }
    }, []);



    const handleDateSelect = (date) => {
        setSelectedDate(date);
    };

    const selectedDateString = moment(selectedDate).format('D MMMM YYYY (dddd)');

    const updateReservations = () => {
        const token = localStorage.getItem("token");
        setIsLoading(true);
        if (token) {
            fetchReservations(selectedDate).then((data) => {
                setReservations(data);
                setIsLoading(false);
            });
        }
    };

    if (!config) {
        return <div>Loading configuration...</div>;
    }

    return (
        <div className={styles.container}>

            <CalendarBar
                onDateSelect={handleDateSelect}
                value={selectedDate}
            />
            {isLoading &&
                <Spinner size={"large"}/>
            }
            <div className={styles.dateAndCheckboxContainer}>
                <div className={styles.dateInfo}>
                    <div>{selectedDateString}</div>
                </div>
                <div className={styles.checkboxAfter14}>
                    <input
                        type="checkbox"
                        id="showAfter14"
                        checked={showAfter14}
                        onChange={(e) => setShowAfter14(e.target.checked)}
                    />
                    <label htmlFor="showAfter14">od 14:00</label>
                </div>
            </div>
            <ReservationList
                selectedDate={selectedDate}
                reservations={reservations}
                courts={courts}
                userId={userId}
                startHour={startHour}
                endHour={endHour}
                updateReservations={updateReservations}
                showAfter14={showAfter14}
                priceChangeTime={config.priceChangeTime}
                priceBeforeChange={config.priceBeforeChange}
                priceAfterChange={config.priceAfterChange}
                coachFee={config.coachFee}
                enableReservation={config.enableReservation}
            />
        </div>
    );
};

export default Reservations;