import React, { useState, useEffect} from "react";
import moment from "moment";
import 'moment/locale/pl';
import styles from './CalendarBar.module.css';
import { useResizeDetector } from 'react-resize-detector';
import {TfiAngleDoubleLeft, TfiAngleDoubleRight} from "react-icons/tfi";

const CalendarBar = ({ onDateSelect, value, showPastDates = false  }) => {
    const [selectedDate, setSelectedDate] = useState(value);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [dates, setDates] = useState(() => generateDates());
    const { ref } = useResizeDetector();

    moment.locale('pl');

    function generateDates(start = moment()) {
        const newDates = [];
        const startOfDates = start.clone();
        const arrowWidth = 40;
        const dateWidth = 100;
        const daysToShow = Math.floor((windowWidth - 2 * arrowWidth) / dateWidth);
        for (let i = 0; i < daysToShow; i++) {
            const date = moment(startOfDates).add(i, "days");
            newDates.push(date);
        }
        return newDates;
    }

    useEffect(() => {
        if (ref.current) {
            setWindowWidth(ref.current.offsetWidth);
        }
    }, [ref]);

    useEffect(() => {
        setSelectedDate(value);
    }, [value]);

    function handlePreviousDay() {
        const newDates = dates.map((date) => moment(date).subtract(1, "day"));
        const today = moment();
        if (showPastDates || newDates[0].isAfter(today, 'day') || newDates[0].isSame(today, 'day')) {
            setDates(newDates);
        }
    }


    function handleNextDay() {
        const newDates = dates.map((date) => moment(date).add(1, "day"));
        const maxDate = moment().add(1, 'month');
        if (newDates[dates.length - 1].isSameOrBefore(maxDate, 'day')) {
            setDates(newDates);
        }
    }

    function handleDateClick(date) {
        setSelectedDate(date);
        onDateSelect(date.toDate());
    }

    const isFirstDateToday = showPastDates ? false : dates[0].isSame(moment(), 'day');
    const isLastDateMax = dates[dates.length - 1].isSame(moment().add(1, 'month'), 'day');

    return (
        <>
            <div className={styles.container} ref={ref} >
                <button
                    className={`${styles.button} ${isFirstDateToday ? styles.buttonHidden : ''}`}
                    onClick={handlePreviousDay}
                >
                    {/*&#x276E;*/}
                    <TfiAngleDoubleLeft />
                </button>

                {dates.map((date) => (
                    <div
                        key={date.format("DD-MM")}
                        onClick={() => handleDateClick(date)}
                        className={`${styles.date} ${selectedDate && date.isSame(selectedDate, 'day') ? styles.selected : ''}`}
                    >
                        <div className={styles.dayOfWeek}>{date.format("ddd")}</div>
                        <div className={styles.dateText}>{date.format("DD-MM")}</div>
                    </div>
                ))}

                <button
                    className={`${styles.button} ${isLastDateMax ? styles.buttonHidden : ''}`}
                    onClick={handleNextDay}
                >
                    {/*&#x276F;*/}
                    <TfiAngleDoubleRight />
                </button>
            </div>

        </>

    );
};

export default CalendarBar;