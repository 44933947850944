import React, {useEffect, useState} from 'react';
// Adjust the import path as necessary
import styles from './TourReg.module.css';
import TourRegForm from "../TourRegForm/TourRegForm";
import {fetchTournaments} from "../../api/api";
import Spinner from "../Spinner/Spinner";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const TourReg = () => {
    const [tournaments, setTournaments] = useState([]);
    const [selectedTournament, setSelectedTournament] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPlannedTournaments = async () => {
            try {
                // Fetch only the tournaments with 'PLANNED' status
                const result = await fetchTournaments('PLANNED');
                if (result.success) {
                    const sortedTournaments = result.data.sort((a, b) => {
                        return new Date(a.tournamentDate) - new Date(b.tournamentDate);
                    });
                    setTournaments(sortedTournaments);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error("Failed to fetch planned tournaments", error);
                setError("Błąd pobrania danych");
                setIsLoading(false);
            }
        };

        fetchPlannedTournaments();
    }, []);

    const handleReturnToList = () => {
        setSelectedTournament(null);
    };

    // Function to check if the tournament is currently open for registration
    const isRegistrationOpen = (tournament) => {
        const now = new Date();
        const registrationStart = new Date(tournament.registrationStartDate);
        const registrationEnd = new Date(tournament.registrationEndDate);
        return now >= registrationStart && now <= registrationEnd;
    };

    // Format date for display
    const formatDateSimple = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const monthNames = ["styczeń", "luty", "marzec", "kwiecień", "maj", "czerwiec", "lipiec", "sierpień", "wrzesień", "październik", "listopad", "grudzień"];
        const month = monthNames[date.getMonth()];
        return `${day} ${month}`;
    };


    if (isLoading) return <Spinner size={"large"}/>;
    if (error) return <ErrorMessage error={error}/>;

    return (
        <div>
            {selectedTournament && <TourRegForm tournament={selectedTournament} onReturn={handleReturnToList}/>}
            <div className={styles.tournaments}>
                {!selectedTournament && tournaments.length > 0 ? (
                    tournaments.map((tournament) => (
                        <button
                            key={tournament.id}
                            className={`${styles.tournamentButton} ${isRegistrationOpen(tournament) ? '' : styles.inactive}`}
                            onClick={() => setSelectedTournament(tournament)}
                        >
                            {`${tournament.title} >> Zapisy od ${formatDateSimple(tournament.registrationStartDate)} do ${formatDateSimple(tournament.registrationEndDate)}`}
                        </button>
                    ))
                ) : (
                    !selectedTournament && <p className={styles.noTournamentsMessage}>Brak zaplanowanych turniejów.</p>
                )}
            </div>
        </div>
    );
};

export default TourReg;
