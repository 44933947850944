import React from 'react';
import page404 from '../assets/images/page404.png';
import styles from './Error.module.css';



const Error = () => {
  return (
      <section className={styles.section}>
          <img src={page404} alt="page not found" className={styles.page404} />
      </section>
  );
};
export default Error;
