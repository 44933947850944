import React, {useEffect, useState} from 'react';
import styles from './SingleEliminationBracket.module.css';
import {
    checkIfResultsEntered,
    renderMatchResultForSingleElimination,
    renderPartnerName,
    renderPlayerName
} from "../../../utils";
import {getUserData} from "../../../api/api";
import {TfiAngleDoubleLeft, TfiAngleDoubleRight} from "react-icons/tfi";

const SingleEliminationBracket = ({matches, onEditMatch, isArchived}) => {
    const [userData, setUserData] = useState({});
    const [currentRound, setCurrentRound] = useState(1);
    const [totalRounds, setTotalRounds] = useState(0); // Total rounds will be determined based on matches

    useEffect(() => {
        const fetchData = async () => {
            const data = await getUserData();
            setUserData(data);
        };
        fetchData();
    }, []);


    useEffect(() => {
        // Ensure there are matches before proceeding
        if (matches.length === 0) {
            return; // Exit the effect if no matches have been loaded yet
        }

        const matchCount = matches.length;
        switch (matchCount) {
            case 16:
                setTotalRounds(4);
                break;
            case 8:
                setTotalRounds(3);
                break;
            case 4:
                setTotalRounds(2);
                break;
            default:
                console.error("Unsupported number of matches");
        }
    }, [matches]);


    // Helper function to find match by number
    const findMatchByNumber = (number) => {
        return matches.find(match => match.matchNumber === number);
    };

    const canUserEditMatch = (match) => {
        // Check if the user is logged in and has the necessary role or is a participant of the match
        const userRoles = new Set(userData.authorities || []);
        const isUserAdminOrViewer = userRoles.has("ROLE_ADMIN") || userRoles.has("ROLE_SUPERVISOR");
        const isUserParticipant = match.userId1 === userData.id || match.userId2 === userData.id || match.userId1Partner === userData.id || match.userId2Partner === userData.id;

        return isUserAdminOrViewer || isUserParticipant;
    };

    // Function to go to the next round
    const goToNextRound = () => {
        setCurrentRound((prevRound) => (prevRound < totalRounds ? prevRound + 1 : prevRound));
    };

    // Function to go to the previous round
    const goToPreviousRound = () => {
        setCurrentRound((prevRound) => (prevRound > 1 ? prevRound - 1 : prevRound));
    };

    const renderMatchCell = (matchNumber) => {
        const match = findMatchByNumber(matchNumber);
        const hasParticipants = match && (match.participant1Id && match.participant2Id);
        return (
            <div className={styles.matchCellForSingleEliminationBracket}>
                {match ? (
                    <>
                        <div className={styles.playerNames}>
                            {match.participant1Id ?
                                <div>
                                    {renderPlayerName(match.participant1Id, match.participant1LastName, match.participant1FirstName, match, match.seedNumberParticipant1)}
                                    {match.participant1PartnerLastName && (
                                        <>
                                            <br/>
                                            <span
                                                className={styles.partnerName}>{renderPartnerName(match.participant1Id, match.participant1PartnerLastName, match.participant1PartnerFirstName, match )}</span>
                                        </>
                                    )}
                                </div> : <div>&nbsp;</div>}
                            {match.participant2Id ?
                                <div>
                                    {renderPlayerName(match.participant2Id, match.participant2LastName, match.participant2FirstName, match, match.seedNumberParticipant2)}
                                    {match.participant2PartnerLastName && (
                                        <>
                                            <br/>
                                            <span
                                                className={styles.partnerName}>{renderPartnerName(match.participant2Id, match.participant2PartnerLastName, match.participant2PartnerFirstName, match )}</span>
                                        </>
                                    )}
                                </div> :
                                <div>&nbsp;</div>}
                        </div>
                        <div>
                            {match.setResults && renderMatchResultForSingleElimination(match.setResults)}
                        </div>
                        <div>
                            {!isArchived && hasParticipants && canUserEditMatch(match) && (
                                <button className={styles.button} onClick={() => onEditMatch(match.id)}>
                                    {checkIfResultsEntered(match) ? 'E' : 'W'}
                                </button>
                            )}
                        </div>
                    </>
                ) : (
                    `match ${matchNumber}`
                )}
            </div>
        );
    };

    const renderRoundTitle = (roundName, roundNumber) => (
        <div>
            <h5 className={styles.titleOfRound}>
                {currentRound > 1 && roundNumber === currentRound && (
                    <button className={styles.navigationButton} onClick={goToPreviousRound}>
                        <TfiAngleDoubleLeft/>
                    </button>
                )}
                {roundName}
                {currentRound < totalRounds && roundNumber === currentRound && (
                    <button className={styles.navigationButton} onClick={goToNextRound}>
                        <TfiAngleDoubleRight/>
                    </button>
                )}
            </h5>
        </div>
    );


    const renderRound = (roundName, roundNumber, matchesNumbers) => (
        <div
            className={`${styles.round} ${styles[`round${roundNumber}`]} ${currentRound === roundNumber ? styles.current : ''}`}>
            {renderRoundTitle(roundName, roundNumber)}
            {matchesNumbers.map(renderMatchCell)}
        </div>
    );

    const generateBracket = () => {
        const matchCount = matches.length;
        switch (matchCount) {
            case 16:
                return (
                    <div className={styles.bracketContainer}>
                        {renderRound('Runda 1/8', 1, [1, 2, 3, 4, 5, 6, 7, 8])}
                        {renderRound('Ćwierćfinały', 2, [9, 10, 11, 12])}
                        {renderRound('Półfinały', 3, [13, 14])}
                        <div className={styles.finalRounds}>
                            {renderRound('Mecz o 3 miejsce', 4, [16])}
                            {renderRound('Finał', 4, [15])}
                        </div>
                    </div>
                );
            case 8:
                return (
                    <div className={styles.bracketContainer}>
                        {renderRound('Ćwierćfinały', 1, [1, 2, 3, 4])}
                        {renderRound('Półfinały', 2, [5, 6])}
                        <div className={styles.finalRounds}>
                            {renderRound('Mecz o 3 miejsce', 3, [8])}
                            {renderRound('Finał', 3, [7])}
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className={styles.bracketContainer}>
                        {renderRound('Półfinały', 1, [1, 2])}
                        <div className={styles.finalRounds}>
                            {renderRound('Mecz o 3 miejsce', 2, [4])}
                            {renderRound('Finał', 2, [3])}
                        </div>
                    </div>
                );
            default:
                return <div>Unsupported number of matches</div>;
        }
    };

    return <>
        <div>
            {generateBracket()}
        </div>

    </>
};

export default SingleEliminationBracket;
