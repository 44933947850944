import React, {useEffect, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import styles from "./RegisterForm.module.css";
import Input from "../../UI/Input/Input";
import config from './../../config/config';
import {useNavigate} from 'react-router-dom';
import CustomModal from "../../UI/Modal/CustomModal";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";


const RegisterForm = () => {

    const [formData, setFormData] = useState({
        username: '',
        firstName: '',
        lastName: '',
        phone: '',
        password: '',
        confirmPassword: ''
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isPositiveMessage, setIsPositiveMessage] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    }

    const handleChange = e => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!hasAgreedToTerms) {
            setModalMessage("Musisz zaakceptować regulamin przed rejestracją.");
            setIsPositiveMessage(false);
            setIsModalOpen(true);
            return;
        }

        if (!captchaValue) {
            setModalMessage("Proszę zaznaczyć pole reCAPTCHA.");
            setIsPositiveMessage(false);
            setIsModalOpen(true);
            return;
        }

        try {

            const payload = {
                ...formData,
                "gRecaptchaResponse": captchaValue
            };

            const response = await axios.post(`${config.apiUrl}/register`, payload);
            setModalMessage(response.data);
            setIsPositiveMessage(true);
            setIsModalOpen(true);
        } catch (error) {
            console.error(error.response.data);
            setModalMessage(error.response.data);
            setIsPositiveMessage(false);
            setIsModalOpen(true);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit} className={styles.registerForm}>
                <h2 className={styles.registerTitle}>Rejestracja</h2>
                <Input
                    placeholder="Email"
                    type="email"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    required
                />
                <Input
                    placeholder="Imię"
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                />
                <Input
                    placeholder="Nazwisko"
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                />
                <Input
                    placeholder="Telefon"
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                />
                <div className={styles.passwordContainer}>
                    <Input
                        placeholder="Hasło"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                    <span className={styles.eyeIcon} onClick={() => setShowPassword(!showPassword)}>
        {showPassword ? <AiOutlineEyeInvisible/> : <AiOutlineEye/>}
    </span>
                </div>
                <div className={styles.passwordContainer}>
                    <Input
                        placeholder="Powtórz hasło"
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        required
                    />
                    <span className={styles.eyeIcon} onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
        {showConfirmPassword ? <AiOutlineEyeInvisible/> : <AiOutlineEye/>}
    </span>
                </div>

                <div className={styles.terms}>
                    <input
                        type="checkbox"
                        checked={hasAgreedToTerms}
                        onChange={() => setHasAgreedToTerms(!hasAgreedToTerms)}
                    />
                    <label className={styles.termsContent}>
                        Akceptuję <a href="/regulation" target="_blank">Regulamin</a>
                        oraz <a href="/privacy-policy" target="_blank">Politykę Prywatności</a>
                    </label>
                </div>


                <ReCAPTCHA
                    className={styles.recaptcha}
                    sitekey="6LeTikYnAAAAAOF2cNqyfcUGpxRf8S-w_Rx95sK8"
                    onChange={handleCaptchaChange}
                    language="pl"
                />

                <button type="submit" className={styles.button}>
                    Zarejestruj
                </button>
            </form>
            <CustomModal
                isOpen={isModalOpen}
                onRequestClose={() => {
                    setIsModalOpen(false);
                    if (isPositiveMessage) {
                        navigate('/');
                    }
                }}
                message={modalMessage}
                isPositiveMessage={isPositiveMessage}
                cancelLabel="Zamknij"
            />
        </>
    );
};

export default RegisterForm;