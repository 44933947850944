import React, {useContext, useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';
import {
    registerTournamentParticipant,
    fetchParticipants,
    unRegisterTournamentParticipant,
    getUserData
} from "../../api/api";
import CustomModal from "../../UI/Modal/CustomModal";
import style from './TourRegForm.module.css';
import {formatFirstLetterOfName} from "../../utils";
import Spinner from "../Spinner/Spinner";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookMessenger} from "@fortawesome/free-brands-svg-icons";
import { IoArrowUndoOutline } from "react-icons/io5";

const calculateTimeLeft = (endDate) => {
    const difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
            total: difference,
        };
    }

    return timeLeft;
};

const TourRegForm = ({tournament, onReturn}) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(tournament.registrationEndDate));
    const [participants, setParticipants] = useState([]);
    const [isRegistered, setIsRegistered] = useState(false);
    const [userParticipantId, setUserParticipantId] = useState(null);
    const [userId, setUserId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showConfirmButton, setShowConfirmButton] = useState(false);
    const [confirmLabel, setConfirmLabel] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const [isReserve, setIsReserve] = useState(false);
    const [partnerEmail, setPartnerEmail] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            const data = await getUserData();
            setUserId(data.id);
        };

        fetchUserData();
    }, []);

    const {isLoggedIn} = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        if (isLoggedIn && userId) {
            // Check if the user is registered either as a primary participant or as a doubles partner
            const registeredParticipant = participants.find(participant =>
                participant.userId === userId || participant.doublesPartnerId === userId
            );

            if (registeredParticipant) {
                setIsRegistered(true);
                setUserParticipantId(registeredParticipant.id);
            } else {
                setIsRegistered(false);
                setUserParticipantId(null);
            }
        }
    }, [isLoggedIn, userId, participants]);


    useEffect(() => {
        const fetchData = async () => {

            try {
                const result = await fetchParticipants(tournament.id);
                if (result.success) {
                    setParticipants(result.data);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error("Failed to load data");
                setError("Błąd pobrania danych");
                setIsLoading(false);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            const newTimeLeft = calculateTimeLeft(tournament.registrationEndDate);
            if (newTimeLeft.total <= 0) {
                clearInterval(timer);
                isRegistrationOpen(false);
            } else {
                setTimeLeft(newTimeLeft);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [tournament.registrationEndDate]);


    const handleLoginRedirect = () => {
        navigate("/login", {state: {from: location.pathname}});
    };

    const isRegistrationOpen = (tournament) => {
        const now = new Date();
        const registrationStart = new Date(tournament.registrationStartDate);
        const registrationEnd = new Date(tournament.registrationEndDate);
        return now >= registrationStart && now <= registrationEnd;
    };

    const isStartRegistration = (tournament) => {
        const now = new Date();
        const registrationStart = new Date(tournament.registrationStartDate);
        return now >= registrationStart;
    };

    const handleRegister = async () => {

        // Additional logic for doubles
        if (tournament.type === 'DOUBLES' && !partnerEmail) {
            // Handle case where partner email is required but not provided
            setModalMessage("Proszę podać adres email partnera.");
            setIsModalOpen(true);
            return;
        }

        if (participants.length >= tournament.participantLimit) {
            setModalMessage("Limit zgłoszeń został przekroczony. Czy zapisać Cię na listę rezerwową?");
            setConfirmLabel("Zapisuje się na listę rezerwową");
            setIsReserve(true);
            setShowConfirmButton(true);
            setIsModalOpen(true);
        } else {
            const response = await registerTournamentParticipant(tournament.id, userId, partnerEmail);
            if (response.success) {
                setParticipants(response.data.participants);
                setIsRegistered(true);
                setModalMessage(response.data.message);
                setShowConfirmButton(false);
                setIsModalOpen(true);
                setPartnerEmail('');
            } else {
                console.error('Failed to register:', response.data);
                setModalMessage(response.data);
                setShowConfirmButton(false);
                setIsModalOpen(true);
            }
        }
    };

    const handleUnregister = async () => {
        if (!userParticipantId) return;
        setModalMessage("Czy na pewno chcesz się wypisać?");
        setConfirmLabel("Wypisuje się");
        setShowConfirmButton(true);
        setIsModalOpen(true);
    };

    const formatDate = (dateString) => {
        const options = {day: '2-digit', month: 'long', hour: '2-digit', minute: '2-digit', hour12: false};
        const date = new Date(dateString);
        return date.toLocaleString('pl-PL', options);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleConfirmAction = async () => {
        if (isReserve) {
            const response = await registerTournamentParticipant(tournament.id, userId, partnerEmail);
            if (response.success) {
                setParticipants(response.data.participants);
                setIsRegistered(true);
                setModalMessage("Zapisano Cię na listę rezerwową.");
                setShowConfirmButton(false);
                setIsReserve(false);
                setIsModalOpen(true)
            } else {
                console.error('Failed to register:', response.data);
                setModalMessage(response.data);
                setShowConfirmButton(false);
                setIsModalOpen(true);
            }
        } else {
            const response = await unRegisterTournamentParticipant(userParticipantId);
            if (response.success) {
                setParticipants(response.data.participants);
                setIsRegistered(false);
                setModalMessage(response.data.message);
                setShowConfirmButton(false);
                setIsModalOpen(true);
            } else {
                console.error('Failed to unRegister:', response.data);
                setError("Błąd podczas wypisania się");
            }
        }
    };

    function formatTournamenDate(dateString) {
        // Create a new Date object from the ISO string
        const date = new Date(dateString);

        // Define options for date and time separately
        const dateOptions = {
            weekday: 'long',  // Full name of the day of the week
            month: 'long',    // Full name of the month
            day: 'numeric'    // Numeric day of the month
        };
        const timeOptions = {
            hour: 'numeric',  // Numeric hour
            minute: 'numeric', // Numeric minute
            hour12: false      // Use 24-hour time format
        };

        // Convert to locale string using Polish locale
        const formattedDate = date.toLocaleDateString('pl-PL', dateOptions);
        const formattedTime = date.toLocaleTimeString('pl-PL', timeOptions);

        // Concatenate time and date in the desired order
        return `${formattedTime}, ${formattedDate}`;
    }

    if (isLoading) return <Spinner size={"large"}/>;
    if (error) return <ErrorMessage error={error}/>;


    return (
        <>
            <CustomModal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                message={modalMessage}
                confirmLabel={showConfirmButton ? confirmLabel : undefined}
                onConfirm={showConfirmButton ? handleConfirmAction : undefined}
                isPositiveMessage={modalMessage.includes("Dodano") || modalMessage.includes("anulowane") || modalMessage.includes("Zapisano")}
                cancelLabel="Zamknij"
                confirmButtonColor="red"
                cancelButtonColor="green"
            />
            <div className={style.container}>
                <div>
                    <button className={style.returnButton} onClick={onReturn}><IoArrowUndoOutline/></button>
                    <h2 className={style.title}>{tournament.title}</h2>
                    <p className={style.startTime}>Start turnieju: {formatTournamenDate(tournament.tournamentDate)}</p>
                    <p className={style.description}>{tournament.description}</p>
                </div>
                <div className={style.linksContainer}>
                    <div className={style.scoreRules}>
                        <a href="/score-rules" target="_blank">Zasady gry</a>
                    </div>
                    <div className={style.messengerLink}>
                        <a href="https://m.me/ch/AbYBlVAAHnRXzvtj/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebookMessenger} size="lg"/>
                            Czat turniejowy
                        </a>
                    </div>
                </div>
                <p className={style.participantLimit}>Limit zgłoszeń: {tournament.participantLimit}</p>
                <div>
                    {
                        isRegistrationOpen(tournament) &&
                        <div>
                            <p className={style.registrationEndDate}>Koniec
                                zapisów {formatTournamenDate(tournament.registrationEndDate)}</p>
                            <div className={style.countdownContainer}>
                                <div className={style.timeBox}>
                                    <span>{timeLeft.days}</span>
                                    <div className={style.timeLabel}>dni</div>
                                </div>
                                <div className={style.timeBox}>
                                    <span>{timeLeft.hours}</span>
                                    <div className={style.timeLabel}>godzin</div>
                                </div>
                                <div className={style.timeBox}>
                                    <span>{timeLeft.minutes}</span>
                                    <div className={style.timeLabel}>minut</div>
                                </div>
                                <div className={style.timeBox}>
                                    <span>{timeLeft.seconds}</span>
                                    <div className={style.timeLabel}>sekund</div>
                                </div>
                            </div>
                            {
                                tournament.type === 'DOUBLES' && !isRegistered && isLoggedIn && (
                                    <input
                                        type="email"
                                        placeholder="Email zarejestrowanego partnera"
                                        value={partnerEmail}
                                        onChange={(e) => setPartnerEmail(e.target.value)}
                                        className={style.partnerEmailInput} // Add appropriate styling class
                                    />
                                )
                            }
                            {
                                !isLoggedIn ? (
                                    // If the user is not logged in, show the "Log in" button
                                    <div className={style.buttonContainer}>
                                        <button className={style.logInButton} onClick={handleLoginRedirect}>Zaloguj
                                        </button>
                                    </div>
                                ) : isRegistered ? (
                                    // If the user is logged in and registered, show the "Remove me" button
                                    <div className={style.buttonContainer}>
                                        <button className={style.removeMeButton} onClick={handleUnregister}>
                                            Wypisuję się
                                        </button>
                                    </div>
                                ) : (
                                    // If the user is logged in but not registered, show the "Add me" button
                                    <div className={style.buttonContainer}>
                                        <button className={style.addMeButton} onClick={handleRegister}>
                                            Zapisz mnie
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    }
                </div>
                {!isStartRegistration(tournament) &&
                    <p className={style.startRegistrationDate}>Zapisy
                        od {formatTournamenDate(tournament.registrationStartDate)}</p>
                }
                {participants.length === 0 && isRegistrationOpen(tournament) &&
                    <h4 className={style.firstRegistration}>Dodaj pierwsze zgłoszenie.</h4>
                }
                {participants.length > 0 &&
                    <table className={style.table}>
                        <thead>
                        <tr>
                            <th>Lp.</th>
                            <th>Zapisani</th>
                            <th>Data zapisu</th>
                        </tr>
                        </thead>
                        <tbody>
                        {participants.filter(participant => !participant.reserve).map((participant, index) => (
                            <tr key={participant.id}>
                                <td>{index + 1}</td>
                                <td>
                                    {participant.lastName} {formatFirstLetterOfName(participant.firstName)} <br/>
                                    {participant.doublesPartnerLastName} {formatFirstLetterOfName(participant.doublesPartnerFirstName)}
                                </td>
                                <td>{formatDate(participant.registrationTime)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                }
                {participants.some(participant => participant.reserve) && (
                    <>
                        <h3 className={style.reserveTitle}>Lista rezerwowa</h3>
                        <table className={style.reserveTable}>
                            <thead>
                            <tr>
                                <th>Lp.</th>
                                <th>Zapisani</th>
                                <th>Data zapisu</th>
                            </tr>
                            </thead>
                            <tbody>
                            {participants.filter(participant => participant.reserve).map((participant, index) => (
                                <tr key={participant.id}>
                                    <td>{index + 1}</td>
                                    <td>
                                        {participant.lastName} {formatFirstLetterOfName(participant.firstName)} <br/>
                                        {participant.doublesPartnerLastName} {formatFirstLetterOfName(participant.doublesPartnerFirstName)}
                                    </td>
                                    <td>{formatDate(participant.registrationTime)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
        </>
    );
};

export default TourRegForm;