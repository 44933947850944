import React, {useState, useEffect, useCallback, useMemo} from "react";
import {format} from "date-fns-tz";
import {cloneDeep} from "lodash";
import {createReservation} from "../../api/api";
import AdminReservationCell from "./AdminReservationCell";
import TimeCell from "../TimeCell/TimeCell";
import PropTypes from "prop-types";
import styles from "./AdminReservationList.module.css";
import CustomModal from "../../UI/Modal/CustomModal";

const AdminReservationList = ({
                                  courts,
                                  selectedDate,
                                  reservations,
                                  userId,
                                  startHour,
                                  endHour,
                                  updateReservations,
                                  showAfter14,
                              }) => {
    const [cellData, setCellData] = useState({});
    const [showReserveButton, setShowReserveButton] = useState(false);
    const [updateKey, setUpdateKey] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");


    useEffect(() => {
        setShowReserveButton(false);
    }, [selectedDate]);

    useEffect(() => {
        setUpdateKey(prevUpdateKey => prevUpdateKey + 1);
    }, [cellData]);

    const generateCellKey = (courtId, time) => {
        const date = new Date(time);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');

        return `court-${courtId}-date-${year}.${month}.${day}-time-${hour}:${minute}`;
    };

    const processReservations = useCallback((reservations, cellData, selectedDate, userId) => {
        const updatedCellData = cloneDeep(cellData);
        reservations.forEach((reservation) => {
            const startTime = new Date(reservation.startTime);
            const endTime = new Date(reservation.endTime);

            const reservationDateString = startTime.toISOString().slice(0, 10);
            const selectedDateString = selectedDate.toISOString().slice(0, 10);

            if (reservationDateString !== selectedDateString) {
                return;
            }


            for (let time = startTime; time < endTime; time.setMinutes(time.getMinutes() + 30)) {
                const key = generateCellKey(reservation.courtId, time);
                if (updatedCellData[key]) {

                    if (Number(reservation.userId) === Number(userId)) {
                        updatedCellData[key].color = "green";
                    } else {
                        updatedCellData[key].color = "red";
                    }
                    updatedCellData[key].firstName = reservation.firstName;
                    updatedCellData[key].lastName = reservation.lastName;
                    updatedCellData[key].member = reservation.member;
                    updatedCellData[key].status = reservation.status;
                } else {
                    console.warn("No cell found for reservation:", key);
                }
            }
        });

        return updatedCellData;
    }, []);


    const generateEmptyCellData = useCallback((courts, selectedDate, startHour, endHour) => {
        const cellData = {};
        courts.forEach((court) => {
            for (let i = startHour; i < endHour; i += 0.5) {
                const time = new Date(selectedDate);
                time.setHours(Math.floor(i), Math.floor((i % 1) * 60), 0, 0);
                const key = generateCellKey(court.id, time);
                cellData[key] = {court: court, time, color: "none", selected: false};
                // console.log(`Cell value: ${JSON.stringify(cellData[key])}`);
            }
        });
        return cellData;
    }, []);


    useEffect(() => {
        if (courts.length === 0 || !reservations || !userId) {
            return;
        }
        const emptyCellData = generateEmptyCellData(courts, selectedDate, startHour, endHour);
        const updatedCellData = processReservations(reservations, emptyCellData, selectedDate, userId);
        setCellData(updatedCellData);
    }, [reservations, selectedDate, courts, userId, generateEmptyCellData, processReservations]);


    const handleEmptyCellClick = (key) => {
        setCellData((prevData) => {
            const newData = {...prevData};

            if (!newData.hasOwnProperty(key)) {
                console.error(`Key ${key} not found in newData`);
            }

            if (newData[key].color === "none") {

                newData[key] = {
                    ...newData[key],
                    color: "yellow",
                    selected: true,
                };
                setShowReserveButton(true);
            } else if (newData[key].color === "yellow") {
                newData[key] = {
                    ...newData[key],
                    color: "none",
                    selected: false,
                }
                setShowReserveButton(Object.values(newData).some((cell) => cell.selected));
            }
            return newData;
        });
    };


    const generateTimeLabels = useCallback(
        (selectedDate, startHour, endHour) => {
            const timeLabels = [];
            const start = Math.floor(startHour * 2);
            const end = Math.floor(endHour * 2);
            for (let i = start; i <= end - 0.5; i++) {
                const time = new Date(selectedDate);
                time.setUTCHours(Math.floor(i / 2), (i % 2) * 30, 0, 0);
                timeLabels.push(time);
            }
            return timeLabels;
        },
        []
    );

    const timeLabels = useMemo(() => {
        return generateTimeLabels(selectedDate, showAfter14 ? 14 : startHour, endHour);
    }, [showAfter14, startHour, endHour]);


    const handleReserveButtonClick = () => {
        setShowReserveButton(false);
        const selectedCells = Object.values(cellData).filter(cell => cell.selected);

        // Group cells by courtId
        const cellsByCourt = selectedCells.reduce((acc, cell) => {
            if (!acc[cell.court.id]) {
                acc[cell.court.id] = [];
            }
            acc[cell.court.id].push(cell);
            return acc;
        }, {});

        // Process each court separately
        Object.values(cellsByCourt).forEach(courtCells => {
            const sortedCells = courtCells.sort((a, b) => a.time - b.time);
            const reservations = [];

            let currentReservation = {
                courtId: sortedCells[0].court.id,
                startTime: format(sortedCells[0].time, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", {timeZone: 'UTC'}),
            };

            for (let i = 1; i < sortedCells.length; i++) {
                const currentTime = sortedCells[i].time;
                const previousTime = sortedCells[i - 1].time;
                const timeDifferenceInMinutes = (currentTime - previousTime) / (60 * 1000);

                if (timeDifferenceInMinutes > 30) {
                    currentReservation.endTime = format(new Date(previousTime.getTime() + 30 * 60 * 1000), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", {timeZone: 'UTC'});
                    reservations.push(currentReservation);
                    currentReservation = {
                        courtId: sortedCells[i].court.id,
                        startTime: format(sortedCells[i].time, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", {timeZone: 'UTC'}),
                    };
                }
            }

            currentReservation.endTime = format(new Date(sortedCells[sortedCells.length - 1].time.getTime() + 30 * 60 * 1000), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", {timeZone: 'UTC'});
            reservations.push(currentReservation);

            // Send reservations to the backend
            reservations.forEach(async (reservation) => {
                try {
                    const response = await createReservation(reservation);
                    setModalMessage(response.data);
                    setIsModalOpen(true);
                } catch (error) {
                    console.error("Error while creating reservation:", error);
                    setModalMessage(error.response.data);
                    console.log(error.response.data);
                    setIsModalOpen(true);
                }
            });
        });
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        updateReservations();
    };

    return (
        <div>
            <CustomModal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                message={modalMessage}
                isPositiveMessage={modalMessage.includes("dodana")}
                cancelLabel="Zamknij"
            />

            <div className={styles.mainContainer}>
                <div className={styles.container}>
                    {courts.map((court) => {
                        // console.log("reservations: ", reservations);
                        const filteredCellData = Object.values(cellData)
                            .filter((cell) => cell.court.id === court.id)
                            .filter((cell) => {
                                const localTime = new Date(cell.time).toLocaleString("pl-PL", {
                                    timeZone: "Europe/Warsaw",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                });
                                const localHour = parseInt(localTime.slice(0, 2));
                                return !showAfter14 || localHour >= 14;
                            })
                            .sort((a, b) => a.time - b.time);
                        return (

                            <div key={court.id} className={styles.courtRow}>
                                <span className={styles.courtName}>{court.name}</span>
                                <div className={styles.timeAndCells}>
                                    <div className={styles.timeRow}>
                                        {timeLabels.map((time) => {
                                            const formattedTime = time.toISOString().substr(11, 5);
                                            return (
                                                <TimeCell key={`time-${formattedTime}`} time={formattedTime}/>
                                            );
                                        })}
                                    </div>
                                    <div className={styles.cellsRow}>
                                        {filteredCellData.map((cell) => {
                                            // console.log("fileteredCellData: ", filteredCellData);
                                            return (
                                                <AdminReservationCell
                                                    key={`${generateCellKey(cell.court.id, cell.time)}-${updateKey}`}
                                                    cell={cell}
                                                    onClick={() =>
                                                        handleEmptyCellClick(generateCellKey(cell.court.id, cell.time))
                                                    }
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>


                        );
                    })}
                </div>
            </div>
            <div className={styles.legend}>
                <button className={styles.registered}>REGISTERED</button>
                <button className={styles.member}>MEMBER</button>
                <button className={styles.nmember}>NMEMBER</button>
                <button className={styles.lessons}>LESSONS</button>
                <button className={styles.periodic}>PERIODIC</button>
                <button className={styles.payed}>PAYED</button>
            </div>
        </div>

    )
        ;
};

AdminReservationList.propTypes = {
    courts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    selectedDate: PropTypes.instanceOf(Date).isRequired,
    reservations: PropTypes.array.isRequired,
    startHour: PropTypes.number.isRequired,
    endHour: PropTypes.number.isRequired,
};

export default AdminReservationList;
