import React from 'react';
import styles from './Statute.module.css';

const Statute = () => {
    return (
        <div className={styles.regulationContainer}>
            <h1>STATUT BOCHEŃSKIEGO TOWARZYSTWA TENISOWEGO</h1>
            <h2>Rozdział I</h2>
            <h3>Postanowienia ogólne</h3>
            <div className={styles.sectionNumber}> § 1</div>
            <div className={styles.regulationText}>
                <p>Stowarzyszenie nosi nazwę „Bocheńskie Towarzystwo Tenisowe” w Bochni, zwane dalej w dalszym ciągu
                    niniejszego statutu w skrócie „Towarzystwem”.</p>
            </div>
            <div className={styles.sectionNumber}> § 2</div>
            <div className={styles.regulationText}>
                <p>Towarzystwo jest dobrowolnym, samorządnym i trwałym zrzeszeniem o celach niezarobkowych,
                    które działa zgodnie z Konstytucją Rzeczypospolitej Polskiej, oraz porządkiem prawnym określonym w
                    ustawach.</p>
            </div>
            <div className={styles.sectionNumber}> § 3</div>
            <div className={styles.regulationText}>
                <p>Siedzibą towarzystwa jest miasto Bochnia.</p>
            </div>
            <div className={styles.sectionNumber}> § 4</div>
            <div className={styles.regulationText}>
                <p>Towarzystwo jest zarejestrowane na czas nieokreślony i posiada osobowość prawną.</p>
            </div>
            <div className={styles.sectionNumber}> § 5</div>
            <div className={styles.regulationText}>
                <p>Zasięgiem działania towarzystwa jest obszar całej Rzeczypospolitej Polskiej.</p>
            </div>
            <div className={styles.sectionNumber}> § 6</div>
            <div className={styles.regulationText}>
                <p>Towarzystwo opiera swoją działalność na pracy społecznej członków, jednakże do prowadzenia swych
                    spraw może zatrudniać pracowników.</p>
            </div>
            <div className={styles.sectionNumber}> § 7</div>
            <div className={styles.regulationText}>
                <p>Towarzystwo może posiadać odznakę organizacyjną ustalona przez Zarząd zgodnie
                    z obowiązującymi przepisami.
                </p>
            </div>

            <h2>Rozdział II</h2>
            <h3>Cele i środki działania</h3>

            <div className={styles.sectionNumber}> § 8</div>
            <div className={styles.regulationText}>
                <p>Celem działalności Towarzystwa jest zaspakajanie potrzeb członków oraz osób nie zrzeszonych w
                    zakresie rekreacji i sportu.</p>
            </div>
            <div className={styles.sectionNumber}> § 9</div>
            <div className={styles.regulationText}>
                <p>Do realizacji powyższego celu Towarzystwo zdąża przy zachowaniu obowiązujących przepisów
                    prawnych:<br/>
                    1.Prowadzenie i obsługę kortów tenisowych w Bochni,<br/>
                    2.Podnoszenie kwalifikacji oraz upowszechnianie zasad gry w tenisa ziemnego
                    (prowadzenie szkółki tenisowej, propagowanie tenisa na ternie szkół podstawowych i średnich,
                    organizowanie turniejów),<br/>
                    3.Rozwijanie umiejętności sportowych oraz organizowanie różnorodnych form rekreacji i wypoczynku.
                </p>
            </div>

            <h2>Rozdział III</h2>
            <h3>Członkowie – ich prawa i obowiązki</h3>

            <div className={styles.sectionNumber}> § 10</div>
            <div className={styles.regulationText}>
                <p>1. Członkiem Towarzystwa może być każda osoba fizyczna mająca zdolność do czynności prawnych i nie
                    jest pozbawiona praw publicznych, która:<br/>
                    a) zgłosiła swoje przystąpienie do Towarzystwa w podpisanej deklaracji, uznała statut i zdeklarowała
                    wkład swojej pracy społecznej na rzecz Towarzystwa,<br/>
                    b) będzie na bieżąco opłacać składki członkowskie,<br/>
                    c) został przyjęta w głosowaniu przez Zarząd,<br/>
                    Od decyzji odmownej przysługuje ubiegającemu się o przyjęcie do Towarzystwa prawo odwołania się do
                    Walnego Zebrania Członków.<br/>
                    2. Małoletni w wieku od 16 do 18 lat, którzy mają ograniczoną zdolność do czynności prawnych, mogą
                    należeć do Towarzystwa i korzystać z czynnego
                    i biernego prawa wyborczego, z tym że w składzie zarządu Towarzystwa większość muszą stanowić osoby
                    o pełnej zdolności do czynności prawnych,<br/>
                    3. Małoletni poniżej 16 lat mogą, za zgodą przedstawicieli ustawowych, należeć do Towarzystwa, bez
                    prawa udziału w głosowaniu na Walnym Zebraniu
                    Członków oraz bez korzystania z czynnego i biernego prawa wyborczego do władz Towarzystwa.<br/>
                    4. Cudzoziemiec może być członkiem Towarzystwa na zasadach obowiązujących obywateli Polskich.
                </p>
            </div>
            <div className={styles.sectionNumber}> § 11</div>
            <div className={styles.regulationText}>
                <p>Każdy członek Towarzystwa ma prawo:<br/>
                    1. Uczestniczenia i wnioskowania na Walnym Zebraniu Członków oraz wyboru
                    i wybieralności do władz Towarzystwa,<br/>
                    2. Korzystania ze wszelkich istniejących urządzeń Towarzystwa, na warunkach ustalonych przez Zarząd,<br/>
                    3. Korzystania z wszelkich ulg i udogodnień, jakie im Towarzystwo w statutowym spełnieniu swych
                    zadań zapewnia,<br/>
                    4. Noszenia ewentualnie odznaki Towarzystwa.
                </p>
            </div>
            <div className={styles.sectionNumber}> § 12</div>
            <div className={styles.regulationText}>
                <p>Każdy członek Towarzystwa ma obowiązek:<br/>
                    1. Popierania działalności Towarzystwa w granicach swoich możliwości,<br/>
                    2. Przestrzegania statutu, regulaminów oraz uchwał władz Towarzystwa,<br/>
                    3. Propagowanie haseł i idei towarzystwa oraz zjednanie mu członków
                    i sympatyków,<br/>
                    4. Regularne wpłacanie, ustalonych przez władze towarzystwa składek członkowskich,<br/>
                    5. Świadczenie w miarę możliwości pracy społecznej na rzecz Towarzystwa.
                </p>
            </div>
            <div className={styles.sectionNumber}> § 13</div>
            <div className={styles.regulationText}>
                <p>Utrata praw członkowski następuje przez:<br/>
                    1. Wystąpienie zgłoszone pisemnie przez Zarząd Towarzystwa po uiszczeniu wszelkich zaległych
                    składek,<br/>
                    2. Wykreślenie uchwałą Zarządu Towarzystwa, podjętą zwykłą większością głosów, za zaleganie ze
                    składkami przez okres
                    co najmniej 6 – ciu po sobie następujących miesiącach,<br/>
                    3. Wykluczenie uchwałą Zarządu Towarzystwa, podjętą większością 2/3 głosów, za czyn nie honorowy lub
                    działanie na szkodę Towarzystwa,<br/>
                    4. Utratę zdolności do czynności prawnych,<br/>
                    5. Śmierć członka.
                </p>
            </div>
            <div className={styles.sectionNumber}> § 14</div>
            <div className={styles.regulationText}>
                <p>Od decyzji o nieprzyjęciu do Towarzystwa, o wykreśleniu lub wykluczeniu, przysługuje zainteresowanemu
                    odwołanie do Walnego zebrania Członków.
                    Odwołanie winno być złożone na piśmie w ciągu 14 dni od dnia doręczenia decyzji.
                    Do czasu podjęcia decyzji przez Walne Zebranie Członków, odwołującemu się nie przysługują prawa
                    członkowskie Towarzystwa.
                </p>
            </div>
            <div className={styles.sectionNumber}> § 15</div>
            <div className={styles.regulationText}>
                <p>Osoba prawna może być jedynie wspierającym członkiem Towarzystwa.</p>
            </div>

            <h2>Rozdział IV</h2>
            <h3>Składki członkowskie</h3>


            <div className={styles.sectionNumber}> § 16</div>
            <div className={styles.regulationText}>
                <p>Wysokość wpisowego i składek członkowskich oraz termin ich płatności ustala corocznie Walne Zebranie
                    Członków.</p>
            </div>

            <h2>Rozdział V</h2>
            <h3>Władze Towarzystwa</h3>

            <div className={styles.sectionNumber}> § 17</div>
            <div className={styles.regulationText}>
                <p>Władzami Towarzystwa są:<br/>
                    1. Walne Zebranie Członków,<br/>
                    2. Zarząd,<br/>
                    3. Komisja Rewizyjna,<br/>
                    4. Sąd Koleżeński
                </p>
            </div>
            <div className={styles.sectionNumber}> § 18</div>
            <div className={styles.regulationText}>
                <p>1. Wybory władz Towarzystwa odbywają się w drodze tajnego głosowania. Kadencja trwa dwa lata.<br/>
                    2. Ustępujący członkowie Władz Towarzystwa mogą być wybranie ponownie.<br/>
                    3. Funkcji w Zarządzie, Komisji Rewizyjnej i Sądzie Koleżeńskim nie wolno wzajemnie łączyć.<br/>
                    4. Jeżeli inne przepisy statutowe nie stanowią inaczej, uchwały władz Towarzystwa zapadają zwykłą
                    większością głosów, w obecności co najmniej połowy liczby osób uprawnionych do głosowania. W razie
                    równości głosów, decyduje głos przewodniczącego zebrania.
                </p>
            </div>
            <div className={styles.sectionNumber}> § 19</div>
            <div className={styles.regulationText}>
                <p>1. W razie ustania członkostwa lub ustąpienia osoby wchodzącej w skład Władz Towarzystwa, władze te
                    mogą przyjąć w jej miejsce nowego członka.<br/>
                    2. Liczba przyjętych członków nie może przekraczać jednej trzeciej liczby członków władz ustalonej
                    statutem.
                </p>
            </div>
            <div className={styles.sectionNumber}> § 20</div>
            <div className={styles.regulationText}>
                <p>Zarząd, Komisja Rewizyjna i Sąd Koleżeński mogą zawiesić mandat członków tych władz w wypadku nie
                    wykonywania lub niesumiennego wykonywania
                    obowiązków wynikających z niniejszego statutu.</p>
            </div>

            <h3>Walne Zebranie Członków</h3>

            <div className={styles.sectionNumber}> § 21</div>
            <div className={styles.regulationText}>
                <p>Walne Zebrania Członków jest najwyższą władzą Towarzystwa. Biorą w nim udział wszyscy członkowie. W
                    sprawach, w których statut nie określa właściwości władz Towarzystwa, podejmowane uchwały należy do
                    Walnego Zebrania Członków.
                    Walne Zebranie Członków może być zwyczajne lub nadzwyczajne.
                </p>
            </div>
            <div className={styles.sectionNumber}> § 22</div>
            <div className={styles.regulationText}>
                <p>SZwyczajne Walne Zebranie Członków zwołuje Zarząd Towarzystwa raz w roku, nie później niż przed dniem
                    30 kwietnia.</p>
            </div>
            <div className={styles.sectionNumber}> § 23</div>
            <div className={styles.regulationText}>
                <p>Do kompetencji Walnego Zebrania Członków należy:<br/>
                    1. Ustalenie wytycznych dla działalności Towarzystwa,<br/>
                    2. Rozpatrywanie i zatwierdzanie sprawozdań z działalności Zarządu, Komisji Rewizyjnej i Sądu
                    Koleżeńskiego,<br/>
                    3. Udzielenie absolutorium ustępującemu Zarządowi na wniosek Komisji Rewizyjnej,<br/>
                    4. Zmiana statutu,<br/>
                    5. Wybór władz Towarzystwa,<br/>
                    6. Rozpatrzenie i zatwierdzenie budżetu, oraz programu prac Towarzystwa na rok następny,<br/>
                    7. Ustalenie wysokości wpisowego i składek członkowskich oraz terminu ich płatności,<br/>
                    8. Rozstrzyganie wniosków w sprawie majątku Towarzystwa,<br/>
                    9. Rozstrzyganie odwołań od uchwał Zarządu i orzeczeń Sądu Koleżeńskiego,<br/>
                    10. Decydowanie w tych wszystkich sprawach, w których statut nie upoważnia inne organa
                    Towarzystwa,<br/>
                    11. Rozwiązanie Towarzystwa i jego Likwidacja.
                </p>
            </div>
            <div className={styles.sectionNumber}> § 24</div>
            <div className={styles.regulationText}>
                <p>Nadzwyczajne Walne Zebranie Członków winno być zwołane przez Zarząd:<br/>
                    1. Na mocy uchwały poprzednie Walnego Zebrania Członków,<br/>
                    2. Z inicjatywy Zarządu,<br/>
                    3. Na żądanie Komisji Rewizyjnej, w terminie 14 dni od złożenia wniosku,<br/>
                    4. Na pisemne żądanie co najmniej 1/10 części ogółu członków Towarzystwa uprawnionych do głosowania,
                    w terminie 14 dni od złożenia wniosku,
                    Nadzwyczajne Walne Zebranie Członków załatwia tylko te sprawy, dla których zostało zwołane.
                </p>
            </div>
            <div className={styles.sectionNumber}> § 25</div>
            <div className={styles.regulationText}>
                <p>Prawo głosu na Walnych Zebraniach mają ci członkowie Towarzystwa, którzy nie zalegają ze składkami
                    dłużej niż 6 miesięcy.
                    Prawo uczestnictwa w Walnym Zebraniu musi być wykorzystywane osobiście.</p>
            </div>
            <div className={styles.sectionNumber}> § 26</div>
            <div className={styles.regulationText}>
                <p>Pisemne, telefoniczne lub email-lowe zawiadomienie z podaniem miejsca, terminu
                    i porządku obrad każdego Walnego Zebrania, rozsyła Zarząd Towarzystwa członkom, co najmniej na 14 –
                    cie dni przed terminem Walnego Zebrania.
                </p>
            </div>
            <div className={styles.sectionNumber}> § 27</div>
            <div className={styles.regulationText}>
                <p>Do każdego Walnego Zebrania potrzebna jest obecność przynajmniej połowy członków.
                    Zwołane ewentualnie w drugim terminie Walne Zebranie jest ważne bez względu na liczbę obecnych.</p>
            </div>
            <div className={styles.sectionNumber}> § 28</div>
            <div className={styles.regulationText}>
                <p>Uchwały na Walnych Zebraniach zapadają zwykłą większością głosów obecnych na Walnym Zebraniu.
                    Sprawy zmian statutu, rozwiązania względnie likwidacji Towarzystwa wymagają ¾ obecnych na Walnym
                    Zebraniu.</p>
            </div>
            <div className={styles.sectionNumber}> § 29</div>
            <div className={styles.regulationText}>
                <p>Prezes lub wiceprezes Zarządu otwiera Walne Zebranie Członków i zarządza wybór Przewodniczącego
                    Walnego Zebrania, który je w dalszym ciągu prowadzi, oraz Sekretarza.
                    Z obrad Walnego Zebrania spisuje się protokół, podpisany przez Przewodniczącego i Sekretarza Walnego
                    Zebrania.
                </p>
            </div>
            <div className={styles.sectionNumber}> § 30</div>
            <div className={styles.regulationText}>
                <p>Głosowanie na Walnym Zebraniu są jawne.
                    Wybór Władz Towarzystwa odbywa się w głosowaniu tajnym.
                    We wszystkich innych sprawach głosowanie tajne może uchwalić Walne Zebranie zwykłą większością
                    głosów.
                </p>
            </div>

            <h3>Zarząd</h3>

            <div className={styles.sectionNumber}> § 31</div>
            <div className={styles.regulationText}>
                <p>Zarząd Towarzystwa kieruje całokształtem działalności Towarzystwa w okresie między Walnymi Zebraniami
                    i odpowiada za swą działalność przed Walnym Zebraniem Członków.</p>
            </div>
            <div className={styles.sectionNumber}> § 32</div>
            <div className={styles.regulationText}>
                <p>Zarząd Towarzystwa składa się z Prezesa, Wiceprezesa, Sekretarza, Skarbnika
                    i jednego członka wybranych przez Walne Zebranie na okres dwóch lat.
                    Zarząd Towarzystwa winien ukonstytuować się najpóźniej w ciągu 7-dmiu dni od dnia wyborów.
                </p>
            </div>
            <div className={styles.sectionNumber}> § 33</div>
            <div className={styles.regulationText}>
                <p>Do zakresu działania Zarządu należy:<br/>
                    1. Kierowanie działalnością Towarzystwa oraz reprezentowanie go na zewnątrz,<br/>
                    2. Wykonywanie uchwał Walnego Zebrania Członków i czuwanie nad przestrzeganiem statutu
                    Towarzystwa,<br/>
                    3. Zwoływanie Walnego Zebrania Członków,<br/>
                    4. Opracowywanie planów pracy oraz projektów preliminarza budżetowego,<br/>
                    5. Zarządzanie majątkiem i funduszami Towarzystwa,<br/>
                    6. Składanie sprawozdań z działalności na Walnym Zebraniu Członków,<br/>
                    7. Przyjmowanie, skreślanie i wykluczanie członków,<br/>
                    8. Zawieranie umów, prowadzenie korespondencji i rachunkowości Towarzystwa,<br/>
                    9. Podejmowanie uchwał w sprawach nie zastrzeżonych do kompetencji innych władz Towarzystwa.
                </p>
            </div>
            <div className={styles.sectionNumber}> § 34</div>
            <div className={styles.regulationText}>
                <p>Zebrania Zarządu są prawomocne przy obecności co najmniej połowy jej członków, uchwały zapadają
                    zwykłą większością głosów.</p>
            </div>
            <div className={styles.sectionNumber}> § 35</div>
            <div className={styles.regulationText}>
                <p>Umowy oraz wszelkie zobowiązania podpisują w imieniu Zarządu – Prezes oraz jeden z członków Zarządu,
                    a w odniesieniu do zobowiązań finansowych – Prezes oraz Skarbnik.</p>
            </div>

            <h3>Komisja Rewizyjna</h3>

            <div className={styles.sectionNumber}> § 36</div>
            <div className={styles.regulationText}>
                <p>Komisja Rewizyjna jest organem kontrolującym całokształt działalności Towarzystwa.</p>
            </div>
            <div className={styles.sectionNumber}> § 37</div>
            <div className={styles.regulationText}>
                <p>Komisja Rewizyjna składa się z Przewodniczącego oraz dwóch, wybranych przez Walne Zebranie Członków
                    na okres dwóch lat.
                    Członkowie Komisji Rewizyjnej mają prawo uczestniczenia z głosem doradczym
                    w posiedzeniach Zarządu.
                </p>
            </div>
            <div className={styles.sectionNumber}> § 38</div>
            <div className={styles.regulationText}>
                <p>Komisja Rewizyjna czuwa nad działalnością Zarządu Towarzystwa. Ma ona prawo dokonywania rewizji w
                    każdym czasie, a obowiązkiem jej jest sprawdzić przynajmniej raz w roku wszystkie księgi, dokumenty
                    i stan majątkowy Towarzystwa. Z każdej dokonanej kontroli, Komisja sporządza protokół. Z czynności
                    swoich Komisja Rewizyjna składa sprawozdanie Walnemu Zebraniu Członków.
                    Komisja może wydawać zalecenia pokontrolne w wypadku stwierdzenia uchybień.
                    Do kompetencji Komisji Rewizyjnej należy również:<br/>
                    1. Stawianie wniosku o udzielnie absolutorium ustępującemu Zarządowi,<br/>
                    2. Występowanie z wnioskiem o zwołanie Nadzwyczajnego walnego Zebrania Członków, w wypadku
                    stwierdzenia nadużyć lub strat w gospodarowaniu majątkiem
                    i funduszami Towarzystwa, względnie bezczynności Zarządu przez okres dłuższy nisz trzy miesiące.
                </p>
            </div>

            <h3>Sąd koleżeński</h3>

            <div className={styles.sectionNumber}> § 39</div>
            <div className={styles.regulationText}>
                <p>Sąd Koleżeński składa się z Przewodniczącego i dwóch arbitrów wybranych przez Walne Zebranie Członków
                    na okres dwóch lat.</p>
            </div>
            <div className={styles.sectionNumber}> § 40</div>
            <div className={styles.regulationText}>
                <p>Do kompetencji Sądu Koleżeńskiego należy rozstrzyganie sporów powstałych między członkami a
                    wynikających w obrębie Towarzystwa w związku z jego działalnością. Od uchwały Sądu Koleżeńskiego
                    przysługuje prawo wniesienia odwołania do Walnego Zebrania Członków w ciągu 14 dni od otrzymania
                    uchwały.
                    Odwołanie składa się za pośrednictwem Sądu Koleżeńskiego. Sąd koleżeński składa sprawozdanie ze
                    swojej działalności na Walnym Zebraniu Członków.
                </p>
            </div>

            <h2>Rozdział VI</h2>
            <h3>Majątek Towarzystwa</h3>


            <div className={styles.sectionNumber}> § 41</div>
            <div className={styles.regulationText}>
                <p>Majątek Towarzystwa stanowią:<br/>
                    1. Wpisowe i składki członkowskie,<br/>
                    2. Dochody z majątku ruchomego i nieruchomego,<br/>
                    3. Dochody z działalności statutowej,<br/>
                    4. Darowizny, zapisy, spadki, subwencje i dotacje,<br/>
                    5. Dochody z ofiarności publicznej,<br/>
                    6. Dochody z działalności gospodarczej.
                </p>
            </div>
            <div className={styles.sectionNumber}> § 42</div>
            <div className={styles.regulationText}>
                <p>Towarzystwo może prowadzić działalność gospodarczą, w/g ogólnych zasad określonych w odrębnych
                    przepisach.
                    Dochód z działalności gospodarczej Towarzystwa służy realizacji celów statutowych i nie może być
                    przeznaczony do podziału między jego członków.</p>
            </div>
            <div className={styles.sectionNumber}> § 43</div>
            <div className={styles.regulationText}>
                <p>Wszelkie pisma i dokumenty Towarzystwa wychodzące na zewnątrz podpisuje Prezes Zarządu oraz jeden z
                    jego członków,
                    natomiast w sprawach finansowych pisma i dokumenty podpisuje Prezes zarządu oraz Skarbnik.</p>
            </div>

            <h2>Rozdział VII</h2>
            <h3>Przepisy końcowe</h3>

            <div className={styles.sectionNumber}> § 44</div>
            <div className={styles.regulationText}>
                <p>Wszelkie zmiany statutu wymagają uchwały Walnego Zebrania Członków powziętej większością 2/3 głosów.</p>
            </div>
            <div className={styles.sectionNumber}> § 45</div>
            <div className={styles.regulationText}>
                <p>Likwidacja Towarzystwa może nastąpić tylko na podstawie uchwały Walne Zebrania Członków powziętej większością 2/3 głosów przy obecności co najmniej połowy członków Towarzystwa.
                    Wniosek o likwidację nie może być wnioskiem nagłym, lecz musi wpłynąć do Zarządu Towarzystwa najpóźniej na trzy tygodnie przed terminem Walnego Zebrania Członków i musi być umieszczony w porządku dziennym.
                    Przy uchwale walnego Zebrania Członków o likwidacji Towarzystwa musi być równocześnie powzięta uchwała o przeznaczeniu majątku Towarzystwa.
                </p>
            </div>
        </div>
    );
};

export default Statute;
