import React, {useState, useEffect} from 'react';
import {FaPhone, FaEnvelope, FaFacebookMessenger} from 'react-icons/fa'; // Make sure to install react-icons if you haven't
import {fetchActiveCoaches} from '../../api/api'; // Adjust the import path based on your actual file structure

import styles from './CoachesList.module.css';

const CoachesList = () => {
    const [coaches, setCoaches] = useState([]);

    useEffect(() => {
        const loadCoaches = async () => {
            let activeCoaches = await fetchActiveCoaches();
            if (Array.isArray(activeCoaches)) {
                activeCoaches = shuffleArray(activeCoaches);
                setCoaches(activeCoaches);
            } else {
                console.error('Expected an array from fetchActiveCoaches, received:', activeCoaches);
                setCoaches([]);
            }
        };

        loadCoaches();
    }, []);


    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]]; // Swap elements
        }
        return array;
    };

    const handleContactClick = (contact, type) => {
        if (type === 'email') {
            window.location.href = `mailto:${contact}`;
        } else if (type === 'phone') {
            window.location.href = `tel:${contact}`;
        } else if (type === 'messenger'){
            window.location.href = `https://m.me/${contact}`;
        }
    };

    if (coaches.length === 0) {
        return <p className={styles.title}>Obecnie brak dostępnych trenerów tenisa.</p>;
    }

    return (
        <div>
            <h4 className={styles.title}>Lekcje tenisa prowadzą:</h4>
            <div className={styles.coachesContainer}>
                {coaches.map((coach) => (
                    <div key={coach.id} className={styles.coachCard}>
                        <h5>{`${coach.firstName} ${coach.lastName}`}</h5>
                        <br/>
                        <p>{coach.coachLessonInformation}</p>
                        <div className={styles.contactIcons}>
                            {coach.showEmail &&
                                <FaEnvelope onClick={() => handleContactClick(coach.username, 'email')}/>}
                            {coach.showPhone && <FaPhone onClick={() => handleContactClick(coach.phone, 'phone')}/>}
                            {coach.showMessenger && <FaFacebookMessenger onClick={() => handleContactClick(coach.messengerContact, 'messenger')}/>}
                        </div>
                    </div>
                ))}
            </div>
        </div>

    );
};

export default CoachesList;
